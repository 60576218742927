<template>
  <div>
    <label
      v-if="$slots.label"
      :for="id"
      class="block text-sm font-medium text-gray-700">
      <slot name="label" />
    </label>
    <div class="mt-1 flex relative">
      <div class="absolute inset-y-0 left-0 flex items-center">
        <select
          id="schema"
          v-model="data.schema"
          :disabled="disabled"
          name="schema"
          class="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
          <option value="http://">
            http://
          </option>
          <option value="https://">
            https://
          </option>
        </select>
      </div>
      <input
        :id="id"
        ref="urlInputRef"
        :class="[classes, rounded ? 'rounded-md' : '']"
        :type="type"
        :value="getOnlyDomain(inputValue)"
        :placeholder="placeholder"
        :disabled="disabled"
        class="shadow-sm block w-full sm:text-sm pl-24 disabled:cursor-not-allowed"
        @input="handleInput($event.target.value)">
    </div>
    <p
      v-if="errorMessage"
      class="mt-2 text-sm text-red-600">
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "UrlInput",
}
</script>

<script setup>
import { computed, defineEmits, defineProps, onMounted, reactive, ref, useSlots, watch } from 'vue';
import { useField } from 'vee-validate';

import { createDebounce } from '../../../utils';

const HTTP_SCHEMA = 'http://';
const HTTPS_SCHEMA = 'https://';

const slots = useSlots();

const debounce = createDebounce();

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  status: {
    type: String,
    default: '',
    validator(value) {
      return ['success', 'warning', 'danger', ''].indexOf(value) !== -1;
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  timeout: {
    type: Number,
    default: 500,
  },
  validation: {
    type: Boolean,
    default: true,
  },
  rounded: {
    type: Boolean,
    default: true,
  }
});

const urlInputRef = ref(null);

const data = reactive({
  schema: HTTPS_SCHEMA,
});

const emit = defineEmits(['update:value']);

let inputValue = computed(() => props.value);
let errorMessage = false;
let meta = { validated: false, valid: true };
let handleInput = (value) => {
  if (!value.startsWith('http')) {
    value = data.schema + value;
  }

  if (props.timeout) {
    debounce(() => emit('update:value', value), props.timeout);
    return;
  }

  emit('update:value', value);
};

if (props.validation) {
  const connectedField = useField(props.id, undefined, {
    initialValue: props.value,
  });
  inputValue = connectedField.value;
  errorMessage = connectedField.errorMessage;
  meta = connectedField.meta;

  handleInput = (value) => {
    if (!value.startsWith('http')) {
      value = data.schema + value;
    }

    function triggerChange() {
      connectedField.handleChange(value);
      emit('update:value', value);
    }

    if (props.timeout) {
      debounce(triggerChange, props.timeout);
      return;
    }

    triggerChange();
  };
}

onMounted(() => {
  switchSchema(props.value);
});

watch(
  () => props.value,
  value => switchSchema(value),
)

watch(
  () => data.schema,
  (newSchema, oldSchema) => {
    if (newSchema === oldSchema) return;
    handleInput(inputValue.value.replace(oldSchema, newSchema));
  }
);

const classes = computed(() => ({
  'pl-10': slots['start-icon'],
  'pr-10': slots['end-icon'],
  ...(
    (meta.validated && !meta.valid) || props.status
      ? {
        'focus:ring-red-500 focus:border-red-500 border-red-300': (meta.validated && !meta.valid) || props.status === 'danger',
        'focus:ring-green-500 focus:border-green-500 border-green-300': props.status === 'success',
        'focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300': props.status === 'warning',
      }
      : {'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300': true}
  ),
}));

const getOnlyDomain = value => value.replace(HTTP_SCHEMA, '').replace(HTTPS_SCHEMA, '');

const switchSchema = value => {
  if (value.startsWith(HTTPS_SCHEMA)) {
    data.schema = HTTPS_SCHEMA;
  } else if (value.startsWith(HTTP_SCHEMA)) {
    data.schema = HTTP_SCHEMA;
  }
};
</script>
