<template>
  <div>
    <breadcrumbs
      :value="breadcrumbsData"
      class="mb-6" />

    <slot name="message" />

    <z-table
      :loading="loading"
      :fields="returnFields"
      :data="value.return_lines"
      class="mb-10">
      <template #cell(reason)="{ row }">
        <template
          v-for="reason in row.reasons"
          :key="reason">
          <span>{{ reason.value }}: {{ reason.reasons.value }}</span><br>
        </template>
      </template>
      <template #cell(country_of_manufacture)="{ row }">
        {{ row.country_of_manufacture && countryMapping[row.country_of_manufacture] }}
      </template>
    </z-table>

    <div
      v-if="trackData"
      class="bg-white shadow overflow-hidden sm:rounded-lg mb-10">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Tracking info
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 pt-5 pb-64 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div
            v-for="item in trackData"
            :key="item"
            class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ item.label }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <template v-if="loading">
                <div class="animate-pulse w-2/5 flex space-x-4">
                  <div class="flex-1 space-y-4 py-1">
                    <div class="h-4 bg-blue-400 rounded" />
                  </div>
                </div>
              </template>
              <template v-else-if="item.label === 'Created'">
                <div :title="props.value.created">
                  {{ item.value }}
                </div>
              </template>
              <template v-else-if="item.label === 'Status'">
                <z-select
                  id="status"
                  :value="item.value"
                  :options="statusOptions"
                  class="w-44 leading-10"
                  content-width
                  @update:value="changeStatus" />
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div
      v-if="JSON.stringify(props.value.custom) !== '[]'"
      class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Custom parameters
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 pt-5 pb-24 sm:px-6">
        <json-formatter :value="props.value.custom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnDetailsTemplate',
}
</script>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

import ZSelect from '../../atoms/Select';
import JsonFormatter from '../../atoms/JsonFormatter';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import ZTable from '../../organisms/Table';

import countryMapping from '../../../assets/js/countries.json';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:status']);

const returnFields = [
  {key: "sku", label: "SKU"},
  {key: "product_name", label: "Product Name"},
  "reason",
  "quantity",
  "value",
  "weight",
  {key: "hs_code", label: "HS Code"},
  {key: "country_of_manufacture", label: "Country"},
];

const statusOptions = [
  {value: 'CREATED', label: 'Created'},
  {value: 'PICKED_UP', label: 'Picked Up'},
  {value: 'IN_TRANSIT', label: 'In Transit'},
  {value: 'OUT_FOR_DELIVERY', label: 'Out For Delivery'},
  {value: 'DELIVERED', label: 'Delivered'},
  {value: 'EXCEPTION', label: 'Exception'},
  {value: 'SHIPMENT_VOIDED', label: 'Shipment Voided'},
];

const trackData = computed(() => {
  let result = [];
  if (props.value.shipment) result.push(...[
    {label: "Status", value: props.value.shipment.status},
    {label: "Carrier", value: props.value.return_method.carrier_name},
    {label: "Reference Number", value: props.value.shipment.reference_number},
    {label: "Tracking Number", value: props.value.shipment.tracking_number},
  ]);
  if (props.value.pickup) result.push({
    label: "Confirmation Number",
    value: props.value.pickup.confirmation_number,
  });
  return result ? result.filter(item => item && item.value) : result;
});

const breadcrumbsData = computed(() => ([
  {
    label: "home",
    to: { name: "home" },
  },
  {
    label: "returns",
    to: { name: "return-methods" },
  },
  {
    label: props.value.id,
    to: '',
  },
]));

const changeStatus = value => emit('update:status', value);
</script>
