<template>
  <div>
    <z-input
      id="name"
      :value="name"
      class="mb-4"
      @update:value="updateName">
      <template #label>
        Name
      </template>
    </z-input>
    <z-select
      id="shipping_account_id"
      :value="shipping_account_key"
      :options="filteredShippingAccountOptions"
      class="mb-4"
      options-class="h-16"
      options-top-position
      @update:value="updateShippingAccountKey">
      <template #label>
        Shipping account
      </template>
    </z-select>
    <div class="mb-4">
      <span
        v-if="!!id"
        class="text-indigo-400 cursor-pointer"
        @click="copyAccountUrl(accountUrl)">
        Copy new shipment creation URL <small
          v-if="data.copyAccountUrl"
          class="text-green-500">
          {{ data.copyAccountUrl }} <CheckCircleIcon
            class="inline h-4 w-4"
            aria-hidden="true" />
        </small>
      </span>
    </div>
    <div class="mb-4">
      <span
        v-if="!!id"
        class="text-indigo-400 cursor-pointer"
        @click="copyRateUrl(rateUrl)">
        Copy available shipping offers URL <small
          v-if="data.copyRateUrl"
          class="text-green-500">
          {{ data.copyRateUrl }} <CheckCircleIcon
            class="inline h-4 w-4"
            aria-hidden="true" />
        </small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewstoreForm',
};
</script>

<script setup>
import {computed, defineProps, defineEmits, reactive} from 'vue';
import { useStore } from 'vuex';
import { CheckCircleIcon } from "@heroicons/vue/solid";

import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import { copyToClipboard } from "../../../utils";

const store = useStore();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  shipping_account_key: {
    type: [String, Number],
    required: true,
  },
  shipping_account_id: {
    type: String,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    default: () => ([]),
  },
  shippingAccountBlacklist: {
    type: Set,
    default: () => (new Set()),
  },
});

const emit = defineEmits(['update:name', 'update:shipping_account_key']);

const data = reactive({
  copyAccountUrl: '',
  copyRateUrl: '',
});

const filteredShippingAccountOptions = computed(() => (
  props.shippingAccounts
    .filter(({ id: value }) => {
      if (props.shipping_account_key === value) return true;
      return !props.shippingAccountBlacklist.has(value);
    })
    .map(({ id: value, name: label }) => ({ value, label }))
));

const apiBaseUrl = computed(() => store.state.apiBaseUrl);

const accountUrl = computed(() => (
  `${apiBaseUrl.value}/integrations/newstore/v1/${props.shipping_account_id}/shipments`
));

const rateUrl = computed(() => (
  `${apiBaseUrl.value}/integrations/newstore/v1/${props.shipping_account_id}/shipping_offers`
));

const copyAccountUrl = value => {
  data.copyAccountUrl = '';
  copyToClipboard(value);
  data.copyAccountUrl = 'Copied!';
  setTimeout(() => data.copyAccountUrl = '', 3000);
};

const copyRateUrl = value => {
  data.copyRateUrl = '';
  copyToClipboard(value);
  data.copyRateUrl = 'Copied!';
  setTimeout(() => data.copyRateUrl = '', 3000);
};

const updateName = value => emit('update:name', value);
const updateShippingAccountKey = value => emit('update:shipping_account_key', value);
</script>
