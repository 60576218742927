import { createStore } from 'vuex'

import client from '../api';
import router from '../router';
import { runAutoLogout } from "../utils";

const store = createStore({
    getters : {
        isAuthenticated: state => !!state.apiKey
    },
    state: {
        apiBaseUrl: localStorage.getItem('dashboardRegion') === 'eu'
            ? process.env.VUE_APP_ZENKRAFT_EU_BASE_URL
            : process.env.VUE_APP_ZENKRAFT_US_BASE_URL,
        user: {},
        apiKey: localStorage.getItem('apiKey') || ''
    },
    actions: {
        async logout({ commit }){
            commit('LOGOUT');
            await router.push({ name: 'home' });
        },
        async login({ commit, dispatch }, { email, password }) {
            const { data: user } = await client.login(email, password);

            if (!user) {
                await router.push({ name: 'login-mfa-confirm', params: { email } });
                return;
            }

            commit('AUTHENTICATE', user);
            runAutoLogout(() => dispatch('logout'), true);
            await router.push({ name: 'home' });
        },
        async forgotPassword(_, { email }) {
            await client.forgotPassword(email);
            await router.push({ name: 'login', query: { success: '' } });
        },
        async loginMfa(_, { email }) {
            await client.loginMfa(email);
            await router.push({ name: 'login-mfa-confirm', params: { email } });
        },
     },
    mutations: {
        AUTHENTICATE(state, user) {
            state.user = user
            state.apiKey = user.api_key
            localStorage.setItem('apiKey', state.apiKey);
        },
        LOGOUT(state) {
            localStorage.setItem('apiKey', '');

            state.user = {}
            state.apiKey = ''
        },
        CHANGE_REGION(state, value) {
            state.apiBaseUrl = value;
        }
    }
})

export default store