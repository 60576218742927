<template>
  <div class="relative">
    <highlightjs
      :code="formattedValue"
      :class="$style.codeBlock"
      class="w-full h-full whitespace-pre	bg-transparent"
      language="javascript" />
    <clipboard-copy-icon
      class="absolute right-3 top-0 h-6 w-6 text-gray-400 hover:text-gray-600 cursor-pointer"
      aria-hidden="true"
      @click="copy" />
  </div>
</template>

<script>
import 'highlight.js/styles/vs.css';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage('json', json);

export default {
  name: "JsonFormatter",

  components: {
    highlightjs: hljsVuePlugin.component
  },
}
</script>

<script setup>
import { computed, defineProps } from 'vue';
import { ClipboardCopyIcon } from '@heroicons/vue/solid';

import { copyToClipboard } from "../../../utils";

const props = defineProps({
  value: {
    type: [Object, String],
    default: () => ({}),
  },
});

const formattedValue = computed(() => {
  let value = typeof(props.value) === 'string'
    ? JSON.parse(props.value)
    : props.value;
  return JSON.stringify(value, null, 4);
});

const copy = () => {
  copyToClipboard(formattedValue.value);
};
</script>

<style module>
.codeBlock span {
  @apply text-sm !important;
}
</style>
