import axios from 'axios';
import OpenAPIClientAxios from 'openapi-client-axios';
import store from "./store";


const api = new OpenAPIClientAxios({
    definition: process.env.VUE_APP_ZENKRAFT_SWAGGER_URL,
});
api.init();

function _getHeaders() {
    return { headers: { zkkey: store.state.apiKey } };
}

async function _getClient() {
    const client = await api.getClient();
    client.defaults.baseURL = store.state.apiBaseUrl;
    return client;
}


class ZenkraftAPI {

    constructor() {
        let service = axios.create({});
        service.interceptors.request.use((config) => {
            config.headers.common['zkkey'] = store.state.apiKey
            return config
        });
        this.service = service;
    }

    get(path, params) {
        return this.service.get(store.state.apiBaseUrl + path, {params: params})
    }

    put(path, payload) {
        return this.service.request({
            method: 'PUT',
            url: store.state.apiBaseUrl + path,
            responseType: 'json',
            data: payload
        })
    }

    post(path, payload) {
        return this.service.request({
            method: 'POST',
            url: store.state.apiBaseUrl + path,
            responseType: 'json',
            data: payload
        })
    }

    async getUser() {
        const client = await _getClient();
        return await client.paths['/user'].get(null, null, _getHeaders());
    }

    async putUser(data) {
        const client = await _getClient();
        return await client.paths['/user'].put(null, data, _getHeaders());
    }

    async getLogs(filters) {
        filters = filters || {};
        const client = await _getClient();
        return await client.paths['/logs'].get(filters, null, _getHeaders());
    }

    async getLogItem(id) {
        const client = await _getClient();
        return await client.paths['/logs/{log_id}'].get({ log_id: id }, null, _getHeaders());
    }

    async getShippingAccounts() {
        const client = await _getClient();
        return await client.paths['/shippingaccount'].get(null, null, _getHeaders());
    }

    async postShippingAccount(newAccount) {
        const client = await _getClient();
        return await client.paths['/shippingaccount'].post(null, newAccount, _getHeaders());
    }

    async putShippingAccount(id, account) {
        const client = await _getClient();
        return await client.paths['/shippingaccount/{id}'].put({ id }, account, _getHeaders());
    }

    async deleteShippingAccount(id) {
        const client = await _getClient();
        return await client.paths['/shippingaccount/{id}'].delete({ id }, null, _getHeaders());
    }

    async getWebhooks() {
        const client = await _getClient();
        return await client.paths['/webhook'].get(null, null, _getHeaders());
    }

    async postWebhooks(newAccount) {
        const client = await _getClient();
        return await client.paths['/webhook'].post(null, newAccount, _getHeaders());
    }

    async putWebhook(id, account) {
        const client = await _getClient();
        return await client.paths['/webhook/{webhook_id}'].put(
            { webhook_id: id }, account, _getHeaders()
        );
    }

    async deleteWebhook(id) {
        const client = await _getClient();
        return await client.paths['/webhook/{webhook_id}'].delete(
            { webhook_id: id }, null, _getHeaders()
        );
    }

    async validateCallback(url) {
        const client = await _getClient();
        return await client.paths['/webhook/callback/validate'].get(
          { url }, null, _getHeaders()
        );
    }

    async getLocations() {
        const client = await _getClient();
        return await client.paths['/location'].get(null, null, _getHeaders());
    }

    async postLocation(location) {
        const client = await _getClient();
        return await client.paths['/location'].post(null, location, _getHeaders());
    }

    async putLocation(id, location) {
        const client = await _getClient();
        return await client.paths['/location/{location_id}'].put(
          { location_id: id }, location, _getHeaders(),
        );
    }

    async login(email, password) {
        const client = await _getClient();
        return await client.paths['/user/login'].post(null, { email, password }, _getHeaders());
    }

    async loginMfa(email) {
        const client = await _getClient();
        return await client.paths['/user/login/mfa'].post(null, { email }, _getHeaders());
    }

    async forgotPassword(email) {
        const client = await _getClient();
        return await client.paths['/user/forgot_password'].post(null, { email }, _getHeaders());
    }

    async getCarriers() {
        const client = await _getClient();
        return await client.get(
            store.state.apiBaseUrl + "/meta/carriers",
            {
                params: {
                    fields: "auth,name,display_name,description,short_description," +
                        "private,has_master_account,master_account_countries"
                },
                ..._getHeaders(),
            }
        );
    }

    async getServiceTypes() {
        const client = await _getClient();
        return await client.get(
            store.state.apiBaseUrl + "/meta/carriers",
          {
              params: {
                  fields: "display_name,shipping_methods,service_types,pickup_service_types"
              },
              ..._getHeaders(),
          },
        );
    }

    async getPackagingTypes() {
        const client = await _getClient();
        return await client.get(
            store.state.apiBaseUrl + "/meta/carriers",
          {
              params: {
                  fields: "packaging_types"
              },
              ..._getHeaders(),
          },
        );
    }

    async getLabelTypes() {
        const client = await _getClient();
        return await client.get(
            store.state.apiBaseUrl + "/meta/carriers",
          {
              params: {
                  fields: "label_types"
              },
              ..._getHeaders(),
          },
        );
    }

    async getSftpCarriers() {
        const { data } = await this.getServiceTypes();
        return data
          .filter(item => item.shipping_methods.includes('ftp'))
          .map(item => item.slug);
    }

    async getReturnMethods() {
        const client = await _getClient();
        return await client.paths['/return_methods'].get(null, null, _getHeaders());
    }

    async postReturnMethod(newReturnMethod) {
        const client = await _getClient();
        return await client.paths['/return_methods'].post(null, newReturnMethod, _getHeaders());
    }

    async putReturnMethod(id, returnMethod) {
        const client = await _getClient();
        return await client.paths['/return_methods/{return_method_id}'].put(
          { return_method_id: id }, returnMethod, _getHeaders()
        );
    }

    async deleteReturnMethod(id) {
        const client = await _getClient();
        return await client.paths['/return_methods/{return_method_id}'].delete(
          { return_method_id: id }, null, _getHeaders()
        );
    }

    async getShipmentEvents(filters) {
        filters = { ...(filters || {}) };
        if (filters.start_date) {
            filters.start_date = filters.start_date.toISOString().replace('Z', '');
        }
        if (filters.end_date) {
            filters.end_date = filters.end_date.toISOString().replace('Z', '');
        }
        const client = await _getClient();
        return await client.paths['/analytics/shipment'].get(filters, null, _getHeaders());
    }

    async getIntegrations() {
        const client = await _getClient();
        return await client.paths['/connected_accounts'].get(null, null, _getHeaders());
    }

    async postIntegration(newIntegration) {
        const client = await _getClient();
        return await client.paths['/connected_accounts'].post(null, newIntegration, _getHeaders());
    }

    async putIntegration(id, integration) {
        const client = await _getClient();
        return await client.paths['/connected_accounts/{connected_account_id}'].put(
            { connected_account_id: id }, integration, _getHeaders()
        );
    }

    async deleteIntegration(id) {
        const client = await _getClient();
        return await client.paths['/connected_accounts/{connected_account_id}'].delete(
          { connected_account_id: id }, null, _getHeaders()
        );
    }

    async getShipments(filters) {
        filters = filters || {};
        const client = await _getClient();
        return await client.paths['/shipments'].get(filters, null, _getHeaders());
    }

    async getShipmentItem(id) {
        const client = await _getClient();
        return await client.paths['/shipments/{shipment_id}'].get(
          { shipment_id: id }, null, _getHeaders()
        );
    }

    async refreshTrack(trackRequest) {
        const client = await _getClient();
        return await client.paths['/track'].post(null, trackRequest, _getHeaders());
    }

    async getReturns(filters) {
        filters = { ...(filters || {}) };
        if (filters.start_date) {
            filters.start_date = filters.start_date.toISOString().replace('Z', '');
        }
        if (filters.end_date) {
            filters.end_date = filters.end_date.toISOString().replace('Z', '');
        }
        const client = await _getClient();
        return await client.paths['/returns'].get(filters, null, _getHeaders());
    }

    async getReturnItem(id) {
        const client = await _getClient();
        return await client.paths['/returns/{return_id}'].get(
          { return_id: id }, null, _getHeaders(),
        );
    }

    async patchReturnItem(id, returnItem) {
        const client = await _getClient();
        return await client.paths['/returns/{return_id}'].patch(
          { return_id: id }, returnItem, _getHeaders(),
        );
    }
}

export default new ZenkraftAPI;
