<template>
  <div class="h-full">
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <sidebar
        :current-name="pathName"
        :is-visible="data.isSidebarVisible"
        @close="closeSidebar" />
    </div>
    <div class="h-full md:pl-64 flex flex-col flex-1">
      <nav-bar
        class="u-mb-medium"
        @toggle-sidebar="openSidebar">
        <template #title>
          <h2 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
            <slot name="title">
              {{ title }}
            </slot>
          </h2>
        </template>
        <z-button
          secondary
          @click="logout">
          Logout
        </z-button>
      </nav-bar>
      <main
        class="h-full"
        @click="closeSidebar">
        <div class="h-full px-6 pt-6">
          <slot>
            <router-view />
          </slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits, reactive } from 'vue';

import ZButton from "../../atoms/Button";
import NavBar from "../../organisms/NavBar";
import Sidebar from '../../organisms/Sidebar';

defineProps({
  pathName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(['sign-out']);

const data = reactive({
  isSidebarVisible: false,
});

const openSidebar = () => data.isSidebarVisible = true;
const closeSidebar = () => data.isSidebarVisible = false;

const logout = () => emit('sign-out');
</script>
