<template>
  <div>
    <z-input
      id="name"
      :value="name"
      class="mb-4"
      @update:value="updateName">
      <template #label>
        Name
      </template>
    </z-input>
    <alert type="warning">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="shippingAccountWarnMessage" />
    </alert>
    <z-select
      id="shipping_account_id"
      :value="shipping_account_id"
      :options="shippingAccountOptions"
      class="mb-4"
      @update:value="updateShippingAccountId">
      <template #label>
        Shipping account
      </template>
    </z-select>
    <z-input
      id="ftp_username"
      :value="ftp_username"
      class="mb-4"
      placeholder="FTP username"
      @update:value="updateFtpUsername">
      <template #label>
        FTP username
      </template>
    </z-input>
    <z-input
      id="ftp_password"
      :value="ftp_password"
      class="mb-4"
      placeholder="FTP password"
      @update:value="updateFtpPassword">
      <template #label>
        FTP password
      </template>
    </z-input>
    <z-input
      id="ftp_key"
      ref="ftpKeyRef"
      :rounded="false"
      type="file"
      class="mb-4"
      @update:value="updateFileContent">
      <template #label>
        FTP private key
      </template>
    </z-input>
  </div>
</template>

<script>
export default {
  name: 'SalesforceCoreForm',
};
</script>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue';

import Alert from '../../atoms/Alert';
import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  ftp_username: {
    type: String,
    required: true,
  },
  ftp_password: {
    type: String,
    required: true,
  },
  ftp_key: {
    type: String,
    required: true,
  },
  shipping_account_id: {
    type: [String, Number],
    required: true,
  },
  shippingAccounts: {
    type: Array,
    default: () => ([]),
  },
  shippingAccountBlacklist: {
    type: Set,
    default: () => (new Set()),
  },
  allowedCarriers: {
    type: Array,
    default: () => ([]),
  },
});

const shippingAccountWarnMessage = computed(() => (
  `Only ${props.allowedCarriers.map(e => e.replace('_', ' ')).join(', ')}
  carriers support tracking with SFTP. Please contact us at <b>support@zenkraft.com</b> if you want
  to use other carriers.`
));

const shippingAccountOptions = computed(() => {
  return props.shippingAccounts
    .filter(({ carrier }) => props.allowedCarriers.includes(carrier))
    .filter(({ id: value }) => {
      if (props.shipping_account_id === value) return true;
      return !props.shippingAccountBlacklist.has(value);
    })
    .map(({ id: value, name: label }) => ({ value, label }));
});

const emit = defineEmits([
  'update:name',
  'update:shipping_account_id',
  'update:ftp_username',
  'update:ftp_password',
  'update:ftp_key',
]);

const ftpKeyRef = ref(null);

const updateFileContent = () => {
  const file = ftpKeyRef.value.inputRef.files[0];

  if (!file) {
    emit('update:ftp_key', '');
  }

  const reader = new FileReader();
  reader.readAsText(file, "UTF-8");
  reader.onload = event => emit('update:ftp_key', event.target.result);
};

const updateName = value => emit('update:name', value);
const updateShippingAccountId = value => emit('update:shipping_account_id', value);
const updateFtpUsername = value => emit('update:ftp_username', value);
const updateFtpPassword = value => emit('update:ftp_password', value);
</script>
