<template>
  <div>
    <z-input
      id="name"
      :value="name"
      class="mb-4"
      @update:value="updateName">
      <template #label>
        Name
      </template>
    </z-input>
    <z-select
      id="shipping_account_id"
      :value="shipping_account_id"
      :options="filteredShippingAccountOptions"
      class="mb-4"
      options-class="h-24"
      options-top-position
      @update:value="updateShippingAccountId">
      <template #label>
        Shipping account
      </template>
    </z-select>
  </div>
</template>

<script>
export default {
  name: 'QuantumViewForm',
};
</script>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  shipping_account_id: {
    type: String,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    default: () => ([]),
  },
  shippingAccountBlacklist: {
    type: Set,
    default: () => (new Set()),
  },
});

const emit = defineEmits(['update:name', 'update:shipping_account_id']);

const filteredShippingAccountOptions = computed(() => (
  props.shippingAccounts
    .filter(({ id: value, carrier }) => {
      if (props.shipping_account_id === value) return true;
      if (carrier !== 'ups') return false;
      return !props.shippingAccountBlacklist.has(value);
    })
    .map(({ id: value, name: label }) => ({ value, label }))
));

const updateName = value => emit('update:name', value);
const updateShippingAccountId = value => emit('update:shipping_account_id', value);
</script>
