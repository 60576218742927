<template>
  <modal
    :value="isShown"
    :validation="schema"
    size="huge"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <slot name="title" />
    </template>
    <template
      v-if="data.localValue"
      #body="{ errors }">
      <z-input
        id="name"
        v-model:value="data.localValue.name"
        class="mb-4">
        <template #label>
          Name
        </template>
      </z-input>
      <z-input
        id="description"
        v-model:value="data.localValue.description"
        class="mb-4">
        <template #label>
          Description
        </template>
      </z-input>

      <z-select
        id="shipping_account_id"
        v-model:value="data.localValue.shipping_account_id"
        :options="shippingAccountOptions"
        class="mb-4 w-full"
        nullable>
        <template #label>
          Shipping Account
        </template>
        <template #nooptions>
          Please create a new <router-link
            :to="{ name: 'shipping-accounts' }"
            target="_blank"
            class="text-indigo-400 hover:text-indigo-600">
            shipping account
          </router-link>
        </template>
      </z-select>

      <z-select
        id="trigger_mode"
        v-model:value="data.localValue.trigger_mode"
        :options="triggerModeOptions"
        class="mb-4 w-full">
        <template #label>
          Trigger mode
        </template>
      </z-select>

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700">
          Events
        </label>
        <template
          v-for="(val, name) in data.events"
          :key="name">
          <checkbox
            :id="`${name.replace('.', '-')}-event`"
            :value="val"
            class="block w-full"
            @update:value="updateEvents(name, $event)">
            <template #label>
              {{ name }}
            </template>
          </checkbox>
        </template>
      </div>

      <url-input
        id="callback"
        v-model:value="data.localValue.callback"
        :timeout="1000"
        class="mb-4">
        <template #label>
          Webhook URL
        </template>
      </url-input>

      <divider
        @click="data.isExpandedAdvanced = !data.isExpandedAdvanced">
        <div class="flex flex-row text-xs">
          <span class="mr-1">Advanced</span>
          <template v-if="data.isExpandedAdvanced">
            <ChevronUpIcon
              class="h-3.5 w-3.5"
              aria-hidden="true" />
          </template>
          <template v-else>
            <ChevronDownIcon
              class="h-3.5 w-3.5"
              aria-hidden="true" />
          </template>
        </div>
      </divider>
      <template v-if="data.isExpandedAdvanced">
        <headers-field
          v-model:value="data.localValue.headers"
          :errors="errors"
          @update:header="updateHeader" />
      </template>
    </template>
    <template #footer>
      <z-button
        type="success"
        full-width
        submit>
        <slot name="send-button-label" />
      </z-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'WebhookModal',
};
</script>

<script setup>
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { array, object, string } from "yup";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/outline';

import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import UrlInput from '../../atoms/UrlInput';
import ZButton from '../../atoms/Button';
import Checkbox from '../../atoms/Checkbox';
import Divider from '../../atoms/Divider';
import Modal from '../../organisms/Modal';
import HeadersField from '../../organisms/HeadersField';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    required: true,
  },
  callbackValidator: {
    type: Function,
    required: true,
  },
  isShown: {
    type: Boolean,
    default: false,
  },
});

const localValueFactory = () => ({
  name: "",
  description: "",
  callback: "",
  shipping_account_id: null,
  events: [],
  headers: [],
  trigger_mode: "checkpoint",
});
const eventsStateFactory = () => ({
  "shipment.created": false,
  "shipment.picked_up": false,
  "shipment.in_transit": false,
  "shipment.out_for_delivery": false,
  "shipment.delivered": false,
  "shipment.exception": false,
  "return.created": false,
  "return.picked_up": false,
  "return.in_transit": false,
  "return.out_for_delivery": false,
  "return.delivered": false,
  "return.exception": false,
  "return.shipment_voided": false,
});

const triggerModeOptions = [
  {
    label: "checkpoint",
    value: "checkpoint",
  },
  {
    label: "tracking stage",
    value: "stage",
  },
]

const data = reactive({
  localValue: {
    ...localValueFactory(),
    ...props.value,
  },
  events: eventsStateFactory(),
  isExpandedAdvanced: false,
});

const emit = defineEmits(['send', 'close']);

const schema = computed(() => {
  const callbackSchema = string().url().required().test({
    name: 'callback',
    test: props.callbackValidator,
    message: 'Bad URL. Please try another.',
  });

  return object({
    name: string().max(50).required().label('Name'),
    description: string().max(100).required().label('Description'),
    callback: callbackSchema.label('Webhook URL'),
    headers: array()
      .of(
        object().shape({
          name: string().max(100).required().label('Name'),
          value: string().max(1000).label('Value'),
        })
      )
      .label('Headers')
  });
});

const shippingAccountOptions = computed(() => (
  props.shippingAccounts.map(({ id: value, name: label }) => ({ value, label }))
));

watch(
  () => props.value,
  (value) => {
    data.localValue = {
      ...data.localValue,
      ...value,
    };
    data.events = eventsStateFactory();
    data.localValue.events.forEach(event => data.events[event] = true);
  }
);

const closeModal = async () => {
  data.localValue = localValueFactory();
  await nextTick(() => emit('close'));
};

const submit = async () => {
  const value = data.localValue;
  data.localValue = localValueFactory();
  await nextTick(() => emit('send', value));
};

const updateEvents = (label, value) => {
  data.events[label] = value;
  data.localValue.events = Object.keys(data.events).filter(event => data.events[event]);
};

const updateHeader = ({ index, field, value }) => data.localValue.headers[index][field] = value;
</script>
