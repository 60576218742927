<template>
  <public-credentials>
    <template #title>
      Forgot your password
    </template>
    <template #subtitle>
      Enter your email address to receive password reset instructions
    </template>
    <template #content>
      <v-form
        :validation-schema="schema"
        @submit="submit">
        <alert
          v-if="error"
          type="danger"
          close
          @close="closeAlert">
          {{ error }}
        </alert>
        <z-input
          id="email"
          :value="email"
          :timeout="0"
          class="mb-4"
          @update:value="updateEmail">
          <template #label>
            Email address
          </template>
        </z-input>

        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
          text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Send Password Reset Instructions
        </button>
      </v-form>

      <divider class="mt-4">
        <router-link
          :to="{ name: 'login' }">
          Don’t login to your account yet? Get Started
        </router-link>
      </divider>
    </template>
  </public-credentials>
</template>

<script>
export default {
  name: 'PublicForgotPasswordTemplate',
}
</script>

<script setup>
import {defineEmits, defineProps} from 'vue';
import { Form as VForm } from 'vee-validate';
import { string } from "yup";

import Alert from '../../atoms/Alert';
import ZInput from '../../atoms/Input';
import Divider from '../../atoms/Divider';
import PublicCredentials from '../../templates/PublicCredentials';

defineProps({
  email: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['submit', 'alert-close', 'update:email']);

const schema = {
  email: string().email().required().label('Email'),
};

const submit = () => emit('submit');
const closeAlert = () => emit('alert-close');
const updateEmail = value => emit('update:email', value);
</script>
