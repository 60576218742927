<template>
  <span
    :class="classes"
    class="inline-flex items-center py-0.5 rounded-full font-medium">
    <slot />
  </span>
</template>

<script>
export default {
  name: "Badge",
}
</script>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  primary: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'info',
    validator: function (value) {
      return ['success', 'info', 'warning', 'danger'].indexOf(value) !== -1;
    },
  },
  size: {
    type: String,
    default: 'small',
    validator: function (value) {
      return ['small', 'large'].indexOf(value) !== -1;
    },
  },
});

const classes = computed(() => ({
  ["badge--primary"]: props.primary,
  ["badge--secondary"]: props.secondary,
  [`badge--${props.type || 'primary'}`]: true,
  [`badge--${props.size}`]: true,
}));
</script>

<style lang="scss">
.badge--small {
  @apply px-2.5 text-xs;
}

.badge--large {
  @apply px-3 text-sm;
}

.badge--success {
  @apply bg-green-100 text-green-800;
}

.badge--info {
  @apply bg-blue-100 text-blue-800;
}

.badge--warning {
  @apply bg-yellow-100 text-yellow-800;
}

.badge--danger {
  @apply bg-red-100 text-red-800;
}

.badge--primary {
  @apply bg-indigo-100 text-indigo-800;
}

.badge--secondary {
  @apply bg-gray-100 text-gray-800;
}
</style>
