<template>
  <nav
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    aria-label="Pagination">
    <div class="flex-1 flex justify-between sm:justify-end">
      <a
        :class="{ 'cursor-not-allowed': page === 1 }"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md
        text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        @click="prev">
        Previous
      </a>
      <a
        :class="{ 'cursor-not-allowed': lastPage }"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium
        rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        @click="next">
        Next
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
}
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  page: {
    type: Number,
    default: 1,
  },
  lastPage: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['prev', 'next']);

const prev = () => {
  if (props.page !== 1) emit('prev', props.page - 1);
};

const next = () => {
  if (!props.lastPage) emit('next', props.page + 1);
};
</script>
