<template>
  <div>
    <template
      v-for="item in items"
      :key="item.name">
      <div v-if="!item.children">
        <router-link
          :to="item.to"
          :class="[
            item.current
              ? 'bg-indigo-800 text-white'
              : 'text-indigo-100 hover:bg-indigo-600 hover:bg-opacity-75',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline hover:no-underline'
          ]"
          @click="close ? closeMenu() : undefined">
          <component
            :is="item.icon"
            class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
            aria-hidden="true" />
          {{ item.name }}
        </router-link>
      </div>
      <template v-else>
        <Disclosure
          v-slot="{ open }"
          as="div"
          class="space-y-1">
          <DisclosureButton
            as="span"
            class="text-indigo-100 hover:bg-indigo-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline hover:no-underline">
            <component
              :is="item.icon"
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              aria-hidden="true" />
            <span class="flex-1">
              {{ item.name }}
            </span>
            <svg
              :class="[open ? 'text-gray-400 rotate-90' : 'text-gray-300', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150']"
              viewBox="0 0 20 20"
              aria-hidden="true">
              <path
                d="M6 6L14 10L6 14V6Z"
                fill="currentColor" />
            </svg>
          </DisclosureButton>
          <DisclosurePanel class="space-y-1">
            <DisclosureButton
              v-for="subItem in item.children"
              :key="subItem.name"
              as="span">
              <router-link
                :to="subItem.to"
                :class="[
                  subItem.current
                    ? 'bg-indigo-800 text-white'
                    : 'text-indigo-100 hover:bg-indigo-600 hover:bg-opacity-75',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline hover:no-underline'
                ]"
                @click="close ? closeMenu() : undefined">
                <component
                  :is="subItem.icon"
                  class="mr-3 flex-shrink-0 h-6 w-6 text-gray-300"
                  aria-hidden="true" />
                {{ subItem.name }}
              </router-link>
            </DisclosureButton>
          </DisclosurePanel>
        </Disclosure>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
}
</script>

<script setup>
import { defineProps, defineEmits, onMounted, onUpdated } from 'vue';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },

  close: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['close']);

const handleSubMenuButton = () => {
  if (props.items[props.items.length - 1].current) {
    // WARN: Due to the current Disclosure implementation, we can't open submenu via method.
    // Therefore we need to do it with direct click of the disclosure button element.
    document.getElementById('headlessui-disclosure-button-1').click();
  }
};

onMounted(handleSubMenuButton);
onUpdated(handleSubMenuButton);

const closeMenu = () => emit('close');
</script>
