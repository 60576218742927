<template>
  <div
    class="bg-white shadow sm:rounded-lg"
    @click="onCardClick">
    <div class="p-6">
      <h3 class="-mt-1 text-lg leading-6 font-medium text-gray-900">
        API key <span
          v-if="data.copyMessage"
          class="text-green-500">
          {{ data.copyMessage }} <CheckCircleIcon
            class="inline h-5 w-5"
            aria-hidden="true" />
        </span>
      </h3>
      <div class="mt-2 max-w-xl text-sm text-gray-500">
        <p>
          Please find your key below:
        </p>
      </div>
      <form class="mt-2 sm:flex sm:items-center">
        <div
          v-if="loading"
          class="animate-pulse w-full flex space-x-4">
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-blue-400 rounded" />
          </div>
        </div>
        <template v-else>
          <div class="w-full mr-2 sm:max-w-xs">
            <z-input
              ref="apiKeyInput"
              :value="value"
              :type="inputType"
              :validation="false"
              disabled />
          </div>
          <z-button
            class="mt-1"
            secondary
            @click.stop="toggle">
            Reveal
          </z-button>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiKeyCard',
};
</script>

<script setup>
import { computed, defineProps, reactive, ref } from 'vue';
import { CheckCircleIcon } from "@heroicons/vue/solid";

import ZInput from '../../atoms/Input';
import ZButton from '../../atoms/Button';
import { copyToClipboard } from "../../../utils";


defineProps({
  value: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  isApiKeyShown: false,
  copyMessage: '',
});

const apiKeyInput = ref(null);

const inputType = computed(() => data.isApiKeyShown ? "text" : "password");

const onCardClick = () => {
  if (data.isApiKeyShown) {
    data.copyMessage = '';
    copyToClipboard(apiKeyInput.value.value);
    data.copyMessage = 'Copied!';
  }
};

const toggle = () => data.isApiKeyShown = !data.isApiKeyShown;
</script>
