<template>
  <div>
    <TransitionRoot
      as="template"
      :show="isVisible">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay
            class="fixed inset-0 bg-gray-600 bg-opacity-75"
            @click="close" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-indigo-700">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full
                  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="close">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div
              class="flex flex-shrink-0 px-4">
              <img
                class="w-3/5"
                src="https://zenkraft.com/img/logo-light.png"
                alt="Logo">
            </div>
            <div class="flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <sidebar-menu
                  :items="navigation"
                  :close="true"
                  @close="close" />
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div
          class="flex-shrink-0 w-14"
          aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow bg-indigo-700 overflow-y-auto">
        <div class="flex flex-shrink-0 px-4">
          <img
            class="w-4/5"
            src="https://zenkraft.com/img/logo-light.png"
            alt="Logo">
        </div>
        <div class="flex-1 flex flex-col">
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <sidebar-menu :items="navigation" />
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
}
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  HomeIcon,
  UsersIcon,
  LocationMarkerIcon,
  ViewListIcon,
  ArrowCircleLeftIcon,
  ChartPieIcon,
  CogIcon,
  XIcon,
  SaveIcon,
} from '@heroicons/vue/outline'
import { LoginIcon } from '@heroicons/vue/solid'

import SidebarMenu from '../SidebarMenu';

const props = defineProps({
  currentName: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const navigation = computed(() => {
  const setupChildren = [
    {
      name: 'Shipping Accounts',
      icon: UsersIcon,
      current: props.currentName === 'shipping-accounts',
      to: { name: 'shipping-accounts' },
    },
    {
      name: 'Webhooks',
      icon: LoginIcon,
      current: props.currentName === 'webhooks',
      to: { name: 'webhooks' },
    },
    {
      name: 'Locations',
      icon: LocationMarkerIcon,
      current: props.currentName === 'locations',
      to: { name: 'locations' },
    },
    {
      name: 'Users',
      icon: UsersIcon,
      current: props.currentName === 'users',
      to: { name: 'users' },
    },
    {
      name: 'Logs',
      icon: ViewListIcon,
      current: props.currentName === 'logs',
      to: { name: 'logs' },
    },
  ];

  return [
    {
      name: 'Dashboard',
      icon: HomeIcon,
      current: props.currentName === 'home',
      to: { name: 'home' },
    },
    {
      name: 'Shipments',
      icon: HomeIcon,
      current: props.currentName === 'shipments',
      to: { name: 'shipments' },
    },
    {
      name: 'Returns',
      icon: ArrowCircleLeftIcon,
      current: props.currentName === 'return-methods',
      to: { name: 'return-methods' },
    },
    {
      name: 'Reports',
      icon: ChartPieIcon,
      current: props.currentName === 'reports',
      to: { name: 'reports' },
    },
    {
      name: 'Integrations',
      icon: SaveIcon,
      current: props.currentName === 'integrations',
      to: { name: 'integrations' },
    },
    {
      name: 'Setup',
      icon: CogIcon,
      current: setupChildren.map(item => item.current).includes(true),
      children: setupChildren,
    },
  ];
});

const close = () => emit('close');
</script>
