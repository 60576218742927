<template>
  <div class="relative h-full -mx-6 -mt-6">
    <shipments-filter-bar
      :filters="filters"
      :carrier-options="carrierOptions"
      :shipping-account-options="shippingAccounts"
      @export-data="exportData"
      @update:filters="updateFilters" />

    <slot name="message" />

    <div class="flex h-full">
      <div class="relative w-8/12 flex-1 p-6">
        <usage-summary-card
          :loading="loading"
          :value="stats"
          class="flex-grow" />

        <shipments-carrier-chart
          v-if="fullData.carrier"
          :data="fullData.carrier"
          :show-top-badge="showTopBadge.carrier" />

        <div class="flex gap-6">
          <shipments-service-chart
            v-if="fullData.service"
            :data="fullData.service"
            :show-top-badge="showTopBadge.service"
            class="w-1/2" />
          <shipments-currency-chart
            v-if="fullData.currency"
            :data="fullData.currency"
            :show-top-badge="showTopBadge.currency"
            class="w-1/2" />
        </div>
      </div>
      <div class="flex-none w-4/12 min-h-screen p-6 bg-white">
        <shipments-carrier-pie-chart
          v-if="fullData.carrierPie"
          :data="fullData.carrierPie"
          :show-top-badge="showTopBadge.carrierPie"
          class="mb-10" />
        <shipments-country-chart
          v-if="fullData.country"
          :data="fullData.country"
          :show-top-badge="showTopBadge.country" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsShipmentTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits, computed, reactive } from 'vue';
import { format } from 'date-fns';

import UsageSummaryCard from "../../molecules/UsageSummaryCard";
import ShipmentsFilterBar from '../../organisms/ShipmentsFilterBar';
import ShipmentsCarrierChart from '../../organisms/ShipmentsCarrierChart';
import ShipmentsCarrierPieChart from '../../organisms/ShipmentsCarrierPieChart';
import ShipmentsCountryChart from '../../organisms/ShipmentsCountryChart';
import ShipmentsServiceChart from '../../organisms/ShipmentsServiceChart';
import ShipmentsCurrencyChart from '../../organisms/ShipmentsCurrencyChart';

import countryNameMapping from '../../../assets/js/countries.json';

const TOP_AMOUNT = 6;

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
  data: {
    type: Array,
    default: () => ([]),
  },
  carriers: {
    type: Object,
    default: () => ({}),
  },
  serviceTypes: {
    type: Object,
    default: () => ({}),
  },
  shippingAccounts: {
    type: Array,
    default: () => ([]),
  },
  stats: {
    type: Array,
    default: () => ([]),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['export-data', 'update:filters']);

const carrierOptions = computed(() => (
  Object.keys(props.carriers).map(key => ({
    value: key,
    label: props.carriers[key],
  }))
));

const baseColors = [
  '#10b981',
  '#efc58f',
  '#a4ca7d',
  '#ef4444',
  '#2dafdb',
  '#4f46e5',
];

const getColors = (number) => {
  return baseColors.slice(0, number);
};

const getTopItems = (data, top_size) => {
  const temp = {};
  let keyList = Object.keys(data);

  const tempSum = {};
  for (let i = 0; i < keyList.length; i++) {
    if (data[keyList[i]].length !== undefined) {
      tempSum[keyList[i]] = data[keyList[i]].reduce((partial, a) => partial + a, 0);
    } else {
      tempSum[keyList[i]] = data[keyList[i]];
    }
  }

  keyList.sort((a, b) => tempSum[b] - tempSum[a]);
  keyList = keyList.slice(0, top_size);

  for (let i = 0; i < top_size; i++) {
    temp[keyList[i]] = data[keyList[i]];
  }

  return temp;
};

const showTopBadge = reactive({
  carrier: true,
  carrierPie: true,
  country: true,
  service: true,
  currency: true,
});

const fullData = computed(() => {
  if (!props.data.length) return {};

  let currencyMapping = {};
  let countryMapping = {};
  let serviceMapping = {};
  let carrierPieMapping = {};

  let carrierMapping = {};
  const carrierDates = [];
  const carrierDefault = [];

  for (let i = 0; i < props.data.length; ++i) {
    const item = props.data[i];
    const created = format(new Date(item.created), 'dd/M');

    if (!(item.currency in currencyMapping)) {
      currencyMapping[item.currency] = 0;
    }
    ++currencyMapping[item.currency];


    const { is_outbound: isOutbound, to_country: recipient, from_country: sender } = item;
    const country = countryNameMapping[isOutbound ? recipient : sender];

    if (!(country in countryMapping)) countryMapping[country] = 0;
    ++countryMapping[country];


    const service = props.serviceTypes[item.service] || item.service;
    if (!(service in serviceMapping)) serviceMapping[service] = 0;
    ++serviceMapping[service];

    const carrier = props.carriers[item.carrier] || item.carrier;
    if (!carrierDates.length || carrierDates[carrierDates.length - 1] !== created) {
      for (let c in carrierMapping) {
        if (carrierMapping[c].length < carrierDates.length) {
          carrierMapping[c].push(0);
        }
      }

      carrierDates.push(created);
      carrierDefault.push(0);

    }

    if (!(carrier in carrierMapping)) {
      carrierMapping[carrier] = [...carrierDefault];
    }
    if (carrierMapping[carrier].length < carrierDates.length) {
      carrierMapping[carrier].push(0);
    }

    carrierMapping[carrier][carrierMapping[carrier].length - 1] += 1;
  }

  const carriersAmount = Object.keys(carrierMapping).length;
  showTopBadge.carrier = carriersAmount > TOP_AMOUNT; // eslint-disable-line vue/no-side-effects-in-computed-properties
  showTopBadge.carrierPie = carriersAmount > TOP_AMOUNT; // eslint-disable-line vue/no-side-effects-in-computed-properties
  showTopBadge.currency = Object.keys(currencyMapping).length > TOP_AMOUNT; // eslint-disable-line vue/no-side-effects-in-computed-properties
  showTopBadge.country = Object.keys(countryMapping).length > TOP_AMOUNT; // eslint-disable-line vue/no-side-effects-in-computed-properties
  showTopBadge.service = Object.keys(serviceMapping).length > TOP_AMOUNT; // eslint-disable-line vue/no-side-effects-in-computed-properties

  currencyMapping = getTopItems(currencyMapping, TOP_AMOUNT);
  countryMapping = getTopItems(countryMapping, TOP_AMOUNT);
  serviceMapping = getTopItems(serviceMapping, TOP_AMOUNT);
  carrierMapping = getTopItems(carrierMapping, TOP_AMOUNT);

  [currencyMapping, countryMapping, serviceMapping, carrierMapping].forEach(mapping => {
    // TODO: remove this undefined filtering
    if ('undefined' in mapping) {
      delete mapping['undefined'];
    }
  });

  Object.keys(carrierMapping).forEach(carrier => {
    carrierPieMapping[carrier] = carrierMapping[carrier].reduce((partial, a) => partial + a, 0);
  });

  const carrierColors = getColors(Object.keys(carrierMapping).length);

  return {
    currency: {
      labels: Object.keys(currencyMapping),
      datasets: [{
        backgroundColor: getColors(Object.keys(currencyMapping).length),
        data: Object.values(currencyMapping),
      }],
    },
    country: {
      labels: Object.keys(countryMapping),
      datasets: [{
        backgroundColor: getColors(Object.keys(countryMapping).length),
        data: Object.values(countryMapping),
      }],
    },
    service: {
      labels: Object.keys(serviceMapping),
      datasets: [{
        backgroundColor: getColors(Object.keys(serviceMapping).length),
        data: Object.values(serviceMapping),
      }],
    },
    carrierPie: {
      labels: Object.keys(carrierPieMapping),
      datasets: [{
        backgroundColor: getColors(Object.keys(carrierPieMapping).length),
        data: Object.values(carrierPieMapping),
      }],
    },
    carrier: {
      labels: carrierDates,
      datasets: Object.keys(carrierMapping)
        .map((carrier, index) => {
          if (carrierMapping[carrier].length < carrierDates.length) {
            const diff = carrierDates.length - carrierMapping[carrier].length;
            for (let i = 0; i < diff; ++i) {
              carrierMapping[carrier].push(0);
            }
          }

          return {
            label: carrier,
            backgroundColor: carrierColors[index],
            data: carrierMapping[carrier],
          }
        }),
    }
  };
});

const exportData = () => emit('export-data');

const updateFilters = value => emit('update:filters', value);
</script>
