import { createApp } from 'vue'
import { Chart, registerables } from 'chart.js'
import App from './App.vue'

import router from './router'
import store from './store'

import '../src/tailwind.css'

if (!process.env.NODE_ENV.trim() || process.env.NODE_ENV === 'development') {
    const initApiStubs = require('./apiStubs');
    initApiStubs.default();
}

const app = createApp(App);

Chart.register(...registerables);

app.use(router);
app.use(store);
app.mount('#app');
