<template>
  <shipment-details-template
    :loading="page.data.loading"
    :value="data.details"
    @refresh="refresh">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </shipment-details-template>
</template>

<script>
export default {
  name: 'ShipmentDetails',
};
</script>

<script setup>
import { defineProps, onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import ShipmentDetailsTemplate from '../../templates/ShipmentDetails';

import usePage from "../../../mixins/usePage";
import { ShipmentDetailsService } from '../../../services';
import { showFailMessage, withLoading } from "../../../utils";

const page = usePage();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const data = reactive({
  details: {},
});

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      data.details = await ShipmentDetailsService.get(props.id);
    } catch (e) {
      await showFailMessage(page.data, `${ShipmentDetailsService.name} weren't loaded!`);
    }
  });
});

const refresh = async () => {
  await withLoading(page.data, async () => {
    try {
      await ShipmentDetailsService.refresh({
        track: {
          refresh: true,
          carrier: data.details.carrier,
          shipping_account: data.details.shipping_account_id,
          tracking_number: data.details.tracking_number,
        }
      });
      data.details = await ShipmentDetailsService.get(props.id);
    } catch (e) {
      await showFailMessage(page.data, `${ShipmentDetailsService.name} weren't refreshed!`);
    }
  });
};
</script>
