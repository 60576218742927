<template>
  <div>
    <div class="flex justify-between mb-5 gap-4">
      <div class="h-12 w-12 flex items-center rounded-md bg-blue-500">
        <CubeIcon class="h-8 w-8 m-auto fill-current text-white" />
      </div>
      <div class="flex flex-col w-1/2">
        <template v-if="loading">
          <div class="animate-pulse w-2/5 flex space-x-4">
            <div class="flex-1 space-y-2 py-1">
              <div class="h-4 bg-blue-400 rounded" />
              <div class="h-4 w-4/5 bg-blue-400 rounded" />
            </div>
          </div>
        </template>
        <template v-else>
          <span class="text-xl font-bold">Shipment {{ value.tracking_number }}</span>
          <span class="text-sm font-medium font-bold text-gray-500">
            Created on <span class="text-black">{{ formattedCreatedOn }}</span>
          </span>
        </template>
      </div>
      <z-button
        class="ml-auto"
        type="info"
        @click="refresh">
        Refresh
      </z-button>
    </div>

    <slot name="message" />

    <div class="flex flex-row gap-6 h-screen">
      <div class="flex flex-col flex-auto">
        <div
          v-if="value.status || (value.checkpoints && value.checkpoints.length)"
          class="shipment-status mb-4">
          <div class="bg-white shadow overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6 text-center">
              <template v-if="loading">
                <div class="flex m-auto animate-pulse w-1/5 space-x-4">
                  <div class="flex-1 space-y-2 py-1">
                    <div class="h-4 bg-blue-400 rounded" />
                    <div class="h-4 bg-blue-400 rounded" />
                  </div>
                </div>
              </template>
              <template v-else>
                <span class="font-bold normal-case">
                  {{ value.checkpoints.length ? value.checkpoints[0].status : '' }}
                </span><br>
                <span class="text-sm font-medium text-gray-500">
                  {{ value.status || value.checkpoints.length ? value.checkpoints[0].description : '' }}
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="shipment-details">
          <div class="bg-white shadow overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Shipment detail
              </h3>
              <span class="text-sm font-medium text-gray-500">
                Shipment detail
              </span>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div
                  v-for="item in detailesRenderData"
                  :key="item"
                  class="sm:col-span-1">
                  <dt class="text-sm font-medium text-gray-500">
                    {{ item.label }}
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    <template v-if="loading">
                      <div class="animate-pulse w-2/5 flex space-x-4">
                        <div class="flex-1 space-y-4 py-1">
                          <div class="h-4 bg-blue-400 rounded" />
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.label === 'Created On'">
                      <div :title="props.value.created">
                        {{ item.value }}
                      </div>
                    </template>
                    <template v-else-if="item.label === 'Last Tracked'">
                      <div :title="props.value.updated">
                        {{ item.value }}
                      </div>
                    </template>
                    <template v-else>
                      {{ item.value }}
                    </template>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="shipment-checkpoints w-4/12">
        <div class="bg-white shadow overflow-hidden sm:rounded-lg min-h-1/2">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Checkpoints
            </h3>
            <span class="text-sm font-medium text-gray-500">
              Status updates from the carrier
            </span>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <template v-if="!loading">
              <div
                v-for="item in checkpointsRenderData"
                :key="item"
                class="sm:col-span-1">
                <div class="flex flex-row items-center py-3 gap-4 border-b border-gray-200">
                  <div class="h-12 w-12 flex items-center justify-center rounded-full bg-gray-100">
                    <TruckIcon class="h-8 w-8 stroke-0 stroke-current text-gray-500" />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-sm font-medium">
                      {{ item.status }}
                    </span>
                    <span class="text-sm font-medium text-gray-500">
                      {{ item.description }}
                    </span>
                  </div>
                  <span class="ml-auto text-sm font-medium text-gray-500 text-center">
                    <span :title="item.time">{{ item.created }}</span>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentDetailsTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { TruckIcon } from "@heroicons/vue/outline";
import { CubeIcon } from "@heroicons/vue/solid";

import ZButton from "../../atoms/Button";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['refresh']);

const formattedCreatedOn = computed(() => (
  props.value.created
    ? format(new Date(props.value.created), 'do LLLL yyyy')
    : ''
));

const detailesRenderData = computed(() => ([
  {label: "Shipping Carrier", value: props.value.carrier},
  {label: "Tracking Number", value: props.value.tracking_number || '-'},
  {label: "Reference Number", value: props.value.reference_number || '-'},
  {
    label: "Last Tracked",
    value: props.value.updated
      ? formatDistanceToNow(
        new Date(props.value.updated), { addSuffix: true }
      )
      : '-',
  },
  {
    label: "Created On",
    value: props.value.created
      ? format(new Date(props.value.created), 'MM/dd/yyyy K:sbbb')
      : '-',
  },
  {
    label: "Estimated Delivery Date",
    value: props.value.estimated_delivery
      ? format(new Date(props.value.estimated_delivery), 'do LLLL yyyy')
      : '-',
  },
]));

const checkpointsRenderData = computed(() => (props.value.checkpoints || []).map(item => ({
  ...item, created: formatDistanceToNowStrict(new Date(item.time), { addSuffix: false })
})));

const refresh = () => emit('refresh');
</script>
