<template>
  <div>
    <return-method-modal
      :value="modal.data.form"
      :is-shown="modal.data.isOpen"
      :service-types="serviceTypes"
      :locations="locations"
      :shipping-accounts="shippingAccounts"
      @send="modal.handle"
      @close="modal.close">
      <template #title>
        <template v-if="modal.data.isCreateForm">
          New
        </template> Return Method
      </template>
      <template #send-button-label>
        {{ modal.data.isCreateForm ? "Create" : "Update" }}
      </template>
    </return-method-modal>

    <slot name="message" />

    <z-table
      :loading="loading"
      :fields="returnFields"
      :data="returns"
      :table-class="!!returns ? '' : 'mb-28'"
      class="mb-10">
      <template #title>
        Returns
      </template>
      <template #filter-bar>
        <div class="flex items-end gap-2">
          <date-picker
            id="dateFrom"
            :value="filters.start_date"
            class="flex-1"
            placeholder="Date from"
            @update:value="updateFilters({ start_date: $event })">
            <template #start-icon>
              <CalendarIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </date-picker>
          <date-picker
            id="dateTo"
            :value="filters.end_date"
            class="flex-1"
            placeholder="Date to"
            @update:value="updateFilters({ end_date: $event })">
            <template #start-icon>
              <CalendarIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </date-picker>
          <z-select
            id="returnMethod"
            :value="filters.return_method"
            :options="returnMethodOptions"
            class="flex-1"
            placeholder="Filter by return method"
            nullable
            @update:value="updateFilters({ return_method: $event || '' })" />
          <z-input
            id="search"
            :value="filters.search"
            :timeout="1500"
            class="flex-1"
            placeholder="Search"
            @update:value="updateFilters({ search: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-input
            id="customerId"
            :value="filters.customer_id"
            :timeout="1500"
            class="flex-1"
            placeholder="Customer ID"
            @update:value="updateFilters({ customer_id: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-input
            id="orderId"
            :value="filters.order_id"
            :timeout="1500"
            class="flex-1"
            placeholder="Order ID"
            @update:value="updateFilters({ order_id: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-select
            id="status"
            :value="filters.status"
            :options="statusOptions"
            class="w-44 leading-10"
            placeholder="Status"
            content-width
            nullable
            @update:value="updateFilters({ status: $event })" />
          <custom-pair-filter
            :value="filters.custom"
            :timeout="1000"
            @update:value="updateFilters({ custom: $event })" />
        </div>
      </template>
      <template #cell(reference_number)="{ row }">
        <router-link
          :to="{ name: 'return-details', params: { id: row.id } }"
          class="text-indigo-400 hover:text-indigo-600">
          {{ row.shipment ? row.shipment.reference_number : row.pickup.confirmation_number }}
        </router-link>
      </template>
      <template #cell(return_method)="{ row }">
        <a
          class="text-indigo-400 hover:text-indigo-600 cursor-pointer"
          @click="showReturnMethodEditForm(row.return_method.id)">
          {{ row.return_method.method_name }}
        </a>
      </template>
      <template #cell(created)="{ row }">
        {{ row.created ? format(Date.parse(row.created), 'PPPppp') : '' }}
      </template>
      <template #footer>
        <button-pagination
          :page="returnPage"
          :last-page="returnLastPage"
          @prev="prev"
          @next="next" />
      </template>
    </z-table>

    <z-table
      :loading="loading"
      :fields="returnMethodFields"
      :data="returnMethods"
      :table-class="'mb-28'"
      class="mb-10">
      <template #title>
        Return Methods
      </template>
      <template #filter-bar>
        <z-button
          type="success"
          @click="modal.showCreateForm">
          Create
        </z-button>
      </template>
      <template #cell(shipping_account_id)="{ row }">
        {{ row.shipping_account_id }}
      </template>
      <template #cell(filters)="{ row }">
        <return-method-filters :value="row.filters" />
      </template>
      <template #cell(return_charge)="{ row }">
        {{ row.return_charge }}
      </template>
      <template #cell(service_type)="{ row }">
        <template
          v-if="
            serviceTypes
              && shippingAccounts
              && serviceTypeMapping[shippingAccountMapping[row.shipping_account_id]]
          ">
          {{ serviceTypeMapping[shippingAccountMapping[row.shipping_account_id]][row.service_type] }}
        </template>
      </template>
      <template #cell(actions)="{ row, rowIndex }">
        <div class="pr-11">
          <dropdown
            :options="handleActionDropdown(actionOptions, rowIndex)"
            :left-align="false"
            @select="selectAction(row, $event)">
            Actions
          </dropdown>
        </div>
      </template>
    </z-table>
  </div>
</template>

<script>
export default {
  name: 'ReturnsTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import {
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  TrashIcon,
  CalendarIcon,
  FilterIcon,
} from '@heroicons/vue/solid';
import format from "date-fns/format";

import ZButton from '../../atoms/Button';
import ZSelect from '../../atoms/Select';
import ZInput from '../../atoms/Input';
import DatePicker from '../../atoms/DatePicker';
import ReturnMethodFilters from '../../atoms/ReturnMethodFilters';
import Dropdown from '../../molecules/Dropdown';
import ButtonPagination from '../../molecules/ButtonPagination';
import CustomPairFilter from '../../molecules/CustomPairFilter';
import ZTable from '../../organisms/Table';
import ReturnMethodModal from '../../organisms/ReturnMethodModal';
import useModal from "../../../mixins/useModal";

const props = defineProps({
  returnMethods: {
    type: Array,
    required: true,
  },
  returns: {
    type: Array,
    required: true,
  },
  serviceTypes: {
    type: Array,
    required: true,
  },
  locations: {
    type: Array,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
  returnPage: {
    type: Number,
    required: true,
  },
  returnLastPage: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['create', 'update:filters', 'delete', 'prev', 'next']);
const modal = useModal(emit);

const returnMethodFields = [
  {key: "method_name", label: "Return Option Name"},
  {key: "shipping_account_id", label: "Shipping Account"},
  {key: "service_type", label: "Shipping Method"},
  {key: "display_name", label: "Display Name"},
  {key: "currency", label: "Currency"},
  {key: "return_charge", label: "Charge"},
  {key: "filters", label: "Display Criteria"},
  {key: "actions", label: "Actions"},
];

const returnFields = [
  {key: "reference_number", label: "Reference Number"},
  {key: "return_method", label: "Return Method"},
  {key: "shipment.tracking_number", label: "Tracking Number"},
  "created",
];

const actionOptions = [
  {icon: ArrowCircleUpIcon, value: 'move-up', label: 'Move Up'},
  {icon: ArrowCircleDownIcon, value: 'move-down', label: 'Move Down'},
  {icon: PencilAltIcon, value: 'edit', label: 'Edit'},
  {icon: TrashIcon, value: 'delete', label: 'Delete'},
];

const statusOptions = [
  {value: 'CREATED', label: 'Created'},
  {value: 'PICKED_UP', label: 'Picked Up'},
  {value: 'IN_TRANSIT', label: 'In Transit'},
  {value: 'OUT_FOR_DELIVERY', label: 'Out For Delivery'},
  {value: 'DELIVERED', label: 'Delivered'},
  {value: 'EXCEPTION', label: 'Exception'},
];

const serviceTypeMapping = computed(() => {
  if (!props.serviceTypes) return {};
  const result = {};

  for (let i = 0; i < props.serviceTypes.length; ++i) {
    const { carrier } = props.serviceTypes[i];

    if (!(carrier in result)) {
      result[carrier] = {};
    }

    ['data', 'pickupServiceTypes'].forEach(key => {
      if (!props.serviceTypes[i][key]) return;

      for (let j = 0; j < props.serviceTypes[i][key].length; ++j) {
        const { slug, name } = props.serviceTypes[i][key][j];
        result[carrier][slug] = name;
      }
    })
  }

  return result;
});

const shippingAccountMapping = computed(() => {
  if (!props.shippingAccounts) return {};
  const result = {};

  for (let i = 0; i < props.shippingAccounts.length; ++i) {
    const { id, carrier } = props.shippingAccounts[i];
    result[id] = carrier;
  }

  return result;
});

const returnMethodOptions = computed(() => props.returnMethods.map(item => ({
  value: item.id, label: item.display_name,
})));

const handleActionDropdown = (options, index) => (
  options.filter(option => (
    !((option.value === 'move-up' && index === 0)
      || (option.value === 'move-down' && index === props.returnMethods.length - 1))
  ))
);

const selectAction = (row, value) => {
  if (value === 'edit') {
    modal.showEditForm(row);
  } else if (value === 'delete') {
    emit('delete', row.id);
  } else if (value === 'move-up') {
    modal.data.isCreateForm = false;
    row.position -= 1;
    modal.handle(row, true);
  } else if (value === 'move-down') {
    modal.data.isCreateForm = false;
    row.position += 1;
    modal.handle(row, true);
  }
};

const showReturnMethodEditForm = returnMethodId => {
  for (let returnMethod of props.returnMethods) {
    if (returnMethod.id === returnMethodId) {
      modal.showEditForm(returnMethod);
      break;
    }
  }
};

const updateFilters = value => {
  emit('update:filters', {
    ...props.filters,
    ...value,
  })
};

const prev = value => emit('prev', value);
const next = value => emit('next', value);
</script>
