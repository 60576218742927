import client from "../api";


class IntegrationService {
  name = 'Integration';

  async get() {
    const { data: { connected_accounts } } = await client.getIntegrations();
    return connected_accounts;
  }

  async post(value) {
    value.service = parseInt(value.service);
    const {
      data: {
        connected_account: connectedAccount,
        url: salesforceCoreRedirectUrl,
      }
    } = await client.postIntegration({
      connected_account: value
    });

    if (connectedAccount) return connectedAccount;
    window.location.href = salesforceCoreRedirectUrl;
  }

  async put(value) {
    value.service = parseInt(value.service);
    const { id } = value;
    delete value.id;
    const { data: { connected_account: connectedAccount } } = await client.putIntegration(
      id, { connected_account: value },
    );
    return connectedAccount;
  }

  async delete(id) {
    await client.deleteIntegration(id);
  }
}

export default new IntegrationService;
