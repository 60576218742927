<template>
  <div>
    <label
      v-if="$slots.label"
      :for="id"
      class="block text-sm font-medium text-gray-700">
      <slot name="label" />
    </label>
    <div class="pt-4">
      <z-switch
        :value="value"
        label="Display for every return"
        @update:value="updateValue" />

      <template v-if="!value">
        <z-table
          v-if="filters.length"
          :fields="fields"
          :data="filters"
          :cell-class="['align-top']"
          :table-class="'mb-36'"
          class="pb-6 mb-2">
          <template #cell(field)="{ row, rowIndex }">
            <z-select
              :id="`field-${rowIndex}`"
              :value="row.field"
              :options="fieldOptions"
              class="w-44 leading-10"
              content-width
              @update:value="updateField(rowIndex, 'field', $event)" />
            <small
              v-if="filteredErrors[rowIndex] && filteredErrors[rowIndex].field"
              class="text-red-600">
              {{ filteredErrors[rowIndex].field }}
            </small>
          </template>
          <template #cell(operator)="{ row, rowIndex }">
            <z-select
              :id="`operator-${rowIndex}`"
              :value="row.operator"
              :options="formatOperatorOptions(row.field)"
              :options-class="row.field !== 'return_from_country' ? 'h-28' : ''"
              class="w-40 leading-10"
              content-width
              @update:value="updateField(rowIndex, 'operator', $event)" />
            <small
              v-if="filteredErrors[rowIndex] && filteredErrors[rowIndex].operator"
              class="w-min text-red-600">
              {{ filteredErrors[rowIndex].operator }}
            </small>
          </template>
          <template #cell(name)="{ row, rowIndex }">
            <z-input
              v-if="row.field === 'custom'"
              :id="`name-${rowIndex}`"
              :value="row.name"
              placeholder="Field name"
              class="w-32 leading-10"
              @update:value="updateField(rowIndex, 'name', $event)" />
            <small
              v-if="filteredErrors[rowIndex] && filteredErrors[rowIndex].name"
              class="text-red-600">
              {{ filteredErrors[rowIndex].name }}
            </small>
          </template>
          <template #cell(value)="{ row, rowIndex }">
            <z-select
              v-if="row.field === 'return_from_country'"
              :id="`value-${rowIndex}`"
              :value="row.value"
              :options="countryOptions"
              :multiple="row.operator === 'in' || row.operator === 'not_in'"
              class="w-40 leading-10"
              options-class="h-28"
              content-width
              options-right-position
              @update:value="updateField(rowIndex, 'value', $event)" />
            <z-input
              v-else
              :id="`value-${rowIndex}`"
              :value="row.value"
              placeholder="50"
              options-class="h-28"
              class="w-32 leading-10"
              @update:value="updateField(rowIndex, 'value', $event)" />
            <small
              v-if="filteredErrors[rowIndex] && filteredErrors[rowIndex].value"
              class="text-red-600">
              {{ filteredErrors[rowIndex].value }}
            </small>
          </template>
          <template #cell(and)>
            <span class="w-2.5 inline-block pt-3 mr-3">AND</span>
          </template>
          <template #cell(delete)="{ rowIndex }">
            <z-button @click="removeFilter(rowIndex)">
              <TrashIcon
                class="h-5 w-5"
                aria-hidden="true" />
            </z-button>
          </template>
        </z-table>

        <z-button
          secondary
          @click="addFilter">
          Add
        </z-button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayCriteriaField',
};
</script>

<script setup>
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps, nextTick, watch } from 'vue';
import { TrashIcon } from '@heroicons/vue/outline';

import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import ZButton from '../../atoms/Button';
import ZSwitch from '../../atoms/Switch';
import ZTable from '../../organisms/Table';

import CountryList from '../../../assets/js/countries.json';

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: Boolean,
    default: true,
  },
  filters: {
    type: Array,
    default: () => ([]),
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
});

const fields = [
  {key: 'delete', label: ''},
  'field',
  'name',
  'operator',
  'value',
  {key: 'and', label: ''},
];
const fieldOptions = [
  {value: 'return_from_country', label: 'Return From Country'},
  {value: 'total_value', label: 'Total Value'},
  {value: 'total_weight', label: 'Total Weight'},
  {value: 'custom', label: 'Custom'},
];
const operatorOptions = [
  {value: 'equals', label: 'Equals'},
  {value: 'not_equals', label: 'Does not equal'},
  {value: 'greater_than', label: 'Greater than'},
  {value: 'less_than', label: 'Less than'},
  {value: 'contains', label: 'Contains'},
  {value: 'not_contains', label: 'Does not contain'},
  {value: 'in', label: 'In'},
  {value: 'not_in', label: 'Not in'},
];
const countryOptions = Object.keys(CountryList).map(value => ({
  value, label: CountryList[value],
}));

const connectedField = useField('filters', undefined, {
  initialValue: props.filters,
});

const emit = defineEmits(['update:value', 'update:filters', 'update:field']);

watch(
  () => props.value,
  value => {
    if (value) {
      emit('update:filters', []);
      connectedField.handleChange([]);
      return;
    }

    if (!props.filters.length) {
      addFilter();
    }
  }
)

const updateValue = value => emit('update:value', value);

const addFilter = () => {
  const value = [...props.filters, {
    field: "return_from_country",
    operator: "equals",
    value: "US",
  }];
  emit('update:filters', value);
  connectedField.handleChange(value);
};

const updateField = async (index, field, value) => {
  emit('update:field', { index, field, value });

  if (field === 'field') {
    const newValue = value === 'return_from_country' ? 'US' : '0';
    emit('update:field', { index, field: 'value', value: newValue });
    emit('update:field', { index, field: 'operator', value: 'equals' });
    emit('update:field', { index, field: 'name', value: '' });
  }
  if (field === 'operator') {
    if (value === 'in' || value === 'not_in') {
      emit('update:field', { index, field: 'value', value: [] });
    }
  }

  await nextTick(async () => {
    await nextTick(() => {
      connectedField.handleChange(props.filters);
    });
  });
};

const filteredErrors = computed(() => {
  const result = {};

  Object.keys(props.errors).forEach(key => {
    if (key.startsWith('filters')) {
      const [other, fieldName] = key.split('].');
      const [_, index] = other.split('[');

      if (!(index in result)) result[index] = {};

      result[index][fieldName] = props.errors[key].replace(`filters[${index}].`, '');
    }
  });

  return result;
});

const formatOperatorOptions = (field) => {
  if (field === 'return_from_country') {
    return operatorOptions.filter(item => (
      item.value === 'equals' || item.value === 'not_equals'
      || item.value === 'in' || item.value === 'not_in'
    ));
  }
  if (field === 'custom') {
    return operatorOptions.filter(item => (
      item.value === 'equals' || item.value === 'not_equals'
    ));
  }
  return operatorOptions;
};

const removeFilter = (index) => {
  const value = props.filters.filter((_, i) => i !== index);
  emit('update:filters', value);
  connectedField.handleChange(value);
};
</script>
