import client from "../api";


class WebhookService {
  name = 'Webhook';

  async get() {
    const { data: { webhooks } } = await client.getWebhooks();
    return webhooks;
  }

  async post(value) {
    const { data: { webhook } } = await client.postWebhooks({ webhook: value });
    return webhook;
  }

  async put(value) {
    const { data: { webhook } } = await client.putWebhook(
      value.id, { webhook: value },
    );
    return webhook;
  }

  async delete(id) {
    await client.deleteWebhook(id);
  }

  async validateCallback(url) {
    return await client.validateCallback(url);
  }
}

export default new WebhookService;
