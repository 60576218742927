<template>
  <shipments-template
    v-model:filters="data.filters"
    :loading="page.data.loading"
    :data="page.data.items"
    :carriers="data.carriers"
    :page="data.page"
    :last-page="data.isLastPage"
    @prev="loadShipments"
    @next="loadShipments"
    @update:filters="loadShipments(1)">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </shipments-template>
</template>

<script>
export default {
  name: 'Shipments',
};
</script>

<script setup>
import { onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import ShipmentsTemplate from '../../templates/Shipments';

import usePage from "../../../mixins/usePage";
import { ShipmentService } from "../../../services";
import { showFailMessage, withLoading } from "../../../utils";
import client from "../../../api";

const page = usePage(ShipmentService);

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      const { data: carriers } = await client.getCarriers();
      data.carriers = carriers.filter(carrier => (
        !carrier.private && Object.keys(carrier.auth).length
      ));
    } catch (e) {
      await showFailMessage(page.data, 'Carrier list loading was failed!');
    }
  });

  await loadShipments();
});

const data = reactive({
  page: 1,
  pageSize: 10,
  isLastPage: false,
  filters: {
    tracking_number: '',
    type: '',
  },
  carriers: [],
});

const loadShipments = async (pageNum) => {
  if (pageNum) data.page = pageNum;

  pageNum = pageNum || data.page;
  const pageSize = data.pageSize;

  const filters = Object.assign({}, data.filters);

  await withLoading(page.data, async () => {
    try {
      const { data: { shipments } } = await client.getShipments({ page: pageNum, page_size: pageSize, ...filters });
      data.isLastPage = !shipments.length;

      if (!data.isLastPage || data.page === 1) {
        page.data.items = shipments;
      } else {
        data.page -= 1;
      }
    } catch (e) {
      await showFailMessage(page, "Shipments weren't loaded!");
    }
  });
};
</script>
