<template>
  <div>
    <url-input
      id="url"
      :value="cloudUrl"
      class="mb-4"
      placeholder="your-domain.demandware.net"
      @update:value="updateCloudUrl">
      <template #label>
        What's your Commerce Cloud URL?
      </template>
    </url-input>
  </div>
</template>

<script>
export default {
  name: 'SalesforceCommerceCloudForm',
};
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

import UrlInput from '../../atoms/UrlInput';

defineProps({
  cloudUrl: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:cloudUrl']);

const updateCloudUrl = value => emit('update:cloudUrl', value);
</script>
