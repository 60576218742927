import client from "../api";


class LogDetailsService {
  name = 'Log details';

  async get(id) {
    const { data } = await client.getLogItem(id);
    return data;
  }
}

export default new LogDetailsService;
