<template>
  <webhooks-template
    :loading="page.data.loading"
    :webhooks="page.data.items"
    :callback-validator="validateCallback"
    :shipping-accounts="data.shippingAccounts"
    @create="page.create"
    @update="page.update"
    @delete="page.remove">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </webhooks-template>
</template>

<script>
export default {
  name: 'Webhooks',
};
</script>

<script setup>
import { onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import WebhooksTemplate from '../../templates/Webhooks';

import usePage from "../../../mixins/usePage";
import { WebhookService } from "../../../services";
import { showFailMessage, withLoading } from "../../../utils";
import client from "../../../api";

const page = usePage(WebhookService);

const data = reactive({
  shippingAccounts: [],
});

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      const { data: accounts } = await client.getShippingAccounts();
      data.shippingAccounts = accounts.map(account => account.shipping_account);
    } catch (e) {
      await showFailMessage(page.data, "Shipping Account list wasn't loaded!");
    }
  });

  await page.read();
});

const validateCallback = async url => {
  if (!url) return false;

  try {
    const { data: { status } } = await WebhookService.validateCallback(url);
    return status;
  } catch (e) {
    return false;
  }
};
</script>
