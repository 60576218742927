<template>
  <analytics-shipment-template
    v-model:filters="data.filters"
    :loading="page.data.loading"
    :data="page.data.items"
    :carriers="data.carriers"
    :service-types="data.serviceTypes"
    :shipping-accounts="data.shippingAccounts"
    :stats="data.stats"
    @export-data="exportData"
    @update:filters="read">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </analytics-shipment-template>
</template>

<script>
export default {
  name: 'AnalyticsShipment',
};
</script>

<script setup>
import { onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import AnalyticsShipmentTemplate from '../../templates/AnalyticsShipment';

import usePage from "../../../mixins/usePage";
import { AnalyticsShipmentService } from "../../../services";
import {showFailMessage, withLoading} from "../../../utils";
import client from "../../../api";

const page = usePage(AnalyticsShipmentService);

const data = reactive({
  serviceTypes: {},
  carriers: {},
  shippingAccounts: [],
  filters: {
    start_date: null,
    end_date: null,
    shipping_account_id: "",
    carrier: "",
    // is_outbound: "",
  },
  stats: [
    { title: "24h Shipments", subtitle: "total Shipments", value: 0 },
    { title: "7 Day Shipments", subtitle: "total Shipments", value: 0 },
    { title: "28 Day Shipments", subtitle: "total Shipments", value: 0 },
  ],
});

const read = async () => {
  await withLoading(page.data, async () => {
    try {
      page.data.items = await AnalyticsShipmentService.get(data.filters);
    } catch (e) {
      await showFailMessage(page.data, `${AnalyticsShipmentService.name} list wasn't loaded!`);
    }
  });
};

const exportData = async () => {
  try {
    await AnalyticsShipmentService.export(data.filters);
  } catch (e) {
    await showFailMessage(page.data, 'Export has failed. Please contact our support team.');
  }
};

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      const { data: rawData } = await client.getServiceTypes();

      for (let i = 0; i < rawData.length; ++i) {
        const carrier = rawData[i];
        data.carriers[carrier.slug] = carrier.display_name;

        for (let j = 0; j < carrier.service_types.length; ++j) {
          const service = carrier.service_types[j];
          data.serviceTypes[service.slug] = service.name;
        }
      }
    } catch (e) {
      await showFailMessage(page.data, "Service type list wasn't loaded!");
    }

    try {
      const { data: accounts } = await client.getShippingAccounts();
      data.shippingAccounts = accounts.map(account => account.shipping_account);
    } catch (e) {
      await showFailMessage(page.data, "Shipping Account list wasn't loaded!");
    }

    try {
      const { data: { total_1_day, total_7_days, total_28_days } } = await client.getUser();
      data.stats[0].value = total_1_day;
      data.stats[1].value = total_7_days;
      data.stats[2].value = total_28_days;
    } catch(e) {
      await showFailMessage(page.data, "User details weren't loaded!");
    }
  });

  await read();
});
</script>
