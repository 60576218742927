<template>
  <div class="inline-flex items-center">
    <input
      :id="id"
      :name="name"
      :disabled="disabled"
      :checked="value"
      type="checkbox"
      class="rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200
      text-gray-700 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50
      disabled:cursor-not-allowed"
      @click="updateValue">
    <label
      :for="id"
      class="ml-2">
      <slot name="label" />
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
}
</script>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:value']);

const updateValue = () => emit('update:value', !props.value);
</script>
