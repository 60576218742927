<template>
  <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
    <button
      type="button"
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2
      focus:ring-inset focus:ring-indigo-500 md:hidden"
      @click="toggleSidebar">
      <span class="sr-only">Open sidebar</span>
      <MenuAlt2Icon
        class="h-6 w-6"
        aria-hidden="true" />
    </button>
    <div class="flex-1 px-4 flex justify-between">
      <div class="flex-1 flex">
        <template v-if="$slots.title">
          <h2
            class="my-auto">
            <slot name="title" />
          </h2>
        </template>
      </div>
      <div class="ml-4 flex items-center md:ml-6">
        <nav>
          <ul>
            <slot />
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>

<script setup>
import { defineEmits } from 'vue';

import { MenuAlt2Icon } from '@heroicons/vue/outline';

const emit = defineEmits(['toggle-sidebar']);

const toggleSidebar = () => emit('toggle-sidebar');
</script>
