import { createWebHashHistory, createRouter } from "vue-router";

import PublicPage from '../components/templates/PublicPage';
import HomePage from '../components/pages/Home';
import PublicLoginPage from '../components/pages/PublicLogin';
import PublicLoginMfaPage from '../components/pages/PublicLoginMfa';
import PublicLoginMfaConfirmPage from '../components/pages/PublicLoginMfaConfirm';
import PublicForgotPasswordPage from '../components/pages/PublicForgotPassword';
import LogsPage from '../components/pages/Logs';
import LogDetailsPage from '../components/pages/LogDetails';
import UsersPage from '../components/pages/Users';
import ShippingAccountsPage from '../components/pages/ShippingAccounts';
import WebhooksPage from '../components/pages/Webhooks';
import LocationsPage from '../components/pages/Locations';
import ReturnMethodPage from '../components/pages/ReturnMethods';
import ReturnDetailsPage from '../components/pages/ReturnDetails';
import AnalyticsShipmentPage from '../components/pages/AnalyticsShipment';
import IntegrationsPage from '../components/pages/Integrations';
import ShipmentsPage from '../components/pages/Shipments';
import ShipmentDetailsPage from '../components/pages/ShipmentDetails';


import store from "../store"

const routes = [
    {
        path: "/account/login",
        name: "login",
        component: PublicLoginPage,
    },
    {
        path: "/account/login/mfa",
        name: "login-mfa",
        component: PublicLoginMfaPage,
    },
    {
        path: "/account/login/mfa/confirm",
        name: "login-mfa-confirm",
        component: PublicLoginMfaConfirmPage,
        props: route => ({ email: route.params.email }),
        beforeEnter: (to, from) => {
            if (from.name === 'login-mfa' || from.name === 'login' || !!to.params.email) return;
            return { name: 'login-mfa' };
        },
    },
    {
        path: "/account/forgot_password",
        name: "forgot-password",
        component: PublicForgotPasswordPage,
    },
    {
        path: "/",
        component: PublicPage,
        props: route => ({
            title: route.meta.title === undefined
                ? route.name.replace("-", " ").split(" ").map(item => (
                    item.charAt(0).toUpperCase() + item.substr(1).toLowerCase()
                )).join(" ")
                : route.meta.title,
            pathName: route.name,
        }),
        children: [
            {
                path: "",
                name: "index",
                redirect: {
                    name: "home",
                }
            },
            {
                path: "/account/home",
                name: "home",
                component: HomePage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/shipments",
                name: "shipments",
                component: ShipmentsPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/shipments/:id",
                name: "shipment-details",
                component: ShipmentDetailsPage,
                props: route => ({ id: route.params.id }),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/integrations",
                name: "integrations",
                component: IntegrationsPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/setup/logs",
                name: "logs",
                component: LogsPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/setup/logs/:id",
                name: "log-details",
                component: LogDetailsPage,
                props: route => ({ id: route.params.id }),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/setup/users",
                name: "users",
                component: UsersPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/setup/shipping-accounts",
                name: "shipping-accounts",
                component: ShippingAccountsPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/setup/webhooks",
                name: "webhooks",
                component: WebhooksPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/setup/locations",
                name: "locations",
                component: LocationsPage,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/returns/settings",
                name: "return-methods",
                component: ReturnMethodPage,
                meta: {
                    requiresAuth: true,
                    title: '',
                },
            },
            {
                path: "/account/returns/:id",
                name: "return-details",
                component: ReturnDetailsPage,
                props: route => ({ id: route.params.id }),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: "/account/reports",
                name: "reports",
                component: AnalyticsShipmentPage,
                meta: {
                    requiresAuth: true
                },
            },
        ],
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next({ name: 'login' });
    } else {
        next()
    }
})

export default router;