<template>
  <public-login-mfa-template
    v-model:email="data.email"
    :error="data.error"
    @send-password="sendPassword"
    @alert-close="data.error = ''" />
</template>

<script>
export default {
  name: 'PublicLoginMfa',
};
</script>

<script setup>
import { reactive } from 'vue';
import { useStore } from "vuex";

import PublicLoginMfaTemplate from '../../templates/PublicLoginMfa';

const store = useStore();

const data = reactive({
  email: "",
  error: "",
});

const sendPassword = async () => {
  try {
    await store.dispatch('loginMfa', { email: data.email });
  } catch(e) {
    try {
      const { response: { data: { error: { message } } } } = e;
      data.error = message;
    } catch {
      data.error = 'Unknown error message from the server. Please contact our support team.';
    }
  }
};
</script>
