<template>
  <Combobox
    v-slot="{ open }"
    as="div"
    :model-value="inputValue"
    :disabled="disabled"
    :multiple="multiple"
    :nullable="nullable"
    @update:modelValue="handleInput">
    <ComboboxLabel
      v-if="$slots.label"
      class="block text-sm font-medium text-gray-700">
      <slot name="label" />
    </ComboboxLabel>
    <div class="relative">
      <div
        class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-1 pr-10
        text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500
        focus:border-indigo-500 sm:text-sm">
        <ComboboxInput
          :disabled="disabled"
          :display-value="displayValue"
          :class="{'cursor-not-allowed': disabled}"
          :placeholder="placeholder"
          class="w-full border-none py-2 pl-3 text-sm leading-5 text-gray-900 focus:ring-0"
          @click="clickButton(open)"
          @change="data.searchFilter = $event.target.value" />
        <ComboboxButton
          ref="comboboxButton"
          :disabled="disabled"
          :class="{'cursor-not-allowed': disabled}"
          class="absolute inset-y-0 right-0 flex items-center pr-2">
          <SelectorIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true" />
        </ComboboxButton>
      </div>

      <transition
        :class="optionsTopPosition ? 'bottom-full' : ''"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="absolute mb-1 w-full rounded-md bg-white shadow-lg">
        <ComboboxOptions
          :class="[
            contentWidth ? 'w-min' : 'w-full',
            optionsRightPosition ? 'right-0' : 'left-0',
            optionsClass,
          ]"
          class="absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base
          ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <span
            v-if="!options.length"
            class="p-4">
            <slot name="nooptions">
              The list is empty
            </slot>
          </span>
          <ComboboxOption
            v-for="option in filteredOptions"
            v-slot="{ active, selected }"
            :key="option"
            :value="option.value || option"
            as="template">
            <li
              :class="[
                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-8 pr-4'
              ]">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ option.label || option.value || option }}
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                ]">
                <CheckIcon
                  class="h-5 w-5"
                  aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </transition>
    </div>
    <p
      v-if="errorMessage"
      class="mt-2 text-sm text-red-600">
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </p>
  </Combobox>
</template>

<script>
export default {
  name: 'ZSelect',
}
</script>

<script setup>
import { useField } from "vee-validate";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
  ComboboxButton,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { defineProps, defineEmits, computed, watch, reactive, ref } from "vue";

const comboboxButton = ref(null);

const props = defineProps({
  value: {
    type: null,
    required: true,
  },
  id: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => ([]),
  },
  placeholder: {
    type: String,
    default: "-- Blank --",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  contentWidth: {
    type: Boolean,
    default: false,
  },
  optionsClass: {
    type: String,
    default: '',
  },
  optionsRightPosition: {
    type: Boolean,
    default: false,
  },
  optionsTopPosition: {
    type: Boolean,
    default: false,
  },
  search: {
    type: Boolean,
    default: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  nullable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:value']);

const data = reactive({
  searchFilter: '',
});

const connectedField = useField(props.id, undefined, {
  initialValue: props.value,
});

watch(
  () => props.value,
  (value) => connectedField.handleChange(value),
);

const inputValue = connectedField.value;
const errorMessage = connectedField.errorMessage;
// const meta = connectedField.meta;

const filteredOptions = computed(() => props.options.filter(item => (
  !data.searchFilter || (JSON.stringify(item).toLowerCase() || '').includes(data.searchFilter.toLowerCase())
)));

const optionsMapping = computed(() => props.options.reduce((result, { label, value }) => {
  result[value] = label;
  return result;
}, {}));

const handleInput = (value) => {
  connectedField.handleChange(value);
  emit('update:value', value);
  data.searchFilter = '';
};

const displayValue = (value) => {
  if (value && Array.isArray(value)) {
    return value.map(item => optionsMapping.value[item] || item).join(', ');
  }
  return optionsMapping.value[value] || value;
};

const clearSearchFilter = () => data.searchFilter = '';

const clickButton = (isOpen) => {
  if (isOpen) clearSearchFilter();
  comboboxButton.value.el.click();
};
</script>
