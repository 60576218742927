<template>
  <div>
    <breadcrumbs
      :value="breadcrumbsData"
      class="mb-6" />

    <slot name="message" />

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Log details
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div
            v-for="item in renderData"
            :key="item"
            class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ item.label }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <template v-if="loading">
                <div class="animate-pulse w-2/5 flex space-x-4">
                  <div class="flex-1 space-y-4 py-1">
                    <div class="h-4 bg-blue-400 rounded" />
                  </div>
                </div>
              </template>
              <template v-else-if="item.label === 'Status Code'">
                <badge
                  v-if="item.value"
                  :type="convertStatusCode(item.value)">
                  {{ item.value }}
                </badge>
              </template>
              <template v-else-if="item.label === 'Created'">
                <div :title="props.value.created">
                  {{ item.value }}
                </div>
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <tabs
              v-if="!loading"
              :data="tabsData">
              <template #request="{ content }">
                <json-formatter :value="content" />
              </template>
              <template #response="{ content }">
                <json-formatter :value="content" />
              </template>
            </tabs>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogDetailsTemplate',
}
</script>

<script setup>
import { defineProps, computed } from 'vue';
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import Badge from "../../atoms/Badge";
import JsonFormatter from '../../atoms/JsonFormatter';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import Tabs from '../../organisms/Tabs';
import { convertStatusCode } from "../../../utils";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const renderData = computed(() => ([
  {label: "URL", value: props.value.url},
  {label: "IP Address", value: props.value.ip_address},
  {label: "Status Code", value: props.value.status_code},
  {
    label: "Created",
    value: props.value.created
      ? formatDistanceToNow(
        new Date(props.value.created), { addSuffix: true }
      )
      : '',
  },
  {label: "HTTP Method", value: props.value.http_method},
  {label: "Content Type", value: props.value.content_type},
  {label: "User Agent", value: props.value.user_agent},
  {label: "", value: ""},
]));

const tabsData = computed(() => ([
  {
    label: "Request",
    content: props.value.request,
  },
  {
    label: "Response",
    content: props.value.response,
  },
]));

const breadcrumbsData = computed(() => ([
  {
    label: "home",
    to: { name: "home" },
  },
  {
    label: "logs",
    to: { name: "logs" },
  },
  {
    label: props.value.id,
    to: '',
  },
]))
</script>
