<template>
  <router-view @sign-out="signOut" />
</template>

<script>
import { runAutoLogout } from "./utils";

export default {
  name: 'App',

  created() {
    runAutoLogout(this.signOut);
  },

  methods: {
    async signOut() {
      await this.$store.dispatch("logout");
      await this.$router.go();
    },
  }
}
</script>
