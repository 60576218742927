<template>
  <modal
    :value="isShown"
    :validation="schema"
    size="huge"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <slot name="title" />
    </template>
    <template
      v-if="data.localValue"
      #body>
      <div class="flex flex-col w-full">
        <z-input
          id="name"
          v-model:value="data.localValue.name"
          class="mb-4 w-full">
          <template #label>
            Location Name
          </template>
        </z-input>
        <z-select
          id="type"
          v-model:value="data.localValue.type"
          :options="typeOptions"
          class="mb-4 w-full">
          <template #label>
            Type
          </template>
        </z-select>

        <div class="flex gap-3">
          <google-autocomplete-input
            id="address"
            v-model:value="data.address"
            class="mb-4 w-full"
            @update:address="updateAddress">
            <template #label>
              Address Search
            </template>
          </google-autocomplete-input>
          <z-input
            id="company"
            v-model:value="data.localValue.company"
            class="mb-4 w-full">
            <template #label>
              Company
            </template>
          </z-input>
        </div>

        <div class="flex gap-3">
          <z-input
            id="street1"
            v-model:value="data.localValue.street1"
            :validation="false"
            class="mb-4 w-full">
            <template #label>
              Street 1
            </template>
          </z-input>
          <z-input
            id="street2"
            v-model:value="data.localValue.street2"
            :validation="false"
            class="mb-4 w-full">
            <template #label>
              Street 2
            </template>
          </z-input>
        </div>
        <div class="flex gap-3">
          <z-input
            id="city"
            v-model:value="data.localValue.city"
            :validation="false"
            class="mb-4 w-full">
            <template #label>
              City
            </template>
          </z-input>
          <z-input
            id="state"
            v-model:value="data.localValue.state"
            :validation="false"
            class="mb-4 w-full">
            <template #label>
              State
            </template>
          </z-input>
        </div>
        <div class="flex gap-3">
          <z-input
            id="postal_code"
            v-model:value="data.localValue.postal_code"
            :validation="false"
            class="mb-4 w-full">
            <template #label>
              Postal Code
            </template>
          </z-input>
          <z-select
            id="country"
            v-model:value="data.localValue.country"
            :options="countryOptions"
            class="mb-4 w-full">
            <template #label>
              Country
            </template>
          </z-select>
        </div>
        <div class="flex gap-3">
          <z-input
            id="phone"
            v-model:value="data.localValue.phone"
            class="mb-4 w-full">
            <template #label>
              Phone
            </template>
          </z-input>
          <z-input
            id="email"
            v-model:value="data.localValue.email"
            class="mb-4 w-full">
            <template #label>
              Email
            </template>
          </z-input>
        </div>
        <div class="flex gap-3 mb-6">
          <z-select
            id="timezone"
            v-model:value="data.localValue.timezone"
            :options="timezoneOptions"
            class="mb-4 w-full"
            placeholder="-- SELECT --"
            options-top-position>
            <template #label>
              Timezone
            </template>
          </z-select>
          <div class="flex gap-3 w-full">
            <z-input
              id="cutoff_hours"
              v-model:value="data.localValue.cutoff_hours"
              :min="0"
              :max="24"
              type="number"
              class="mb-4 w-full">
              <template #label>
                Cut-off Hour
              </template>
            </z-input>
            <z-input
              id="cutoff_minutes"
              v-model:value="data.localValue.cutoff_minutes"
              :min="0"
              :max="60"
              type="number"
              class="mb-4 w-full">
              <template #label>
                Cut-off Minutes
              </template>
            </z-input>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <z-button
        type="success"
        full-width
        submit>
        <slot name="send-button-label" />
      </z-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'LocationModal',
};
</script>

<script setup>
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { string, number, object } from 'yup';

import ZInput from '../../atoms/Input';
import GoogleAutocompleteInput from '../../atoms/GoogleAutocompleteInput';
import ZSelect from '../../atoms/Select';
import ZButton from '../../atoms/Button';
import Modal from '../../organisms/Modal';

import CountryList from '../../../assets/js/countries.json';
import TimezoneList from '../../../assets/js/timezones.json';

const countryOptions = Object.keys(CountryList).map(value => ({
  value, label: CountryList[value],
}));
const timezoneOptions = TimezoneList.map(value => ({ value, label: value }));

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  typeOptions: {
    type: Array,
    required: true,
  },
  isShown: {
    type: Boolean,
    default: false,
  },
});

const localValueFactory = () => ({
  name: "",
  type: "",
  company: "",
  phone: "",
  email: "",
  street1: "",
  street2: "",
  street3: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  timezone: "",
  cutoff_hours: 0,
  cutoff_minutes: 0,
});

const data = reactive({
  address: '',
  localValue: {
    ...localValueFactory(),
    ...props.value,
  },
  countryOptions,
  timezoneOptions: [
    {value: '', label: '-- SELECT --'},
    ...timezoneOptions,
  ],
});

const emit = defineEmits(['send', 'close']);

const schema = computed(() => object({
  name: string().max(35).required().label('Name'),
  type: string()
    .required()
    .oneOf(
      props.typeOptions
        ? props.typeOptions.map(option => option.value)
        : []
    )
    .label('Type'),
  company: string().max(35).required().label('Company'),
  address: string().required().label('Address'),
  // street2: string().label('Street 2'),
  // street3: string().label('Street 3'),
  // city: string().required().label('City'),
  // state: string().required().label('State'),
  // postal_code: string().required().label('Postal Code'),
  country: string().required().oneOf(Object.keys(CountryList)).label('Country'),
  phone: string().min(10).max(15).required().label('Phone'),
  email: string().email().required().label('Email'),
  timezone: string().label('Timezone'),
  cutoff_hours: number().label('Cut-off Hours'),
  cutoff_minutes: number().label('Cut-off Minutes'),
}));

watch(
  () => props.value,
  (value) => {
    data.localValue = {
      ...data.localValue,
      ...value,
    };
    const { street1, city, state, country, postal_code: postalCode } = data.localValue;
    data.address = [street1, city, state, country, postalCode]
      .filter(part => part)
      .join(', ');
  }
);

const closeModal = async () => {
  data.localValue = localValueFactory();
  await nextTick(() => emit('close'));
};

const submit = async () => {
  const value = data.localValue;
  data.localValue = localValueFactory();
  await nextTick(() => emit('send', value));
};

const updateAddress = place => {
  data.localValue = Object.assign({}, data.localValue, {
    street1: [place.route, place.street_number]
      .filter(item => item)
      .join(' '),
    city: place.locality || place.administrative_area_level_2,
    state: place.administrative_area_level_1 === place.administrative_area_level_1.toUpperCase()
      ? place.administrative_area_level_1
      : '',
    postal_code: (place.postal_code || '').replace(' ', ''),
    country: place.country,
  });
};
</script>
