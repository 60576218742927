<template>
  <div class="pr-4">
    <h3 class="mb-8">
      Shipments by Carrier
      <badge
        v-if="showTopBadge"
        type="info">
        Top 6
      </badge>
    </h3>
    <pie-chart
      :chart-data="data"
      :options="options"
      :plugins="[ChartDataLabels]" />
  </div>
</template>

<script>
export default {
  name: 'ShipmentsCarrierPieChart',
}
</script>

<script setup>
import { defineProps } from 'vue';
import { PieChart } from 'vue-chart-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Badge from '../../atoms/Badge';

defineProps({
  data: {
    type: Object,
    required: true,
  },
  showTopBadge: {
    type: Boolean,
    default: true,
  },
});

const options = ({
  responsive: true,
  layout: {
    padding: 0,
  },
  datasets: {
    pie: {
      // offset: 15,
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 11,
        usePointStyle: true,
        pointStyle: 'rectRounded',
        padding: 15,
      },
    },
    datalabels: {
      formatter: (value, ctx) => {
        const sum = ctx.chart.data.datasets[0].data.reduce((value, item) => {
          value += item;
          return value;
        }, 0);
        return (value * 100 / sum).toFixed(2) + "%";
      },
      color: '#fff',
    }
  }
});
</script>
