export default [
  {
    "carrier": "usps_endicia",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": true,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-05-02T00:00:00",
    "currency": "IDR",
    "from_country": "TA",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "DK",
    "total_costs": 769,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-05-02T00:00:00",
    "currency": "USD",
    "from_country": "ME",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "MG",
    "total_costs": 168,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-05-02T00:00:00",
    "currency": "EUR",
    "from_country": "JO",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "FR",
    "total_costs": 510,
    "tracking_number": ""
  },
  // {
  //   "carrier": "japanpost",
  //   "created": "2021-05-03T00:00:00",
  //   "currency": "USD",
  //   "from_country": "TR",
  //   "is_outbound": false,
  //   "service": "air",
  //   "to_country": "NU",
  //   "total_costs": 508,
  //   "tracking_number": ""
  // },
  // {
  //   "carrier": "crossflight",
  //   "created": "2021-05-03T00:00:00",
  //   "currency": "USD",
  //   "from_country": "TR",
  //   "is_outbound": false,
  //   "service": "air",
  //   "to_country": "NU",
  //   "total_costs": 508,
  //   "tracking_number": ""
  // },
  // {
  //   "carrier": "post_nord",
  //   "created": "2021-05-03T00:00:00",
  //   "currency": "USD",
  //   "from_country": "TR",
  //   "is_outbound": false,
  //   "service": "air",
  //   "to_country": "NU",
  //   "total_costs": 508,
  //   "tracking_number": ""
  // },
  // {
  //   "carrier": "dhl_germany",
  //   "created": "2021-05-03T00:00:00",
  //   "currency": "USD",
  //   "from_country": "TR",
  //   "is_outbound": false,
  //   "service": "air",
  //   "to_country": "NU",
  //   "total_costs": 508,
  //   "tracking_number": ""
  // },
  {
    "carrier": "purolator_international",
    "created": "2021-05-03T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-05-03T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 508,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-05-03T00:00:00",
    "currency": "CHF",
    "from_country": "JM",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "ER",
    "total_costs": 707,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-05-03T00:00:00",
    "currency": "JPY",
    "from_country": "LU",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "PH",
    "total_costs": 368,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-05-04T00:00:00",
    "currency": "USD",
    "from_country": "SE",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "EC",
    "total_costs": 579,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-05-04T00:00:00",
    "currency": "HKD",
    "from_country": "BV",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "DK",
    "total_costs": 794,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-05-04T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "OM",
    "total_costs": 333,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-05-04T00:00:00",
    "currency": "USD",
    "from_country": "PG",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "RU",
    "total_costs": 556,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-05-04T00:00:00",
    "currency": "USD",
    "from_country": "MD",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "KY",
    "total_costs": 169,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-05T00:00:00",
    "currency": "USD",
    "from_country": "MW",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "CR",
    "total_costs": 248,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-05-05T00:00:00",
    "currency": "USD",
    "from_country": "NL",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "AW",
    "total_costs": 984,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-05-05T00:00:00",
    "currency": "USD",
    "from_country": "MR",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "RU",
    "total_costs": 309,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-05-05T00:00:00",
    "currency": "USD",
    "from_country": "JE",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BE",
    "total_costs": 8,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-05-06T00:00:00",
    "currency": "USD",
    "from_country": "YE",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "XZ",
    "total_costs": 282,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-05-06T00:00:00",
    "currency": "USD",
    "from_country": "BE",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "CV",
    "total_costs": 17,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-05-06T00:00:00",
    "currency": "TWD",
    "from_country": "IC",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "BG",
    "total_costs": 882,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-05-06T00:00:00",
    "currency": "EUR",
    "from_country": "MK",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "BF",
    "total_costs": 859,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-05-06T00:00:00",
    "currency": "USD",
    "from_country": "BS",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "SH",
    "total_costs": 433,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-05-06T00:00:00",
    "currency": "PAB",
    "from_country": "JM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "DZ",
    "total_costs": 205,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-05-06T00:00:00",
    "currency": "USD",
    "from_country": "ES",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "PE",
    "total_costs": 19,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-05-06T00:00:00",
    "currency": "USD",
    "from_country": "TL",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "YE",
    "total_costs": 657,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-05-06T00:00:00",
    "currency": "EUR",
    "from_country": "GM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "MO",
    "total_costs": 484,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-05-07T00:00:00",
    "currency": "USD",
    "from_country": "YT",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "MU",
    "total_costs": 166,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-05-07T00:00:00",
    "currency": "NZD",
    "from_country": "LV",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "GB",
    "total_costs": 329,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-05-08T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "JE",
    "total_costs": 323,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-05-08T00:00:00",
    "currency": "USD",
    "from_country": "IS",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "NF",
    "total_costs": 157,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-05-08T00:00:00",
    "currency": "CZK",
    "from_country": "SK",
    "is_outbound": true,
    "service": "air",
    "to_country": "SZ",
    "total_costs": 823,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-05-08T00:00:00",
    "currency": "USD",
    "from_country": "DJ",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "NZ",
    "total_costs": 430,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-05-09T00:00:00",
    "currency": "USD",
    "from_country": "US",
    "is_outbound": true,
    "service": "air",
    "to_country": "TD",
    "total_costs": 757,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-05-09T00:00:00",
    "currency": "USD",
    "from_country": "SV",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "CZ",
    "total_costs": 253,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-05-09T00:00:00",
    "currency": "AUD",
    "from_country": "PA",
    "is_outbound": false,
    "service": "air",
    "to_country": "UG",
    "total_costs": 406,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-05-09T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "TN",
    "total_costs": 207,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-05-09T00:00:00",
    "currency": "USD",
    "from_country": "BN",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "IE",
    "total_costs": 244,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-10T00:00:00",
    "currency": "USD",
    "from_country": "CC",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "LS",
    "total_costs": 248,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-05-10T00:00:00",
    "currency": "USD",
    "from_country": "MO",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "KN",
    "total_costs": 504,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-05-10T00:00:00",
    "currency": "USD",
    "from_country": "SR",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "MR",
    "total_costs": 926,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-05-10T00:00:00",
    "currency": "NOK",
    "from_country": "CX",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "MG",
    "total_costs": 481,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-05-10T00:00:00",
    "currency": "USD",
    "from_country": "AN",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "IN",
    "total_costs": 550,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "SI",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "ER",
    "total_costs": 565,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "ME",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "OM",
    "total_costs": 299,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "SL",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "JP",
    "total_costs": 479,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "IE",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "PT",
    "total_costs": 554,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "KY",
    "is_outbound": false,
    "service": "air",
    "to_country": "TZ",
    "total_costs": 10,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "TM",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "MV",
    "total_costs": 546,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "AG",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "VE",
    "total_costs": 509,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-05-11T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "SK",
    "total_costs": 408,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-05-12T00:00:00",
    "currency": "USD",
    "from_country": "ME",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BD",
    "total_costs": 360,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-05-12T00:00:00",
    "currency": "USD",
    "from_country": "US",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "LK",
    "total_costs": 350,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-05-12T00:00:00",
    "currency": "USD",
    "from_country": "MG",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "FO",
    "total_costs": 639,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-05-13T00:00:00",
    "currency": "DKK",
    "from_country": "GU",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "LT",
    "total_costs": 824,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-05-13T00:00:00",
    "currency": "USD",
    "from_country": "ML",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "MS",
    "total_costs": 185,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-05-13T00:00:00",
    "currency": "USD",
    "from_country": "VC",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "GT",
    "total_costs": 436,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-05-13T00:00:00",
    "currency": "USD",
    "from_country": "AQ",
    "is_outbound": false,
    "service": "air",
    "to_country": "FM",
    "total_costs": 370,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-05-13T00:00:00",
    "currency": "USD",
    "from_country": "IQ",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "PE",
    "total_costs": 804,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-05-13T00:00:00",
    "currency": "USD",
    "from_country": "NL",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "MM",
    "total_costs": 217,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-05-14T00:00:00",
    "currency": "EUR",
    "from_country": "TG",
    "is_outbound": true,
    "service": "air",
    "to_country": "PW",
    "total_costs": 184,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-05-14T00:00:00",
    "currency": "USD",
    "from_country": "GE",
    "is_outbound": false,
    "service": "air",
    "to_country": "AO",
    "total_costs": 240,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-05-14T00:00:00",
    "currency": "USD",
    "from_country": "TG",
    "is_outbound": true,
    "service": "air",
    "to_country": "NC",
    "total_costs": 755,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-05-14T00:00:00",
    "currency": "EUR",
    "from_country": "CC",
    "is_outbound": true,
    "service": "air",
    "to_country": "KZ",
    "total_costs": 657,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-05-14T00:00:00",
    "currency": "USD",
    "from_country": "SL",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "NI",
    "total_costs": 293,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-05-15T00:00:00",
    "currency": "USD",
    "from_country": "SY",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "LK",
    "total_costs": 360,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-05-15T00:00:00",
    "currency": "USD",
    "from_country": "GS",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "JO",
    "total_costs": 739,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-05-16T00:00:00",
    "currency": "THB",
    "from_country": "BD",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "BB",
    "total_costs": 807,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-05-16T00:00:00",
    "currency": "USD",
    "from_country": "GM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AI",
    "total_costs": 528,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-05-16T00:00:00",
    "currency": "USD",
    "from_country": "BZ",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "MA",
    "total_costs": 382,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-16T00:00:00",
    "currency": "USD",
    "from_country": "BS",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "AE",
    "total_costs": 264,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-05-16T00:00:00",
    "currency": "USD",
    "from_country": "MG",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "MP",
    "total_costs": 959,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-05-17T00:00:00",
    "currency": "USD",
    "from_country": "UZ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "KG",
    "total_costs": 643,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-05-17T00:00:00",
    "currency": "USD",
    "from_country": "VI",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "SE",
    "total_costs": 836,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-05-17T00:00:00",
    "currency": "USD",
    "from_country": "AO",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "GN",
    "total_costs": 702,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-05-17T00:00:00",
    "currency": "USD",
    "from_country": "PW",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "GP",
    "total_costs": 646,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-05-17T00:00:00",
    "currency": "USD",
    "from_country": "LB",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "MY",
    "total_costs": 308,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-05-17T00:00:00",
    "currency": "DOP",
    "from_country": "EG",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "NP",
    "total_costs": 46,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-05-17T00:00:00",
    "currency": "USD",
    "from_country": "CR",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "BW",
    "total_costs": 307,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-05-18T00:00:00",
    "currency": "USD",
    "from_country": "IN",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "TH",
    "total_costs": 251,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-05-18T00:00:00",
    "currency": "GTQ",
    "from_country": "PG",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "PW",
    "total_costs": 104,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-05-18T00:00:00",
    "currency": "MYR",
    "from_country": "EG",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "VN",
    "total_costs": 798,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-05-18T00:00:00",
    "currency": "USD",
    "from_country": "PW",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "DM",
    "total_costs": 805,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-05-18T00:00:00",
    "currency": "USD",
    "from_country": "MQ",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "SG",
    "total_costs": 545,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-05-18T00:00:00",
    "currency": "USD",
    "from_country": "HN",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "UY",
    "total_costs": 307,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-05-18T00:00:00",
    "currency": "USD",
    "from_country": "BM",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "VG",
    "total_costs": 494,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-05-19T00:00:00",
    "currency": "USD",
    "from_country": "MW",
    "is_outbound": false,
    "service": "air",
    "to_country": "LB",
    "total_costs": 308,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-05-19T00:00:00",
    "currency": "USD",
    "from_country": "CN",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "NO",
    "total_costs": 276,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-05-19T00:00:00",
    "currency": "EUR",
    "from_country": "NZ",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "IM",
    "total_costs": 811,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-05-19T00:00:00",
    "currency": "USD",
    "from_country": "GU",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "MN",
    "total_costs": 61,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-05-19T00:00:00",
    "currency": "EUR",
    "from_country": "KN",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "KE",
    "total_costs": 840,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-05-19T00:00:00",
    "currency": "USD",
    "from_country": "TT",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "IE",
    "total_costs": 90,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-05-20T00:00:00",
    "currency": "USD",
    "from_country": "EC",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "BO",
    "total_costs": 803,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-05-20T00:00:00",
    "currency": "USD",
    "from_country": "SG",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "PA",
    "total_costs": 867,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-05-21T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "TK",
    "total_costs": 19,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-05-21T00:00:00",
    "currency": "MYR",
    "from_country": "KM",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "LU",
    "total_costs": 40,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-05-21T00:00:00",
    "currency": "AUD",
    "from_country": "TD",
    "is_outbound": true,
    "service": "air",
    "to_country": "TO",
    "total_costs": 309,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-05-21T00:00:00",
    "currency": "USD",
    "from_country": "SZ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "LY",
    "total_costs": 348,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-05-22T00:00:00",
    "currency": "USD",
    "from_country": "VN",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "KG",
    "total_costs": 140,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-05-22T00:00:00",
    "currency": "PLN",
    "from_country": "RW",
    "is_outbound": false,
    "service": "air",
    "to_country": "BL",
    "total_costs": 307,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-05-22T00:00:00",
    "currency": "USD",
    "from_country": "KI",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BB",
    "total_costs": 374,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-05-22T00:00:00",
    "currency": "USD",
    "from_country": "SC",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "GS",
    "total_costs": 368,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-05-22T00:00:00",
    "currency": "USD",
    "from_country": "KW",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "TW",
    "total_costs": 812,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-05-22T00:00:00",
    "currency": "USD",
    "from_country": "LV",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "CC",
    "total_costs": 704,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-05-22T00:00:00",
    "currency": "USD",
    "from_country": "HN",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "MW",
    "total_costs": 248,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-05-22T00:00:00",
    "currency": "HUF",
    "from_country": "GF",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "SZ",
    "total_costs": 209,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-05-23T00:00:00",
    "currency": "USD",
    "from_country": "SA",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "AO",
    "total_costs": 644,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-05-23T00:00:00",
    "currency": "USD",
    "from_country": "SG",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "EH",
    "total_costs": 210,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-05-23T00:00:00",
    "currency": "BRL",
    "from_country": "GR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "MF",
    "total_costs": 785,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-05-23T00:00:00",
    "currency": "GBP",
    "from_country": "GE",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "GM",
    "total_costs": 338,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-05-23T00:00:00",
    "currency": "MXN",
    "from_country": "GI",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "KM",
    "total_costs": 446,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-05-24T00:00:00",
    "currency": "USD",
    "from_country": "AD",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "NE",
    "total_costs": 777,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-05-24T00:00:00",
    "currency": "EUR",
    "from_country": "SI",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "EE",
    "total_costs": 807,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-05-24T00:00:00",
    "currency": "SGD",
    "from_country": "BM",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "TZ",
    "total_costs": 163,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-05-24T00:00:00",
    "currency": "USD",
    "from_country": "LB",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "DJ",
    "total_costs": 533,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-05-24T00:00:00",
    "currency": "USD",
    "from_country": "ID",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "FM",
    "total_costs": 591,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-05-24T00:00:00",
    "currency": "USD",
    "from_country": "SZ",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "IS",
    "total_costs": 835,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-05-25T00:00:00",
    "currency": "USD",
    "from_country": "AQ",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "MO",
    "total_costs": 643,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-05-25T00:00:00",
    "currency": "USD",
    "from_country": "DK",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "GT",
    "total_costs": 685,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-05-25T00:00:00",
    "currency": "USD",
    "from_country": "NA",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "EH",
    "total_costs": 300,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-05-25T00:00:00",
    "currency": "USD",
    "from_country": "TZ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "MV",
    "total_costs": 999,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-05-26T00:00:00",
    "currency": "USD",
    "from_country": "MH",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "VC",
    "total_costs": 730,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-05-26T00:00:00",
    "currency": "USD",
    "from_country": "GI",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "LI",
    "total_costs": 975,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-05-27T00:00:00",
    "currency": "USD",
    "from_country": "GE",
    "is_outbound": true,
    "service": "air",
    "to_country": "KZ",
    "total_costs": 899,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-05-27T00:00:00",
    "currency": "USD",
    "from_country": "AI",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "VE",
    "total_costs": 359,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-05-27T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "CZ",
    "total_costs": 541,
    "tracking_number": ""
  },
  {
    "carrier": "odfl",
    "created": "2021-05-27T00:00:00",
    "currency": "USD",
    "from_country": "NA",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "MH",
    "total_costs": 219,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-05-27T00:00:00",
    "currency": "USD",
    "from_country": "NZ",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "LC",
    "total_costs": 795,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-05-28T00:00:00",
    "currency": "USD",
    "from_country": "DM",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "MF",
    "total_costs": 684,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-05-28T00:00:00",
    "currency": "USD",
    "from_country": "HU",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "EH",
    "total_costs": 839,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-05-28T00:00:00",
    "currency": "USD",
    "from_country": "TK",
    "is_outbound": false,
    "service": "air",
    "to_country": "PK",
    "total_costs": 273,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-05-28T00:00:00",
    "currency": "USD",
    "from_country": "SA",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "BF",
    "total_costs": 137,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-05-28T00:00:00",
    "currency": "USD",
    "from_country": "FJ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AI",
    "total_costs": 838,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-05-28T00:00:00",
    "currency": "USD",
    "from_country": "ME",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "ZM",
    "total_costs": 649,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-28T00:00:00",
    "currency": "CAD",
    "from_country": "BY",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "QA",
    "total_costs": 440,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "HR",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "PF",
    "total_costs": 491,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "SD",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "GT",
    "total_costs": 681,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "MS",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "CI",
    "total_costs": 86,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "AZ",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "VG",
    "total_costs": 880,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "MX",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "IT",
    "total_costs": 73,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "PH",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "AX",
    "total_costs": 339,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-05-29T00:00:00",
    "currency": "USD",
    "from_country": "MT",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "KR",
    "total_costs": 142,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "VG",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CM",
    "total_costs": 512,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "DJ",
    "total_costs": 293,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "UY",
    "total_costs": 101,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "SN",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "SO",
    "total_costs": 927,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "AW",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "NU",
    "total_costs": 329,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "AQ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "NE",
    "total_costs": 207,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-05-30T00:00:00",
    "currency": "USD",
    "from_country": "LY",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "GS",
    "total_costs": 825,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "CK",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "LK",
    "total_costs": 906,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "LY",
    "total_costs": 789,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "UG",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "IR",
    "total_costs": 42,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "QA",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "EE",
    "total_costs": 809,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "ES",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "RE",
    "total_costs": 302,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "KZ",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "KR",
    "total_costs": 442,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-31T00:00:00",
    "currency": "USD",
    "from_country": "MT",
    "is_outbound": true,
    "service": "air",
    "to_country": "BH",
    "total_costs": 619,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-05-31T00:00:00",
    "currency": "ILS",
    "from_country": "RE",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "PE",
    "total_costs": 724,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-06-01T00:00:00",
    "currency": "USD",
    "from_country": "GF",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "PM",
    "total_costs": 660,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-06-01T00:00:00",
    "currency": "USD",
    "from_country": "MS",
    "is_outbound": false,
    "service": "air",
    "to_country": "NU",
    "total_costs": 242,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-06-01T00:00:00",
    "currency": "ARS",
    "from_country": "SC",
    "is_outbound": false,
    "service": "air",
    "to_country": "SO",
    "total_costs": 337,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-06-01T00:00:00",
    "currency": "EUR",
    "from_country": "DJ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MG",
    "total_costs": 502,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-06-01T00:00:00",
    "currency": "USD",
    "from_country": "AM",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "TW",
    "total_costs": 879,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-06-01T00:00:00",
    "currency": "USD",
    "from_country": "US",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "MX",
    "total_costs": 690,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-06-01T00:00:00",
    "currency": "RMB",
    "from_country": "MH",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "NO",
    "total_costs": 184,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-06-01T00:00:00",
    "currency": "USD",
    "from_country": "FJ",
    "is_outbound": true,
    "service": "air",
    "to_country": "GH",
    "total_costs": 753,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-06-01T00:00:00",
    "currency": "USD",
    "from_country": "TA",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "CN",
    "total_costs": 112,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-06-01T00:00:00",
    "currency": "EUR",
    "from_country": "BH",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "SR",
    "total_costs": 81,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-06-02T00:00:00",
    "currency": "USD",
    "from_country": "BL",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "FR",
    "total_costs": 854,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-06-02T00:00:00",
    "currency": "USD",
    "from_country": "TO",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "AU",
    "total_costs": 627,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-06-02T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "CL",
    "total_costs": 363,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-06-03T00:00:00",
    "currency": "EUR",
    "from_country": "KR",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "AQ",
    "total_costs": 732,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "SD",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "MP",
    "total_costs": 325,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "LK",
    "is_outbound": false,
    "service": "air",
    "to_country": "CN",
    "total_costs": 132,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "CN",
    "is_outbound": false,
    "service": "air",
    "to_country": "LS",
    "total_costs": 796,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "BT",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "GW",
    "total_costs": 26,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "MW",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "GB",
    "total_costs": 136,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "IL",
    "is_outbound": false,
    "service": "air",
    "to_country": "MU",
    "total_costs": 224,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-06-03T00:00:00",
    "currency": "USD",
    "from_country": "TJ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AE",
    "total_costs": 466,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-06-03T00:00:00",
    "currency": "CLF",
    "from_country": "SM",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BF",
    "total_costs": 404,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-06-04T00:00:00",
    "currency": "HKD",
    "from_country": "US",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "MA",
    "total_costs": 44,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-06-04T00:00:00",
    "currency": "USD",
    "from_country": "PK",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "BV",
    "total_costs": 325,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-06-04T00:00:00",
    "currency": "USD",
    "from_country": "TW",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "NR",
    "total_costs": 726,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-06-04T00:00:00",
    "currency": "USD",
    "from_country": "ET",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "KI",
    "total_costs": 104,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-06-04T00:00:00",
    "currency": "USD",
    "from_country": "YE",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "GI",
    "total_costs": 606,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-06-04T00:00:00",
    "currency": "SGD",
    "from_country": "FI",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "MP",
    "total_costs": 398,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-06-04T00:00:00",
    "currency": "USD",
    "from_country": "PH",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "AR",
    "total_costs": 669,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-06-04T00:00:00",
    "currency": "USD",
    "from_country": "MT",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "MD",
    "total_costs": 4,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-06-05T00:00:00",
    "currency": "USD",
    "from_country": "TG",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "LT",
    "total_costs": 91,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-06-05T00:00:00",
    "currency": "ARS",
    "from_country": "TF",
    "is_outbound": true,
    "service": "air",
    "to_country": "BF",
    "total_costs": 853,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-06-05T00:00:00",
    "currency": "USD",
    "from_country": "ET",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "SA",
    "total_costs": 732,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-06-05T00:00:00",
    "currency": "USD",
    "from_country": "LK",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "FM",
    "total_costs": 775,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-06-05T00:00:00",
    "currency": "USD",
    "from_country": "HR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "NG",
    "total_costs": 512,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-06-06T00:00:00",
    "currency": "USD",
    "from_country": "CI",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "AG",
    "total_costs": 142,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-06-06T00:00:00",
    "currency": "USD",
    "from_country": "AM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "CD",
    "total_costs": 371,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-06-06T00:00:00",
    "currency": "USD",
    "from_country": "MY",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "AL",
    "total_costs": 88,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-06-06T00:00:00",
    "currency": "HKD",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "TZ",
    "total_costs": 695,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-06-06T00:00:00",
    "currency": "ARS",
    "from_country": "CC",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "NR",
    "total_costs": 109,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-06-07T00:00:00",
    "currency": "EUR",
    "from_country": "KZ",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "CY",
    "total_costs": 692,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-06-07T00:00:00",
    "currency": "USD",
    "from_country": "BN",
    "is_outbound": false,
    "service": "air",
    "to_country": "LY",
    "total_costs": 916,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-06-07T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "LV",
    "total_costs": 955,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-06-07T00:00:00",
    "currency": "USD",
    "from_country": "EC",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "AG",
    "total_costs": 360,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-06-07T00:00:00",
    "currency": "USD",
    "from_country": "TM",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "TF",
    "total_costs": 67,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-06-07T00:00:00",
    "currency": "USD",
    "from_country": "TW",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "EE",
    "total_costs": 545,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-06-08T00:00:00",
    "currency": "DKK",
    "from_country": "KZ",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "LV",
    "total_costs": 207,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "VA",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "BI",
    "total_costs": 174,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-06-08T00:00:00",
    "currency": "BRL",
    "from_country": "BS",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "JM",
    "total_costs": 529,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "NP",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "FM",
    "total_costs": 890,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "SR",
    "total_costs": 702,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "GM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AS",
    "total_costs": 149,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "ZA",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "MH",
    "total_costs": 590,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-06-08T00:00:00",
    "currency": "EUR",
    "from_country": "AD",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "RS",
    "total_costs": 960,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "PM",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "VE",
    "total_costs": 585,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "AF",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "VU",
    "total_costs": 954,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "SC",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "HN",
    "total_costs": 743,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-06-08T00:00:00",
    "currency": "USD",
    "from_country": "FM",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "HK",
    "total_costs": 215,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "WS",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "SI",
    "total_costs": 476,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "NP",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "IM",
    "total_costs": 192,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "ME",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "WS",
    "total_costs": 18,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-06-09T00:00:00",
    "currency": "RUB",
    "from_country": "FR",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "DO",
    "total_costs": 556,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "PA",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "AI",
    "total_costs": 880,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-06-09T00:00:00",
    "currency": "HUF",
    "from_country": "CI",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "IN",
    "total_costs": 561,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "MT",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "VN",
    "total_costs": 993,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "HT",
    "is_outbound": true,
    "service": "air",
    "to_country": "CR",
    "total_costs": 363,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "IQ",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "LT",
    "total_costs": 707,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-06-09T00:00:00",
    "currency": "USD",
    "from_country": "BS",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "IT",
    "total_costs": 640,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-06-10T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "NE",
    "total_costs": 621,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-06-10T00:00:00",
    "currency": "USD",
    "from_country": "WF",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "VI",
    "total_costs": 991,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-06-10T00:00:00",
    "currency": "USD",
    "from_country": "BR",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "KP",
    "total_costs": 852,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-06-10T00:00:00",
    "currency": "USD",
    "from_country": "SJ",
    "is_outbound": true,
    "service": "air",
    "to_country": "DJ",
    "total_costs": 724,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-06-11T00:00:00",
    "currency": "EUR",
    "from_country": "SV",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "VN",
    "total_costs": 679,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-06-11T00:00:00",
    "currency": "USD",
    "from_country": "LB",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "AR",
    "total_costs": 90,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-06-11T00:00:00",
    "currency": "USD",
    "from_country": "KZ",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "PN",
    "total_costs": 236,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-06-12T00:00:00",
    "currency": "CAD",
    "from_country": "IM",
    "is_outbound": false,
    "service": "air",
    "to_country": "KM",
    "total_costs": 86,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-06-12T00:00:00",
    "currency": "USD",
    "from_country": "VI",
    "is_outbound": false,
    "service": "air",
    "to_country": "TT",
    "total_costs": 821,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-06-12T00:00:00",
    "currency": "USD",
    "from_country": "HM",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "SK",
    "total_costs": 924,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-06-12T00:00:00",
    "currency": "TWD",
    "from_country": "KZ",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "CY",
    "total_costs": 129,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "MX",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "KR",
    "total_costs": 0,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "BO",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "BE",
    "total_costs": 352,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "HU",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "SM",
    "total_costs": 421,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "BW",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "AC",
    "total_costs": 722,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-06-13T00:00:00",
    "currency": "PLN",
    "from_country": "YE",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "PG",
    "total_costs": 191,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "BM",
    "is_outbound": false,
    "service": "air",
    "to_country": "MQ",
    "total_costs": 88,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "RW",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "AR",
    "total_costs": 804,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-06-13T00:00:00",
    "currency": "USD",
    "from_country": "UY",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MZ",
    "total_costs": 904,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-06-14T00:00:00",
    "currency": "USD",
    "from_country": "MW",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "RU",
    "total_costs": 308,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-06-14T00:00:00",
    "currency": "ARS",
    "from_country": "NC",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "TW",
    "total_costs": 986,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-06-14T00:00:00",
    "currency": "USD",
    "from_country": "MU",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "CF",
    "total_costs": 388,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-06-15T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "TT",
    "total_costs": 259,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-06-15T00:00:00",
    "currency": "USD",
    "from_country": "SZ",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "MR",
    "total_costs": 209,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-06-15T00:00:00",
    "currency": "USD",
    "from_country": "BD",
    "is_outbound": true,
    "service": "air",
    "to_country": "GS",
    "total_costs": 187,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-06-15T00:00:00",
    "currency": "USD",
    "from_country": "QA",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "ZA",
    "total_costs": 646,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-06-15T00:00:00",
    "currency": "USD",
    "from_country": "EE",
    "is_outbound": false,
    "service": "air",
    "to_country": "SI",
    "total_costs": 56,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-06-15T00:00:00",
    "currency": "THB",
    "from_country": "ID",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "ML",
    "total_costs": 353,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "MQ",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "LR",
    "total_costs": 441,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "YT",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "TA",
    "total_costs": 58,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "KM",
    "is_outbound": false,
    "service": "air",
    "to_country": "CV",
    "total_costs": 326,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "SR",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "MT",
    "total_costs": 922,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "PK",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "PL",
    "total_costs": 524,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "NP",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "SK",
    "total_costs": 755,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "IC",
    "total_costs": 810,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-06-16T00:00:00",
    "currency": "USD",
    "from_country": "BN",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "PS",
    "total_costs": 229,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-06-17T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MW",
    "total_costs": 193,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-06-17T00:00:00",
    "currency": "USD",
    "from_country": "RS",
    "is_outbound": false,
    "service": "air",
    "to_country": "AS",
    "total_costs": 855,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-06-17T00:00:00",
    "currency": "ILS",
    "from_country": "BN",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "KY",
    "total_costs": 937,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-06-17T00:00:00",
    "currency": "USD",
    "from_country": "SG",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "GN",
    "total_costs": 838,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-06-17T00:00:00",
    "currency": "USD",
    "from_country": "AQ",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "GF",
    "total_costs": 526,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-06-17T00:00:00",
    "currency": "HUF",
    "from_country": "BB",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "GE",
    "total_costs": 86,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-06-18T00:00:00",
    "currency": "USD",
    "from_country": "BL",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "NC",
    "total_costs": 791,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-06-18T00:00:00",
    "currency": "USD",
    "from_country": "GW",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "AI",
    "total_costs": 94,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-06-18T00:00:00",
    "currency": "USD",
    "from_country": "RU",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "AR",
    "total_costs": 797,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-06-18T00:00:00",
    "currency": "USD",
    "from_country": "SC",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "AG",
    "total_costs": 686,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-06-18T00:00:00",
    "currency": "USD",
    "from_country": "JP",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "GP",
    "total_costs": 862,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-06-18T00:00:00",
    "currency": "USD",
    "from_country": "UM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "BA",
    "total_costs": 194,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "GD",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "ES",
    "total_costs": 877,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "HK",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "BO",
    "total_costs": 592,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "VE",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "GA",
    "total_costs": 694,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-06-19T00:00:00",
    "currency": "CAD",
    "from_country": "BH",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "HU",
    "total_costs": 137,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "LK",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CC",
    "total_costs": 58,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "EE",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "KI",
    "total_costs": 208,
    "tracking_number": ""
  },
  {
    "carrier": "gls_denmark",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "GL",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "TV",
    "total_costs": 333,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-06-19T00:00:00",
    "currency": "USD",
    "from_country": "SK",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "NU",
    "total_costs": 160,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-06-20T00:00:00",
    "currency": "USD",
    "from_country": "VC",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "KM",
    "total_costs": 608,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-06-20T00:00:00",
    "currency": "USD",
    "from_country": "CF",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "MP",
    "total_costs": 581,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-06-20T00:00:00",
    "currency": "AUD",
    "from_country": "MH",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "IE",
    "total_costs": 457,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-06-20T00:00:00",
    "currency": "USD",
    "from_country": "UG",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "AT",
    "total_costs": 564,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-06-20T00:00:00",
    "currency": "USD",
    "from_country": "BY",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "RE",
    "total_costs": 137,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-06-21T00:00:00",
    "currency": "USD",
    "from_country": "NE",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "MD",
    "total_costs": 204,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-06-21T00:00:00",
    "currency": "USD",
    "from_country": "NA",
    "is_outbound": false,
    "service": "air",
    "to_country": "PL",
    "total_costs": 684,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-06-21T00:00:00",
    "currency": "USD",
    "from_country": "GA",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "MR",
    "total_costs": 102,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-06-21T00:00:00",
    "currency": "USD",
    "from_country": "MP",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "NC",
    "total_costs": 130,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-06-21T00:00:00",
    "currency": "USD",
    "from_country": "LV",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "FM",
    "total_costs": 679,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-06-21T00:00:00",
    "currency": "USD",
    "from_country": "FI",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TC",
    "total_costs": 150,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-06-22T00:00:00",
    "currency": "USD",
    "from_country": "CK",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "AL",
    "total_costs": 801,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-06-22T00:00:00",
    "currency": "USD",
    "from_country": "FM",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "GU",
    "total_costs": 928,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-06-22T00:00:00",
    "currency": "USD",
    "from_country": "GN",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "RE",
    "total_costs": 472,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-06-22T00:00:00",
    "currency": "USD",
    "from_country": "RO",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "BE",
    "total_costs": 493,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-06-22T00:00:00",
    "currency": "USD",
    "from_country": "MV",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "HT",
    "total_costs": 369,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-06-23T00:00:00",
    "currency": "USD",
    "from_country": "CO",
    "is_outbound": true,
    "service": "air",
    "to_country": "PR",
    "total_costs": 198,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-06-23T00:00:00",
    "currency": "NOK",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "ME",
    "total_costs": 520,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-06-23T00:00:00",
    "currency": "USD",
    "from_country": "KW",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "MF",
    "total_costs": 389,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-06-23T00:00:00",
    "currency": "USD",
    "from_country": "UG",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "BH",
    "total_costs": 933,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-06-23T00:00:00",
    "currency": "EUR",
    "from_country": "UY",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "DJ",
    "total_costs": 374,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-06-24T00:00:00",
    "currency": "USD",
    "from_country": "BS",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "VA",
    "total_costs": 439,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-06-24T00:00:00",
    "currency": "USD",
    "from_country": "PE",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "MP",
    "total_costs": 751,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-06-24T00:00:00",
    "currency": "USD",
    "from_country": "MD",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "US",
    "total_costs": 531,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-06-24T00:00:00",
    "currency": "GBP",
    "from_country": "HN",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "HR",
    "total_costs": 768,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-06-24T00:00:00",
    "currency": "USD",
    "from_country": "PG",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "AN",
    "total_costs": 795,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-06-25T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "TD",
    "total_costs": 115,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-06-25T00:00:00",
    "currency": "RMB",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "BR",
    "total_costs": 38,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-06-25T00:00:00",
    "currency": "USD",
    "from_country": "BJ",
    "is_outbound": true,
    "service": "air",
    "to_country": "CD",
    "total_costs": 730,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-06-25T00:00:00",
    "currency": "EUR",
    "from_country": "MN",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "AC",
    "total_costs": 477,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-06-25T00:00:00",
    "currency": "USD",
    "from_country": "SJ",
    "is_outbound": true,
    "service": "air",
    "to_country": "AG",
    "total_costs": 595,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-06-26T00:00:00",
    "currency": "USD",
    "from_country": "IC",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "ZA",
    "total_costs": 92,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-06-26T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "EH",
    "total_costs": 48,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-06-26T00:00:00",
    "currency": "USD",
    "from_country": "AN",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "SZ",
    "total_costs": 463,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-06-27T00:00:00",
    "currency": "EUR",
    "from_country": "XZ",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "AC",
    "total_costs": 103,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-06-27T00:00:00",
    "currency": "USD",
    "from_country": "NO",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "HN",
    "total_costs": 287,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-06-27T00:00:00",
    "currency": "USD",
    "from_country": "LY",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "HU",
    "total_costs": 662,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-06-27T00:00:00",
    "currency": "USD",
    "from_country": "IM",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "BA",
    "total_costs": 688,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-06-27T00:00:00",
    "currency": "USD",
    "from_country": "DO",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "SZ",
    "total_costs": 480,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-06-28T00:00:00",
    "currency": "USD",
    "from_country": "SO",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "TM",
    "total_costs": 356,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-06-28T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "HT",
    "total_costs": 553,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-06-28T00:00:00",
    "currency": "USD",
    "from_country": "GS",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "FK",
    "total_costs": 873,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-06-29T00:00:00",
    "currency": "NZD",
    "from_country": "UZ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "IR",
    "total_costs": 761,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-06-29T00:00:00",
    "currency": "MOP",
    "from_country": "BM",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "CI",
    "total_costs": 503,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-06-29T00:00:00",
    "currency": "USD",
    "from_country": "AC",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "SE",
    "total_costs": 650,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-06-29T00:00:00",
    "currency": "SEK",
    "from_country": "GQ",
    "is_outbound": true,
    "service": "air",
    "to_country": "KR",
    "total_costs": 609,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-06-29T00:00:00",
    "currency": "USD",
    "from_country": "AM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "WS",
    "total_costs": 598,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-06-29T00:00:00",
    "currency": "SEK",
    "from_country": "RS",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "IM",
    "total_costs": 406,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-06-30T00:00:00",
    "currency": "USD",
    "from_country": "NL",
    "is_outbound": false,
    "service": "air",
    "to_country": "HK",
    "total_costs": 704,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-06-30T00:00:00",
    "currency": "USD",
    "from_country": "TK",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "TW",
    "total_costs": 8,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-06-30T00:00:00",
    "currency": "USD",
    "from_country": "BB",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "HM",
    "total_costs": 967,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-06-30T00:00:00",
    "currency": "USD",
    "from_country": "CH",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "DZ",
    "total_costs": 375,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-06-30T00:00:00",
    "currency": "USD",
    "from_country": "PH",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "SE",
    "total_costs": 644,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-06-30T00:00:00",
    "currency": "ARS",
    "from_country": "CI",
    "is_outbound": true,
    "service": "air",
    "to_country": "KZ",
    "total_costs": 184,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-07-01T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "GI",
    "total_costs": 863,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-07-01T00:00:00",
    "currency": "USD",
    "from_country": "CA",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "AD",
    "total_costs": 776,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-07-01T00:00:00",
    "currency": "USD",
    "from_country": "MN",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "CO",
    "total_costs": 585,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-07-02T00:00:00",
    "currency": "CRC",
    "from_country": "JO",
    "is_outbound": false,
    "service": "air",
    "to_country": "YT",
    "total_costs": 101,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-07-02T00:00:00",
    "currency": "USD",
    "from_country": "JO",
    "is_outbound": true,
    "service": "air",
    "to_country": "YE",
    "total_costs": 991,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-07-02T00:00:00",
    "currency": "USD",
    "from_country": "DJ",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "CI",
    "total_costs": 37,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-07-03T00:00:00",
    "currency": "EUR",
    "from_country": "OM",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "DK",
    "total_costs": 257,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-07-03T00:00:00",
    "currency": "CLF",
    "from_country": "MZ",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "MX",
    "total_costs": 397,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-07-03T00:00:00",
    "currency": "USD",
    "from_country": "SM",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "TV",
    "total_costs": 387,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-07-03T00:00:00",
    "currency": "USD",
    "from_country": "TH",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "MW",
    "total_costs": 14,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-07-03T00:00:00",
    "currency": "USD",
    "from_country": "BL",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "NR",
    "total_costs": 914,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-07-03T00:00:00",
    "currency": "USD",
    "from_country": "GS",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "EE",
    "total_costs": 836,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-07-03T00:00:00",
    "currency": "SGD",
    "from_country": "TW",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "HT",
    "total_costs": 499,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "TN",
    "total_costs": 27,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BO",
    "total_costs": 34,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "HT",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "JO",
    "total_costs": 298,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "US",
    "is_outbound": false,
    "service": "air",
    "to_country": "AE",
    "total_costs": 741,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "BM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "GL",
    "total_costs": 58,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-07-04T00:00:00",
    "currency": "EUR",
    "from_country": "MH",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "AC",
    "total_costs": 989,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "KG",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "JO",
    "total_costs": 225,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-07-04T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GR",
    "total_costs": 610,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-07-05T00:00:00",
    "currency": "USD",
    "from_country": "SA",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "MG",
    "total_costs": 41,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-07-05T00:00:00",
    "currency": "USD",
    "from_country": "IL",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "AR",
    "total_costs": 524,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-07-05T00:00:00",
    "currency": "USD",
    "from_country": "ET",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "ZA",
    "total_costs": 645,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-07-06T00:00:00",
    "currency": "USD",
    "from_country": "GE",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "XZ",
    "total_costs": 168,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-07-06T00:00:00",
    "currency": "CZK",
    "from_country": "IL",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AX",
    "total_costs": 567,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-07-06T00:00:00",
    "currency": "USD",
    "from_country": "FI",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CF",
    "total_costs": 267,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-07-06T00:00:00",
    "currency": "USD",
    "from_country": "KE",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "KG",
    "total_costs": 279,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-07-06T00:00:00",
    "currency": "KRW",
    "from_country": "RW",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "CU",
    "total_costs": 518,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-07-06T00:00:00",
    "currency": "USD",
    "from_country": "SO",
    "is_outbound": false,
    "service": "air",
    "to_country": "GW",
    "total_costs": 876,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-07-06T00:00:00",
    "currency": "USD",
    "from_country": "FK",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "VN",
    "total_costs": 404,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "OM",
    "is_outbound": true,
    "service": "air",
    "to_country": "AI",
    "total_costs": 111,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "GQ",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "NI",
    "total_costs": 564,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "GE",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "NA",
    "total_costs": 878,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-07-07T00:00:00",
    "currency": "RMB",
    "from_country": "BH",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "MZ",
    "total_costs": 244,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "KZ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "BS",
    "total_costs": 68,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "AM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "MK",
    "total_costs": 744,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "MP",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "NA",
    "total_costs": 798,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "AC",
    "total_costs": 217,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "SD",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "PA",
    "total_costs": 616,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-07-07T00:00:00",
    "currency": "USD",
    "from_country": "MU",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "AX",
    "total_costs": 429,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-07-08T00:00:00",
    "currency": "USD",
    "from_country": "FO",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "ML",
    "total_costs": 830,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-07-08T00:00:00",
    "currency": "USD",
    "from_country": "IC",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "UA",
    "total_costs": 516,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-07-09T00:00:00",
    "currency": "USD",
    "from_country": "VI",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "UZ",
    "total_costs": 51,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-07-09T00:00:00",
    "currency": "USD",
    "from_country": "DZ",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BO",
    "total_costs": 692,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-07-09T00:00:00",
    "currency": "USD",
    "from_country": "AI",
    "is_outbound": false,
    "service": "air",
    "to_country": "CI",
    "total_costs": 562,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-07-09T00:00:00",
    "currency": "EUR",
    "from_country": "BL",
    "is_outbound": false,
    "service": "air",
    "to_country": "BO",
    "total_costs": 945,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-07-09T00:00:00",
    "currency": "USD",
    "from_country": "GF",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "ZW",
    "total_costs": 114,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-07-09T00:00:00",
    "currency": "VND",
    "from_country": "TO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "KR",
    "total_costs": 199,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-07-09T00:00:00",
    "currency": "USD",
    "from_country": "ML",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "UA",
    "total_costs": 951,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-07-09T00:00:00",
    "currency": "DKK",
    "from_country": "ER",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "PG",
    "total_costs": 288,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "PW",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "DM",
    "total_costs": 972,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "TZ",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "BL",
    "total_costs": 186,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "KZ",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "CZ",
    "total_costs": 336,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "TR",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "ZA",
    "total_costs": 167,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "DZ",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "CZ",
    "total_costs": 926,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "GY",
    "is_outbound": false,
    "service": "air",
    "to_country": "TJ",
    "total_costs": 469,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "AE",
    "is_outbound": true,
    "service": "air",
    "to_country": "LY",
    "total_costs": 237,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-07-10T00:00:00",
    "currency": "USD",
    "from_country": "UA",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "FI",
    "total_costs": 52,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-07-11T00:00:00",
    "currency": "USD",
    "from_country": "YT",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "KE",
    "total_costs": 410,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-07-11T00:00:00",
    "currency": "USD",
    "from_country": "VC",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "JE",
    "total_costs": 736,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-07-11T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "GP",
    "total_costs": 126,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-07-11T00:00:00",
    "currency": "USD",
    "from_country": "GB",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BY",
    "total_costs": 256,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-07-11T00:00:00",
    "currency": "USD",
    "from_country": "MU",
    "is_outbound": true,
    "service": "air",
    "to_country": "CZ",
    "total_costs": 91,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-07-12T00:00:00",
    "currency": "MYR",
    "from_country": "ID",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "ML",
    "total_costs": 870,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-07-12T00:00:00",
    "currency": "ARS",
    "from_country": "JE",
    "is_outbound": false,
    "service": "air",
    "to_country": "CX",
    "total_costs": 241,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-07-13T00:00:00",
    "currency": "USD",
    "from_country": "RE",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "IE",
    "total_costs": 182,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-07-13T00:00:00",
    "currency": "USD",
    "from_country": "CY",
    "is_outbound": false,
    "service": "air",
    "to_country": "PF",
    "total_costs": 99,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-07-13T00:00:00",
    "currency": "USD",
    "from_country": "MZ",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "MU",
    "total_costs": 780,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-07-13T00:00:00",
    "currency": "USD",
    "from_country": "LU",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "SJ",
    "total_costs": 374,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-07-14T00:00:00",
    "currency": "TRY",
    "from_country": "CC",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BT",
    "total_costs": 453,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-07-14T00:00:00",
    "currency": "MOP",
    "from_country": "KR",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BY",
    "total_costs": 627,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-07-14T00:00:00",
    "currency": "USD",
    "from_country": "ML",
    "is_outbound": false,
    "service": "air",
    "to_country": "LU",
    "total_costs": 839,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-07-14T00:00:00",
    "currency": "USD",
    "from_country": "GI",
    "is_outbound": false,
    "service": "air",
    "to_country": "IN",
    "total_costs": 518,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-07-14T00:00:00",
    "currency": "USD",
    "from_country": "SE",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "NR",
    "total_costs": 495,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-07-14T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "LY",
    "total_costs": 176,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-07-14T00:00:00",
    "currency": "USD",
    "from_country": "LI",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "PW",
    "total_costs": 272,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-07-15T00:00:00",
    "currency": "USD",
    "from_country": "SC",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "LV",
    "total_costs": 802,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-07-15T00:00:00",
    "currency": "USD",
    "from_country": "AL",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "BO",
    "total_costs": 727,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-07-15T00:00:00",
    "currency": "USD",
    "from_country": "ET",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "NI",
    "total_costs": 216,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-07-15T00:00:00",
    "currency": "EUR",
    "from_country": "ST",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "EC",
    "total_costs": 353,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-07-15T00:00:00",
    "currency": "USD",
    "from_country": "MF",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "IN",
    "total_costs": 198,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-07-15T00:00:00",
    "currency": "USD",
    "from_country": "AO",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "GE",
    "total_costs": 109,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-07-15T00:00:00",
    "currency": "EUR",
    "from_country": "GG",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "ZA",
    "total_costs": 182,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-07-16T00:00:00",
    "currency": "USD",
    "from_country": "MN",
    "is_outbound": false,
    "service": "air",
    "to_country": "FO",
    "total_costs": 994,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-07-16T00:00:00",
    "currency": "USD",
    "from_country": "ZW",
    "is_outbound": false,
    "service": "air",
    "to_country": "BB",
    "total_costs": 254,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-07-17T00:00:00",
    "currency": "USD",
    "from_country": "SN",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "MF",
    "total_costs": 166,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-07-17T00:00:00",
    "currency": "USD",
    "from_country": "IE",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "CK",
    "total_costs": 503,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-07-18T00:00:00",
    "currency": "USD",
    "from_country": "MR",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "KI",
    "total_costs": 693,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-07-18T00:00:00",
    "currency": "ILS",
    "from_country": "DO",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "TT",
    "total_costs": 543,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-07-18T00:00:00",
    "currency": "USD",
    "from_country": "BV",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "MR",
    "total_costs": 677,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-07-18T00:00:00",
    "currency": "USD",
    "from_country": "CY",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "CD",
    "total_costs": 615,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-07-18T00:00:00",
    "currency": "KRW",
    "from_country": "KP",
    "is_outbound": false,
    "service": "air",
    "to_country": "AQ",
    "total_costs": 796,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-07-18T00:00:00",
    "currency": "USD",
    "from_country": "IS",
    "is_outbound": true,
    "service": "air",
    "to_country": "FR",
    "total_costs": 2,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-07-18T00:00:00",
    "currency": "USD",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "ET",
    "total_costs": 288,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-07-19T00:00:00",
    "currency": "USD",
    "from_country": "FJ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MT",
    "total_costs": 791,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-07-19T00:00:00",
    "currency": "CLF",
    "from_country": "VE",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "IE",
    "total_costs": 182,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-07-19T00:00:00",
    "currency": "NOK",
    "from_country": "IQ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MH",
    "total_costs": 495,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-07-19T00:00:00",
    "currency": "USD",
    "from_country": "EC",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "LB",
    "total_costs": 386,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-07-20T00:00:00",
    "currency": "HUF",
    "from_country": "WF",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "TA",
    "total_costs": 607,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-07-20T00:00:00",
    "currency": "USD",
    "from_country": "DO",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "VG",
    "total_costs": 374,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-07-20T00:00:00",
    "currency": "USD",
    "from_country": "GD",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "VE",
    "total_costs": 33,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-07-20T00:00:00",
    "currency": "BRL",
    "from_country": "LB",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "AC",
    "total_costs": 8,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-07-20T00:00:00",
    "currency": "BRL",
    "from_country": "TC",
    "is_outbound": true,
    "service": "air",
    "to_country": "EH",
    "total_costs": 307,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-07-20T00:00:00",
    "currency": "USD",
    "from_country": "MR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "PF",
    "total_costs": 729,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-07-21T00:00:00",
    "currency": "MXN",
    "from_country": "NR",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "HM",
    "total_costs": 122,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-07-21T00:00:00",
    "currency": "USD",
    "from_country": "WF",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "MW",
    "total_costs": 628,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-07-21T00:00:00",
    "currency": "USD",
    "from_country": "MQ",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "AW",
    "total_costs": 491,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-07-21T00:00:00",
    "currency": "USD",
    "from_country": "HN",
    "is_outbound": true,
    "service": "air",
    "to_country": "MU",
    "total_costs": 828,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-07-21T00:00:00",
    "currency": "RUB",
    "from_country": "PG",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "MF",
    "total_costs": 314,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-07-21T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "GF",
    "total_costs": 967,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-07-21T00:00:00",
    "currency": "USD",
    "from_country": "YT",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "RS",
    "total_costs": 16,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-07-22T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AN",
    "total_costs": 291,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-07-22T00:00:00",
    "currency": "CHF",
    "from_country": "VE",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "SJ",
    "total_costs": 34,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-07-22T00:00:00",
    "currency": "USD",
    "from_country": "JO",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "SJ",
    "total_costs": 107,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-07-22T00:00:00",
    "currency": "USD",
    "from_country": "SE",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "BI",
    "total_costs": 398,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-07-22T00:00:00",
    "currency": "SEK",
    "from_country": "KP",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "NI",
    "total_costs": 569,
    "tracking_number": ""
  },
  {
    "carrier": "gls_denmark",
    "created": "2021-07-22T00:00:00",
    "currency": "MYR",
    "from_country": "LI",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "GL",
    "total_costs": 934,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-07-22T00:00:00",
    "currency": "USD",
    "from_country": "IO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MK",
    "total_costs": 360,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-07-22T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "LU",
    "total_costs": 712,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-07-23T00:00:00",
    "currency": "USD",
    "from_country": "CA",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "UZ",
    "total_costs": 809,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-07-23T00:00:00",
    "currency": "USD",
    "from_country": "DE",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "PY",
    "total_costs": 67,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-07-24T00:00:00",
    "currency": "CRC",
    "from_country": "EC",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "VA",
    "total_costs": 45,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-07-24T00:00:00",
    "currency": "USD",
    "from_country": "TL",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "JE",
    "total_costs": 41,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-07-24T00:00:00",
    "currency": "USD",
    "from_country": "UZ",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "GM",
    "total_costs": 749,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-07-24T00:00:00",
    "currency": "USD",
    "from_country": "CF",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "PS",
    "total_costs": 661,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-07-24T00:00:00",
    "currency": "USD",
    "from_country": "PY",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "SM",
    "total_costs": 188,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-07-24T00:00:00",
    "currency": "USD",
    "from_country": "DM",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "SJ",
    "total_costs": 751,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-07-24T00:00:00",
    "currency": "USD",
    "from_country": "GM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "GS",
    "total_costs": 847,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-07-25T00:00:00",
    "currency": "RUB",
    "from_country": "PK",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "GL",
    "total_costs": 400,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-07-25T00:00:00",
    "currency": "USD",
    "from_country": "KH",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "UG",
    "total_costs": 52,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-07-25T00:00:00",
    "currency": "SGD",
    "from_country": "PN",
    "is_outbound": true,
    "service": "air",
    "to_country": "AW",
    "total_costs": 26,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-07-25T00:00:00",
    "currency": "USD",
    "from_country": "KG",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "PK",
    "total_costs": 717,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-07-25T00:00:00",
    "currency": "USD",
    "from_country": "FK",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "BZ",
    "total_costs": 284,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-07-25T00:00:00",
    "currency": "USD",
    "from_country": "XZ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "EC",
    "total_costs": 477,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-07-25T00:00:00",
    "currency": "USD",
    "from_country": "BH",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TD",
    "total_costs": 996,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-07-25T00:00:00",
    "currency": "EUR",
    "from_country": "CG",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "LV",
    "total_costs": 547,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GW",
    "total_costs": 847,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-07-26T00:00:00",
    "currency": "AUD",
    "from_country": "CV",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "BD",
    "total_costs": 551,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "EG",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "GH",
    "total_costs": 528,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "IO",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "PY",
    "total_costs": 695,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "DJ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AE",
    "total_costs": 668,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-07-26T00:00:00",
    "currency": "NZD",
    "from_country": "TT",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "TG",
    "total_costs": 674,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "NF",
    "is_outbound": false,
    "service": "air",
    "to_country": "BN",
    "total_costs": 376,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "ET",
    "total_costs": 343,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "MZ",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "IC",
    "total_costs": 40,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-07-26T00:00:00",
    "currency": "USD",
    "from_country": "VI",
    "is_outbound": false,
    "service": "air",
    "to_country": "NP",
    "total_costs": 52,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-07-27T00:00:00",
    "currency": "USD",
    "from_country": "ML",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "JE",
    "total_costs": 528,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-07-27T00:00:00",
    "currency": "USD",
    "from_country": "BV",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "EH",
    "total_costs": 540,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-07-27T00:00:00",
    "currency": "USD",
    "from_country": "GI",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "TV",
    "total_costs": 603,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-07-28T00:00:00",
    "currency": "USD",
    "from_country": "AN",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "PH",
    "total_costs": 841,
    "tracking_number": ""
  },
  {
    "carrier": "gls_denmark",
    "created": "2021-07-28T00:00:00",
    "currency": "USD",
    "from_country": "BY",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "FR",
    "total_costs": 783,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-07-28T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "PM",
    "total_costs": 307,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-07-28T00:00:00",
    "currency": "USD",
    "from_country": "NL",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "SR",
    "total_costs": 256,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-07-29T00:00:00",
    "currency": "EUR",
    "from_country": "LY",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "EG",
    "total_costs": 834,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-07-29T00:00:00",
    "currency": "USD",
    "from_country": "TM",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "IM",
    "total_costs": 61,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-07-29T00:00:00",
    "currency": "MXN",
    "from_country": "GI",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "TD",
    "total_costs": 759,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "BT",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BA",
    "total_costs": 189,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "LU",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "GP",
    "total_costs": 477,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "IQ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MT",
    "total_costs": 869,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-07-30T00:00:00",
    "currency": "RUB",
    "from_country": "TW",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "SN",
    "total_costs": 771,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "GA",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "AU",
    "total_costs": 984,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "MH",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "LK",
    "total_costs": 313,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "XZ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TV",
    "total_costs": 749,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-07-30T00:00:00",
    "currency": "USD",
    "from_country": "PE",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "FI",
    "total_costs": 647,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-07-31T00:00:00",
    "currency": "USD",
    "from_country": "EG",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "NL",
    "total_costs": 111,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-07-31T00:00:00",
    "currency": "USD",
    "from_country": "VU",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "CX",
    "total_costs": 214,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-07-31T00:00:00",
    "currency": "MOP",
    "from_country": "GM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "GD",
    "total_costs": 285,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-07-31T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "BJ",
    "total_costs": 471,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-07-31T00:00:00",
    "currency": "USD",
    "from_country": "LS",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "GG",
    "total_costs": 624,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-07-31T00:00:00",
    "currency": "EUR",
    "from_country": "BB",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "BD",
    "total_costs": 320,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-07-31T00:00:00",
    "currency": "RMB",
    "from_country": "UY",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "AU",
    "total_costs": 812,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-07-31T00:00:00",
    "currency": "HKD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CF",
    "total_costs": 222,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "GS",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "SI",
    "total_costs": 920,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "CC",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "HR",
    "total_costs": 297,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-01T00:00:00",
    "currency": "TWD",
    "from_country": "DK",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "AE",
    "total_costs": 774,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "MU",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "KG",
    "total_costs": 584,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "RO",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "PY",
    "total_costs": 292,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "BW",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "PS",
    "total_costs": 757,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "AL",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "GI",
    "total_costs": 938,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "YT",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "GI",
    "total_costs": 649,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "AZ",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "ST",
    "total_costs": 50,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "LA",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "BG",
    "total_costs": 652,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-08-01T00:00:00",
    "currency": "CRC",
    "from_country": "ES",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BJ",
    "total_costs": 72,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-01T00:00:00",
    "currency": "USD",
    "from_country": "TK",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "IC",
    "total_costs": 631,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-08-02T00:00:00",
    "currency": "USD",
    "from_country": "GD",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "TK",
    "total_costs": 226,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-02T00:00:00",
    "currency": "USD",
    "from_country": "NP",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "CI",
    "total_costs": 253,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-08-02T00:00:00",
    "currency": "EUR",
    "from_country": "BG",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "KG",
    "total_costs": 912,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-08-02T00:00:00",
    "currency": "USD",
    "from_country": "MT",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "CD",
    "total_costs": 349,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-08-02T00:00:00",
    "currency": "USD",
    "from_country": "MV",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GF",
    "total_costs": 381,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-08-02T00:00:00",
    "currency": "USD",
    "from_country": "FI",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "KH",
    "total_costs": 958,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-08-02T00:00:00",
    "currency": "TRY",
    "from_country": "ST",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "XZ",
    "total_costs": 222,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-08-02T00:00:00",
    "currency": "USD",
    "from_country": "IT",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "YE",
    "total_costs": 239,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-08-03T00:00:00",
    "currency": "DOP",
    "from_country": "WS",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "TH",
    "total_costs": 54,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "FI",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "SH",
    "total_costs": 950,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-03T00:00:00",
    "currency": "EUR",
    "from_country": "JP",
    "is_outbound": true,
    "service": "air",
    "to_country": "MC",
    "total_costs": 91,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "TJ",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "TW",
    "total_costs": 462,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-08-03T00:00:00",
    "currency": "EUR",
    "from_country": "NG",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "ET",
    "total_costs": 259,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "SZ",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "LU",
    "total_costs": 468,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "LB",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "AM",
    "total_costs": 909,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "PH",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "ET",
    "total_costs": 429,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "PY",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "HN",
    "total_costs": 333,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "AZ",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "RU",
    "total_costs": 246,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-08-03T00:00:00",
    "currency": "USD",
    "from_country": "PW",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "SB",
    "total_costs": 912,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "CK",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CR",
    "total_costs": 8,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "NL",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "DK",
    "total_costs": 928,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "PE",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "GE",
    "total_costs": 225,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "WF",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AR",
    "total_costs": 381,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "IM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "ZM",
    "total_costs": 443,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": false,
    "service": "air",
    "to_country": "CM",
    "total_costs": 29,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "TD",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "SJ",
    "total_costs": 607,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-08-04T00:00:00",
    "currency": "USD",
    "from_country": "LS",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "UY",
    "total_costs": 803,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-08-05T00:00:00",
    "currency": "USD",
    "from_country": "HR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "ZA",
    "total_costs": 502,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-08-05T00:00:00",
    "currency": "USD",
    "from_country": "PN",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "KW",
    "total_costs": 165,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-08-05T00:00:00",
    "currency": "CRC",
    "from_country": "TM",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "BI",
    "total_costs": 297,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-08-05T00:00:00",
    "currency": "USD",
    "from_country": "SI",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BG",
    "total_costs": 597,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "AS",
    "total_costs": 766,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "TT",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "IT",
    "total_costs": 174,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "AC",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MH",
    "total_costs": 444,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "AD",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "DE",
    "total_costs": 464,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AE",
    "total_costs": 826,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "MG",
    "total_costs": 403,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-08-06T00:00:00",
    "currency": "USD",
    "from_country": "VU",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "AU",
    "total_costs": 212,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "GI",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "TG",
    "total_costs": 982,
    "tracking_number": ""
  },
  {
    "carrier": "odfl",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "YT",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "AS",
    "total_costs": 463,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "TZ",
    "total_costs": 523,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "DJ",
    "is_outbound": false,
    "service": "air",
    "to_country": "FM",
    "total_costs": 79,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "ZM",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "NF",
    "total_costs": 984,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "AW",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "KG",
    "total_costs": 876,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "PT",
    "is_outbound": false,
    "service": "air",
    "to_country": "ST",
    "total_costs": 360,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-08-07T00:00:00",
    "currency": "USD",
    "from_country": "GP",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "CL",
    "total_costs": 800,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-08-08T00:00:00",
    "currency": "USD",
    "from_country": "BO",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "GE",
    "total_costs": 510,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-08-08T00:00:00",
    "currency": "SGD",
    "from_country": "OM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TW",
    "total_costs": 836,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-08-08T00:00:00",
    "currency": "MXN",
    "from_country": "US",
    "is_outbound": true,
    "service": "air",
    "to_country": "GP",
    "total_costs": 638,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-08-08T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BD",
    "total_costs": 884,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-08-09T00:00:00",
    "currency": "USD",
    "from_country": "TN",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "CV",
    "total_costs": 82,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-08-09T00:00:00",
    "currency": "USD",
    "from_country": "NC",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "PE",
    "total_costs": 541,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-08-09T00:00:00",
    "currency": "USD",
    "from_country": "EG",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "PA",
    "total_costs": 529,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-08-09T00:00:00",
    "currency": "BRL",
    "from_country": "TJ",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "PL",
    "total_costs": 861,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-08-09T00:00:00",
    "currency": "USD",
    "from_country": "AG",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "AL",
    "total_costs": 10,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-08-09T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "CV",
    "total_costs": 553,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-08-09T00:00:00",
    "currency": "USD",
    "from_country": "BV",
    "is_outbound": true,
    "service": "air",
    "to_country": "YE",
    "total_costs": 804,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-08-10T00:00:00",
    "currency": "USD",
    "from_country": "SE",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "SD",
    "total_costs": 674,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-08-10T00:00:00",
    "currency": "USD",
    "from_country": "IE",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "PG",
    "total_costs": 526,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-08-10T00:00:00",
    "currency": "USD",
    "from_country": "BR",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "HK",
    "total_costs": 154,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-08-10T00:00:00",
    "currency": "CHF",
    "from_country": "CM",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "LS",
    "total_costs": 946,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-08-10T00:00:00",
    "currency": "USD",
    "from_country": "UY",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "IC",
    "total_costs": 763,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-08-10T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "AD",
    "total_costs": 481,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-10T00:00:00",
    "currency": "USD",
    "from_country": "EE",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "VN",
    "total_costs": 619,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-08-10T00:00:00",
    "currency": "EUR",
    "from_country": "RS",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "CF",
    "total_costs": 517,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-08-11T00:00:00",
    "currency": "GBP",
    "from_country": "SB",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "AE",
    "total_costs": 856,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-08-11T00:00:00",
    "currency": "USD",
    "from_country": "SK",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "GY",
    "total_costs": 863,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-11T00:00:00",
    "currency": "USD",
    "from_country": "BI",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "MW",
    "total_costs": 488,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-08-11T00:00:00",
    "currency": "USD",
    "from_country": "PS",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "KW",
    "total_costs": 30,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-08-11T00:00:00",
    "currency": "USD",
    "from_country": "HU",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "TR",
    "total_costs": 20,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-08-11T00:00:00",
    "currency": "USD",
    "from_country": "ZA",
    "is_outbound": true,
    "service": "air",
    "to_country": "AO",
    "total_costs": 19,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-08-11T00:00:00",
    "currency": "USD",
    "from_country": "BS",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "RO",
    "total_costs": 641,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-08-11T00:00:00",
    "currency": "MOP",
    "from_country": "CX",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "CG",
    "total_costs": 455,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-08-12T00:00:00",
    "currency": "USD",
    "from_country": "KW",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "KH",
    "total_costs": 662,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-08-12T00:00:00",
    "currency": "USD",
    "from_country": "BJ",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "GH",
    "total_costs": 752,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-08-12T00:00:00",
    "currency": "USD",
    "from_country": "CU",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "SK",
    "total_costs": 61,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-08-13T00:00:00",
    "currency": "USD",
    "from_country": "IM",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "XZ",
    "total_costs": 47,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-08-13T00:00:00",
    "currency": "EUR",
    "from_country": "CY",
    "is_outbound": false,
    "service": "air",
    "to_country": "CD",
    "total_costs": 956,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-08-13T00:00:00",
    "currency": "USD",
    "from_country": "VE",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "TG",
    "total_costs": 222,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-08-13T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "IE",
    "total_costs": 379,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-08-13T00:00:00",
    "currency": "USD",
    "from_country": "LK",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "CC",
    "total_costs": 237,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-08-13T00:00:00",
    "currency": "USD",
    "from_country": "IR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "AE",
    "total_costs": 378,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-08-14T00:00:00",
    "currency": "EUR",
    "from_country": "IS",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "BF",
    "total_costs": 320,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-14T00:00:00",
    "currency": "USD",
    "from_country": "DM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "KW",
    "total_costs": 815,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-08-14T00:00:00",
    "currency": "NZD",
    "from_country": "TC",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "ML",
    "total_costs": 330,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-08-14T00:00:00",
    "currency": "KRW",
    "from_country": "MK",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "HR",
    "total_costs": 39,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-08-14T00:00:00",
    "currency": "USD",
    "from_country": "FI",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "CM",
    "total_costs": 99,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-08-14T00:00:00",
    "currency": "USD",
    "from_country": "GW",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "TM",
    "total_costs": 652,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-08-14T00:00:00",
    "currency": "USD",
    "from_country": "GB",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "DK",
    "total_costs": 925,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-08-15T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "JP",
    "total_costs": 996,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-08-15T00:00:00",
    "currency": "USD",
    "from_country": "ES",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "BL",
    "total_costs": 237,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-08-15T00:00:00",
    "currency": "USD",
    "from_country": "BN",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "DO",
    "total_costs": 394,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-08-15T00:00:00",
    "currency": "EUR",
    "from_country": "VC",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "MP",
    "total_costs": 440,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-08-15T00:00:00",
    "currency": "MOP",
    "from_country": "CK",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "AF",
    "total_costs": 125,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-08-15T00:00:00",
    "currency": "USD",
    "from_country": "FJ",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "BO",
    "total_costs": 5,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-08-15T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "KY",
    "total_costs": 342,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-08-15T00:00:00",
    "currency": "USD",
    "from_country": "SE",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "FK",
    "total_costs": 464,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-08-16T00:00:00",
    "currency": "USD",
    "from_country": "BL",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "CO",
    "total_costs": 870,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-08-16T00:00:00",
    "currency": "EUR",
    "from_country": "CF",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "EC",
    "total_costs": 689,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-08-16T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "NG",
    "total_costs": 925,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-08-16T00:00:00",
    "currency": "USD",
    "from_country": "RE",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "BB",
    "total_costs": 438,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-08-17T00:00:00",
    "currency": "USD",
    "from_country": "PS",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "HU",
    "total_costs": 782,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-08-18T00:00:00",
    "currency": "TRY",
    "from_country": "GG",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "EH",
    "total_costs": 384,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-08-18T00:00:00",
    "currency": "USD",
    "from_country": "CR",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "IT",
    "total_costs": 668,
    "tracking_number": ""
  },
  {
    "carrier": "odfl",
    "created": "2021-08-18T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "CO",
    "total_costs": 479,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-08-18T00:00:00",
    "currency": "MYR",
    "from_country": "AL",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "CC",
    "total_costs": 869,
    "tracking_number": ""
  },
  {
    "carrier": "gls_denmark",
    "created": "2021-08-19T00:00:00",
    "currency": "USD",
    "from_country": "GF",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "MY",
    "total_costs": 64,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-08-19T00:00:00",
    "currency": "USD",
    "from_country": "NR",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "WF",
    "total_costs": 437,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-08-19T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "SL",
    "total_costs": 51,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-08-19T00:00:00",
    "currency": "USD",
    "from_country": "BV",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "PK",
    "total_costs": 51,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-08-20T00:00:00",
    "currency": "USD",
    "from_country": "ST",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "LI",
    "total_costs": 935,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-08-20T00:00:00",
    "currency": "USD",
    "from_country": "GE",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "MT",
    "total_costs": 825,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-20T00:00:00",
    "currency": "USD",
    "from_country": "DO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "IC",
    "total_costs": 404,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-20T00:00:00",
    "currency": "USD",
    "from_country": "HK",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CD",
    "total_costs": 264,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-08-20T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "KI",
    "total_costs": 546,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-08-20T00:00:00",
    "currency": "USD",
    "from_country": "AG",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "FI",
    "total_costs": 823,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-08-21T00:00:00",
    "currency": "USD",
    "from_country": "DE",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "MH",
    "total_costs": 627,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-08-21T00:00:00",
    "currency": "USD",
    "from_country": "BH",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "DJ",
    "total_costs": 665,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-08-21T00:00:00",
    "currency": "USD",
    "from_country": "HK",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "KI",
    "total_costs": 853,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-08-21T00:00:00",
    "currency": "USD",
    "from_country": "MR",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "ST",
    "total_costs": 213,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-08-21T00:00:00",
    "currency": "USD",
    "from_country": "SI",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "NL",
    "total_costs": 643,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-08-21T00:00:00",
    "currency": "USD",
    "from_country": "UG",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "TT",
    "total_costs": 141,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-08-22T00:00:00",
    "currency": "USD",
    "from_country": "JE",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "VN",
    "total_costs": 754,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-22T00:00:00",
    "currency": "EUR",
    "from_country": "DK",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TD",
    "total_costs": 908,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-08-22T00:00:00",
    "currency": "USD",
    "from_country": "WS",
    "is_outbound": false,
    "service": "air",
    "to_country": "CX",
    "total_costs": 370,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-08-22T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "BF",
    "total_costs": 181,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-08-22T00:00:00",
    "currency": "USD",
    "from_country": "WF",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "SB",
    "total_costs": 953,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-08-22T00:00:00",
    "currency": "USD",
    "from_country": "CZ",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "NL",
    "total_costs": 251,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-08-23T00:00:00",
    "currency": "EUR",
    "from_country": "LV",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "CV",
    "total_costs": 344,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "SR",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "SR",
    "total_costs": 26,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "JM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "IS",
    "total_costs": 293,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "SJ",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "CI",
    "total_costs": 95,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-08-23T00:00:00",
    "currency": "KRW",
    "from_country": "CV",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "AT",
    "total_costs": 590,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "SL",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "IL",
    "total_costs": 257,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "TA",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "MG",
    "total_costs": 638,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "BA",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "CG",
    "total_costs": 684,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "EG",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "TA",
    "total_costs": 770,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "KN",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "UY",
    "total_costs": 634,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "HM",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "MQ",
    "total_costs": 820,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-08-23T00:00:00",
    "currency": "USD",
    "from_country": "AL",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "AE",
    "total_costs": 32,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "TC",
    "total_costs": 187,
    "tracking_number": ""
  },
  {
    "carrier": "malca_amit",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "AG",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TZ",
    "total_costs": 253,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "JE",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "DO",
    "total_costs": 941,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "TO",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "BI",
    "total_costs": 782,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "TF",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "NP",
    "total_costs": 245,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "CD",
    "is_outbound": false,
    "service": "air",
    "to_country": "UY",
    "total_costs": 249,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "MQ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "MK",
    "total_costs": 422,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-08-24T00:00:00",
    "currency": "USD",
    "from_country": "PY",
    "is_outbound": false,
    "service": "air",
    "to_country": "IT",
    "total_costs": 857,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-08-25T00:00:00",
    "currency": "USD",
    "from_country": "YE",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "CA",
    "total_costs": 645,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-08-25T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "SO",
    "total_costs": 22,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-08-26T00:00:00",
    "currency": "USD",
    "from_country": "VU",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "TN",
    "total_costs": 960,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-08-26T00:00:00",
    "currency": "USD",
    "from_country": "GB",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "CZ",
    "total_costs": 859,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-08-26T00:00:00",
    "currency": "USD",
    "from_country": "TD",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "VI",
    "total_costs": 631,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-08-26T00:00:00",
    "currency": "USD",
    "from_country": "VC",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "BO",
    "total_costs": 179,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-08-26T00:00:00",
    "currency": "USD",
    "from_country": "SA",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "BT",
    "total_costs": 63,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-26T00:00:00",
    "currency": "USD",
    "from_country": "VI",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "SH",
    "total_costs": 479,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-08-27T00:00:00",
    "currency": "USD",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "BY",
    "total_costs": 211,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-27T00:00:00",
    "currency": "USD",
    "from_country": "HU",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "BY",
    "total_costs": 989,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-08-27T00:00:00",
    "currency": "EUR",
    "from_country": "BL",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "PK",
    "total_costs": 31,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-08-27T00:00:00",
    "currency": "USD",
    "from_country": "AC",
    "is_outbound": true,
    "service": "air",
    "to_country": "LI",
    "total_costs": 472,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-08-27T00:00:00",
    "currency": "EUR",
    "from_country": "AG",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "MF",
    "total_costs": 700,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-08-27T00:00:00",
    "currency": "USD",
    "from_country": "IN",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "EH",
    "total_costs": 182,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-08-27T00:00:00",
    "currency": "USD",
    "from_country": "GF",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "SC",
    "total_costs": 511,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-08-27T00:00:00",
    "currency": "RUB",
    "from_country": "FO",
    "is_outbound": true,
    "service": "air",
    "to_country": "UG",
    "total_costs": 885,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-08-28T00:00:00",
    "currency": "USD",
    "from_country": "LA",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "TN",
    "total_costs": 450,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-28T00:00:00",
    "currency": "USD",
    "from_country": "TF",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "IR",
    "total_costs": 473,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-08-28T00:00:00",
    "currency": "PHP",
    "from_country": "TA",
    "is_outbound": true,
    "service": "air",
    "to_country": "AW",
    "total_costs": 439,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-08-28T00:00:00",
    "currency": "USD",
    "from_country": "BN",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "GQ",
    "total_costs": 563,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-08-28T00:00:00",
    "currency": "USD",
    "from_country": "RO",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "ER",
    "total_costs": 378,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-08-28T00:00:00",
    "currency": "USD",
    "from_country": "KW",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "SZ",
    "total_costs": 321,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": true,
    "service": "air",
    "to_country": "PS",
    "total_costs": 342,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "NG",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "GQ",
    "total_costs": 362,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "LT",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "MN",
    "total_costs": 184,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "CI",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "HM",
    "total_costs": 569,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "PM",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "GI",
    "total_costs": 329,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "MQ",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "VI",
    "total_costs": 887,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-08-29T00:00:00",
    "currency": "USD",
    "from_country": "UG",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "WF",
    "total_costs": 114,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-08-30T00:00:00",
    "currency": "USD",
    "from_country": "SK",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "DK",
    "total_costs": 38,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-08-30T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "ME",
    "total_costs": 847,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-08-30T00:00:00",
    "currency": "USD",
    "from_country": "BR",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "CV",
    "total_costs": 730,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-08-30T00:00:00",
    "currency": "USD",
    "from_country": "FM",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "US",
    "total_costs": 431,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-08-30T00:00:00",
    "currency": "EUR",
    "from_country": "MW",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "ER",
    "total_costs": 297,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-08-30T00:00:00",
    "currency": "EUR",
    "from_country": "PW",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "IT",
    "total_costs": 458,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-08-31T00:00:00",
    "currency": "USD",
    "from_country": "AN",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "MM",
    "total_costs": 347,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-08-31T00:00:00",
    "currency": "EUR",
    "from_country": "AW",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "YE",
    "total_costs": 947,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-08-31T00:00:00",
    "currency": "USD",
    "from_country": "FM",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "IO",
    "total_costs": 991,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-09-01T00:00:00",
    "currency": "USD",
    "from_country": "UM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BF",
    "total_costs": 774,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-09-01T00:00:00",
    "currency": "USD",
    "from_country": "TC",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "BW",
    "total_costs": 559,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-09-01T00:00:00",
    "currency": "USD",
    "from_country": "VA",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "GW",
    "total_costs": 796,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-09-02T00:00:00",
    "currency": "USD",
    "from_country": "LA",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "IR",
    "total_costs": 982,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-09-02T00:00:00",
    "currency": "USD",
    "from_country": "TO",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "TC",
    "total_costs": 81,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-09-02T00:00:00",
    "currency": "NOK",
    "from_country": "KH",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "VU",
    "total_costs": 803,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-09-02T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "TG",
    "total_costs": 93,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-09-02T00:00:00",
    "currency": "USD",
    "from_country": "MX",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "TN",
    "total_costs": 791,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-09-03T00:00:00",
    "currency": "USD",
    "from_country": "SN",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "CM",
    "total_costs": 516,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-09-03T00:00:00",
    "currency": "USD",
    "from_country": "PE",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "GS",
    "total_costs": 375,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-09-03T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "VU",
    "total_costs": 459,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-09-03T00:00:00",
    "currency": "USD",
    "from_country": "PW",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "EG",
    "total_costs": 608,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-09-03T00:00:00",
    "currency": "USD",
    "from_country": "JO",
    "is_outbound": true,
    "service": "air",
    "to_country": "GI",
    "total_costs": 335,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-09-04T00:00:00",
    "currency": "GBP",
    "from_country": "NZ",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "GW",
    "total_costs": 384,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "LK",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "WF",
    "total_costs": 748,
    "tracking_number": ""
  },
  {
    "carrier": "gls_denmark",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "CU",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "GL",
    "total_costs": 496,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "ST",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CA",
    "total_costs": 859,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "FJ",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "BL",
    "total_costs": 245,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-09-04T00:00:00",
    "currency": "NZD",
    "from_country": "LA",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GW",
    "total_costs": 67,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "VG",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "DZ",
    "total_costs": 474,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "MY",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "MO",
    "total_costs": 955,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "UM",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "NP",
    "total_costs": 317,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "GH",
    "is_outbound": true,
    "service": "air",
    "to_country": "LR",
    "total_costs": 603,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-09-04T00:00:00",
    "currency": "USD",
    "from_country": "GB",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "GM",
    "total_costs": 615,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-09-05T00:00:00",
    "currency": "USD",
    "from_country": "MY",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "LS",
    "total_costs": 753,
    "tracking_number": ""
  },
  {
    "carrier": "glopal",
    "created": "2021-09-05T00:00:00",
    "currency": "USD",
    "from_country": "EH",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "CI",
    "total_costs": 500,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-09-05T00:00:00",
    "currency": "USD",
    "from_country": "CN",
    "is_outbound": true,
    "service": "air",
    "to_country": "IE",
    "total_costs": 225,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-09-05T00:00:00",
    "currency": "USD",
    "from_country": "BB",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "BB",
    "total_costs": 612,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-09-05T00:00:00",
    "currency": "USD",
    "from_country": "TJ",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "NZ",
    "total_costs": 634,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-09-06T00:00:00",
    "currency": "USD",
    "from_country": "GT",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "UZ",
    "total_costs": 493,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-09-06T00:00:00",
    "currency": "USD",
    "from_country": "KP",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "FJ",
    "total_costs": 616,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-09-06T00:00:00",
    "currency": "USD",
    "from_country": "SD",
    "is_outbound": true,
    "service": "air",
    "to_country": "TT",
    "total_costs": 872,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "SA",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "DJ",
    "total_costs": 313,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "LS",
    "is_outbound": false,
    "service": "air",
    "to_country": "JP",
    "total_costs": 494,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "SV",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "KE",
    "total_costs": 205,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "BZ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AM",
    "total_costs": 545,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "WS",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "AZ",
    "total_costs": 118,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "BR",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "GB",
    "total_costs": 901,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-09-07T00:00:00",
    "currency": "NOK",
    "from_country": "SE",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GU",
    "total_costs": 542,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-09-07T00:00:00",
    "currency": "USD",
    "from_country": "OM",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "RU",
    "total_costs": 61,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-09-08T00:00:00",
    "currency": "USD",
    "from_country": "LK",
    "is_outbound": true,
    "service": "air",
    "to_country": "GG",
    "total_costs": 799,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-09-08T00:00:00",
    "currency": "EUR",
    "from_country": "MQ",
    "is_outbound": false,
    "service": "air",
    "to_country": "BE",
    "total_costs": 864,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-09-08T00:00:00",
    "currency": "USD",
    "from_country": "US",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "KN",
    "total_costs": 428,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-09-08T00:00:00",
    "currency": "CRC",
    "from_country": "NC",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BD",
    "total_costs": 840,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-09-08T00:00:00",
    "currency": "USD",
    "from_country": "BY",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "ER",
    "total_costs": 323,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-09-08T00:00:00",
    "currency": "USD",
    "from_country": "MZ",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "KE",
    "total_costs": 951,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-09-09T00:00:00",
    "currency": "USD",
    "from_country": "PK",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MR",
    "total_costs": 352,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-09-09T00:00:00",
    "currency": "GBP",
    "from_country": "MP",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "NU",
    "total_costs": 7,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-09-09T00:00:00",
    "currency": "USD",
    "from_country": "MY",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "AU",
    "total_costs": 414,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-09-09T00:00:00",
    "currency": "USD",
    "from_country": "NR",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "NR",
    "total_costs": 864,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-09-10T00:00:00",
    "currency": "USD",
    "from_country": "GE",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "MP",
    "total_costs": 394,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-09-10T00:00:00",
    "currency": "KRW",
    "from_country": "KP",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "MZ",
    "total_costs": 195,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-09-10T00:00:00",
    "currency": "KRW",
    "from_country": "CM",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "GH",
    "total_costs": 967,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-09-10T00:00:00",
    "currency": "MXN",
    "from_country": "AS",
    "is_outbound": false,
    "service": "air",
    "to_country": "TF",
    "total_costs": 287,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-09-10T00:00:00",
    "currency": "USD",
    "from_country": "NZ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "UM",
    "total_costs": 724,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-09-11T00:00:00",
    "currency": "USD",
    "from_country": "GL",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "JE",
    "total_costs": 848,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-09-11T00:00:00",
    "currency": "USD",
    "from_country": "BG",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "IR",
    "total_costs": 885,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-09-11T00:00:00",
    "currency": "USD",
    "from_country": "IL",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "CH",
    "total_costs": 854,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-09-11T00:00:00",
    "currency": "USD",
    "from_country": "GD",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "UA",
    "total_costs": 839,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-09-11T00:00:00",
    "currency": "USD",
    "from_country": "NO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "TD",
    "total_costs": 330,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-09-12T00:00:00",
    "currency": "BRL",
    "from_country": "AM",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "PF",
    "total_costs": 408,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-09-12T00:00:00",
    "currency": "USD",
    "from_country": "RS",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "SH",
    "total_costs": 905,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-09-12T00:00:00",
    "currency": "USD",
    "from_country": "ZW",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "MU",
    "total_costs": 455,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_express",
    "created": "2021-09-13T00:00:00",
    "currency": "USD",
    "from_country": "CH",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "HK",
    "total_costs": 584,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-09-13T00:00:00",
    "currency": "USD",
    "from_country": "HR",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "SR",
    "total_costs": 919,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-09-13T00:00:00",
    "currency": "EUR",
    "from_country": "MX",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "US",
    "total_costs": 495,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-09-13T00:00:00",
    "currency": "USD",
    "from_country": "CX",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "CL",
    "total_costs": 595,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-09-13T00:00:00",
    "currency": "USD",
    "from_country": "UY",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "AS",
    "total_costs": 497,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-09-14T00:00:00",
    "currency": "RMB",
    "from_country": "CL",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "NU",
    "total_costs": 179,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-09-14T00:00:00",
    "currency": "USD",
    "from_country": "VA",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "PM",
    "total_costs": 706,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-09-14T00:00:00",
    "currency": "USD",
    "from_country": "HT",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "NC",
    "total_costs": 102,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-09-14T00:00:00",
    "currency": "USD",
    "from_country": "AM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "BA",
    "total_costs": 224,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-09-14T00:00:00",
    "currency": "USD",
    "from_country": "DO",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "TH",
    "total_costs": 150,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-09-14T00:00:00",
    "currency": "USD",
    "from_country": "TF",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "DE",
    "total_costs": 487,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-09-15T00:00:00",
    "currency": "USD",
    "from_country": "AU",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "BZ",
    "total_costs": 347,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-09-15T00:00:00",
    "currency": "USD",
    "from_country": "BW",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "HK",
    "total_costs": 181,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-09-15T00:00:00",
    "currency": "USD",
    "from_country": "LA",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "PG",
    "total_costs": 54,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-09-15T00:00:00",
    "currency": "USD",
    "from_country": "DJ",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "ML",
    "total_costs": 673,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-09-15T00:00:00",
    "currency": "USD",
    "from_country": "AN",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "AE",
    "total_costs": 588,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-09-15T00:00:00",
    "currency": "EUR",
    "from_country": "CY",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "NO",
    "total_costs": 779,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-09-16T00:00:00",
    "currency": "USD",
    "from_country": "NF",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GF",
    "total_costs": 272,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-09-16T00:00:00",
    "currency": "THB",
    "from_country": "KG",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "ET",
    "total_costs": 599,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-09-16T00:00:00",
    "currency": "USD",
    "from_country": "MZ",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "MT",
    "total_costs": 455,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-09-16T00:00:00",
    "currency": "USD",
    "from_country": "AR",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "EH",
    "total_costs": 199,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-09-16T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "GY",
    "total_costs": 15,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-09-17T00:00:00",
    "currency": "USD",
    "from_country": "TD",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "RE",
    "total_costs": 51,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-09-17T00:00:00",
    "currency": "USD",
    "from_country": "GI",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "WF",
    "total_costs": 2,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-09-17T00:00:00",
    "currency": "USD",
    "from_country": "TG",
    "is_outbound": false,
    "service": "air",
    "to_country": "BV",
    "total_costs": 866,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-09-17T00:00:00",
    "currency": "USD",
    "from_country": "KR",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "YE",
    "total_costs": 823,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-09-18T00:00:00",
    "currency": "USD",
    "from_country": "XZ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "GS",
    "total_costs": 910,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-09-18T00:00:00",
    "currency": "USD",
    "from_country": "RS",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "TW",
    "total_costs": 281,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-09-18T00:00:00",
    "currency": "USD",
    "from_country": "NF",
    "is_outbound": true,
    "service": "air",
    "to_country": "DK",
    "total_costs": 300,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-09-18T00:00:00",
    "currency": "SGD",
    "from_country": "LV",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "AO",
    "total_costs": 772,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-09-19T00:00:00",
    "currency": "KRW",
    "from_country": "AL",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "TG",
    "total_costs": 215,
    "tracking_number": ""
  },
  {
    "carrier": "skynet",
    "created": "2021-09-19T00:00:00",
    "currency": "USD",
    "from_country": "PN",
    "is_outbound": false,
    "service": "air",
    "to_country": "IQ",
    "total_costs": 564,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-09-19T00:00:00",
    "currency": "USD",
    "from_country": "YE",
    "is_outbound": false,
    "service": "air",
    "to_country": "AQ",
    "total_costs": 305,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-09-19T00:00:00",
    "currency": "USD",
    "from_country": "TJ",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "CA",
    "total_costs": 678,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-09-19T00:00:00",
    "currency": "USD",
    "from_country": "BM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "SN",
    "total_costs": 793,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-09-19T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "HT",
    "total_costs": 321,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-09-20T00:00:00",
    "currency": "USD",
    "from_country": "AL",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "VU",
    "total_costs": 441,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-09-20T00:00:00",
    "currency": "USD",
    "from_country": "ST",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "CO",
    "total_costs": 680,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-09-20T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "CG",
    "total_costs": 666,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-09-20T00:00:00",
    "currency": "USD",
    "from_country": "WS",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "PE",
    "total_costs": 228,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-09-20T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "HM",
    "total_costs": 178,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-09-20T00:00:00",
    "currency": "USD",
    "from_country": "SH",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "VC",
    "total_costs": 174,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "MY",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MW",
    "total_costs": 50,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-09-21T00:00:00",
    "currency": "GBP",
    "from_country": "DJ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "AX",
    "total_costs": 568,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "AO",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "MO",
    "total_costs": 196,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "ML",
    "is_outbound": true,
    "service": "air",
    "to_country": "NA",
    "total_costs": 962,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "FO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "EE",
    "total_costs": 893,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "BL",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "LR",
    "total_costs": 15,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "GY",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "GD",
    "total_costs": 376,
    "tracking_number": ""
  },
  {
    "carrier": "yrc",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "GN",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "KW",
    "total_costs": 422,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "BF",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "BF",
    "total_costs": 571,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-09-21T00:00:00",
    "currency": "USD",
    "from_country": "LC",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "MU",
    "total_costs": 164,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-09-22T00:00:00",
    "currency": "USD",
    "from_country": "LB",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "IQ",
    "total_costs": 614,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-09-22T00:00:00",
    "currency": "EUR",
    "from_country": "SN",
    "is_outbound": false,
    "service": "air",
    "to_country": "MQ",
    "total_costs": 31,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-09-22T00:00:00",
    "currency": "USD",
    "from_country": "EC",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "CF",
    "total_costs": 488,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-09-22T00:00:00",
    "currency": "AUD",
    "from_country": "JM",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "UY",
    "total_costs": 797,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-09-22T00:00:00",
    "currency": "MXN",
    "from_country": "MA",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "HN",
    "total_costs": 505,
    "tracking_number": ""
  },
  {
    "carrier": "odfl",
    "created": "2021-09-23T00:00:00",
    "currency": "USD",
    "from_country": "SL",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "TM",
    "total_costs": 222,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-09-23T00:00:00",
    "currency": "USD",
    "from_country": "MW",
    "is_outbound": true,
    "service": "air",
    "to_country": "SA",
    "total_costs": 109,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-09-23T00:00:00",
    "currency": "USD",
    "from_country": "KH",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "KG",
    "total_costs": 758,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-09-23T00:00:00",
    "currency": "EUR",
    "from_country": "VN",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "NU",
    "total_costs": 266,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-09-23T00:00:00",
    "currency": "GBP",
    "from_country": "DK",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BV",
    "total_costs": 685,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-09-24T00:00:00",
    "currency": "USD",
    "from_country": "AC",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "SY",
    "total_costs": 931,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-09-24T00:00:00",
    "currency": "EUR",
    "from_country": "IL",
    "is_outbound": false,
    "service": "air",
    "to_country": "SH",
    "total_costs": 21,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-09-24T00:00:00",
    "currency": "MXN",
    "from_country": "KM",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "PH",
    "total_costs": 118,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-09-25T00:00:00",
    "currency": "USD",
    "from_country": "BJ",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "GU",
    "total_costs": 416,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-09-25T00:00:00",
    "currency": "USD",
    "from_country": "JP",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "FI",
    "total_costs": 558,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-09-25T00:00:00",
    "currency": "USD",
    "from_country": "KZ",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "BA",
    "total_costs": 102,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-09-25T00:00:00",
    "currency": "USD",
    "from_country": "EC",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "YT",
    "total_costs": 745,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-09-25T00:00:00",
    "currency": "USD",
    "from_country": "BA",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "BS",
    "total_costs": 863,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-09-25T00:00:00",
    "currency": "USD",
    "from_country": "AD",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "SI",
    "total_costs": 550,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-09-26T00:00:00",
    "currency": "USD",
    "from_country": "TM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BN",
    "total_costs": 670,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-09-26T00:00:00",
    "currency": "USD",
    "from_country": "SN",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "IR",
    "total_costs": 856,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-09-26T00:00:00",
    "currency": "USD",
    "from_country": "CN",
    "is_outbound": true,
    "service": "air",
    "to_country": "CA",
    "total_costs": 802,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-09-26T00:00:00",
    "currency": "USD",
    "from_country": "SM",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "LR",
    "total_costs": 125,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-09-26T00:00:00",
    "currency": "USD",
    "from_country": "LA",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "EG",
    "total_costs": 235,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-09-27T00:00:00",
    "currency": "USD",
    "from_country": "ZM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "HT",
    "total_costs": 290,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-09-27T00:00:00",
    "currency": "USD",
    "from_country": "PG",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "AF",
    "total_costs": 203,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-09-27T00:00:00",
    "currency": "USD",
    "from_country": "CM",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "ZW",
    "total_costs": 520,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-09-27T00:00:00",
    "currency": "USD",
    "from_country": "NR",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "BL",
    "total_costs": 694,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-09-28T00:00:00",
    "currency": "USD",
    "from_country": "MH",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "VC",
    "total_costs": 648,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-09-28T00:00:00",
    "currency": "USD",
    "from_country": "NI",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "TZ",
    "total_costs": 386,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-09-28T00:00:00",
    "currency": "USD",
    "from_country": "MN",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "SO",
    "total_costs": 800,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-09-28T00:00:00",
    "currency": "USD",
    "from_country": "IR",
    "is_outbound": false,
    "service": "air",
    "to_country": "JE",
    "total_costs": 537,
    "tracking_number": ""
  },
  {
    "carrier": "pilot_freight",
    "created": "2021-09-29T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "PG",
    "total_costs": 600,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-09-29T00:00:00",
    "currency": "ARS",
    "from_country": "PW",
    "is_outbound": false,
    "service": "air",
    "to_country": "MP",
    "total_costs": 292,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-09-29T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "MY",
    "total_costs": 277,
    "tracking_number": ""
  },
  {
    "carrier": "landmark",
    "created": "2021-09-29T00:00:00",
    "currency": "USD",
    "from_country": "VE",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "BV",
    "total_costs": 366,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-09-29T00:00:00",
    "currency": "EUR",
    "from_country": "UG",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "CO",
    "total_costs": 138,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-09-29T00:00:00",
    "currency": "USD",
    "from_country": "DZ",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "AE",
    "total_costs": 186,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-09-29T00:00:00",
    "currency": "USD",
    "from_country": "HM",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "TG",
    "total_costs": 416,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-09-30T00:00:00",
    "currency": "USD",
    "from_country": "CY",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "TG",
    "total_costs": 979,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-09-30T00:00:00",
    "currency": "USD",
    "from_country": "VE",
    "is_outbound": true,
    "service": "air",
    "to_country": "KG",
    "total_costs": 223,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-09-30T00:00:00",
    "currency": "USD",
    "from_country": "MW",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "GQ",
    "total_costs": 667,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-09-30T00:00:00",
    "currency": "USD",
    "from_country": "TG",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "SJ",
    "total_costs": 333,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-09-30T00:00:00",
    "currency": "USD",
    "from_country": "GR",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "SR",
    "total_costs": 976,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-09-30T00:00:00",
    "currency": "USD",
    "from_country": "YE",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "RW",
    "total_costs": 470,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-10-01T00:00:00",
    "currency": "HKD",
    "from_country": "CY",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "SE",
    "total_costs": 608,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-10-01T00:00:00",
    "currency": "USD",
    "from_country": "CG",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "NO",
    "total_costs": 344,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-10-01T00:00:00",
    "currency": "SEK",
    "from_country": "TR",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "FI",
    "total_costs": 625,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-10-01T00:00:00",
    "currency": "CRC",
    "from_country": "PA",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "MA",
    "total_costs": 608,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-10-01T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "SC",
    "total_costs": 108,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-10-01T00:00:00",
    "currency": "USD",
    "from_country": "BM",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "US",
    "total_costs": 446,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-10-02T00:00:00",
    "currency": "USD",
    "from_country": "CO",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "TK",
    "total_costs": 782,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-10-02T00:00:00",
    "currency": "USD",
    "from_country": "MM",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "CA",
    "total_costs": 551,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-10-02T00:00:00",
    "currency": "USD",
    "from_country": "CH",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AD",
    "total_costs": 294,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-10-02T00:00:00",
    "currency": "EUR",
    "from_country": "LR",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "CR",
    "total_costs": 194,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-10-03T00:00:00",
    "currency": "USD",
    "from_country": "DE",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "MR",
    "total_costs": 703,
    "tracking_number": ""
  },
  {
    "carrier": "purolator",
    "created": "2021-10-03T00:00:00",
    "currency": "USD",
    "from_country": "PH",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "KM",
    "total_costs": 418,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-10-03T00:00:00",
    "currency": "USD",
    "from_country": "CR",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "DM",
    "total_costs": 1,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-10-03T00:00:00",
    "currency": "USD",
    "from_country": "OM",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MW",
    "total_costs": 920,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-10-04T00:00:00",
    "currency": "USD",
    "from_country": "AI",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "CD",
    "total_costs": 680,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-10-04T00:00:00",
    "currency": "KRW",
    "from_country": "MF",
    "is_outbound": false,
    "service": "air",
    "to_country": "VI",
    "total_costs": 406,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-10-04T00:00:00",
    "currency": "MXN",
    "from_country": "EC",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "CF",
    "total_costs": 635,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-10-04T00:00:00",
    "currency": "USD",
    "from_country": "PH",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "IE",
    "total_costs": 221,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-10-04T00:00:00",
    "currency": "CLF",
    "from_country": "BE",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "KW",
    "total_costs": 722,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-10-04T00:00:00",
    "currency": "USD",
    "from_country": "SR",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "CK",
    "total_costs": 683,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-10-05T00:00:00",
    "currency": "EUR",
    "from_country": "MM",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "SH",
    "total_costs": 702,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "KM",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "TW",
    "total_costs": 437,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-10-05T00:00:00",
    "currency": "GBP",
    "from_country": "GH",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "MZ",
    "total_costs": 685,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "SE",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "GD",
    "total_costs": 775,
    "tracking_number": ""
  },
  {
    "carrier": "apc_logistics",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "EG",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "HR",
    "total_costs": 939,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "DJ",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "JP",
    "total_costs": 732,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-10-05T00:00:00",
    "currency": "EUR",
    "from_country": "BF",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "NI",
    "total_costs": 992,
    "tracking_number": ""
  },
  {
    "carrier": "bring",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "TG",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "LU",
    "total_costs": 500,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "GU",
    "is_outbound": false,
    "service": "air",
    "to_country": "BH",
    "total_costs": 486,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-10-05T00:00:00",
    "currency": "USD",
    "from_country": "LC",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "XZ",
    "total_costs": 878,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-10-06T00:00:00",
    "currency": "HKD",
    "from_country": "NR",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "CX",
    "total_costs": 734,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-10-06T00:00:00",
    "currency": "DOP",
    "from_country": "LI",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "PH",
    "total_costs": 0,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-10-06T00:00:00",
    "currency": "USD",
    "from_country": "UG",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "GH",
    "total_costs": 815,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-10-07T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "AF",
    "total_costs": 142,
    "tracking_number": ""
  },
  {
    "carrier": "post_nl",
    "created": "2021-10-07T00:00:00",
    "currency": "USD",
    "from_country": "AN",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "VG",
    "total_costs": 903,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-10-07T00:00:00",
    "currency": "USD",
    "from_country": "LY",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "NC",
    "total_costs": 601,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-10-07T00:00:00",
    "currency": "USD",
    "from_country": "PM",
    "is_outbound": true,
    "service": "air",
    "to_country": "LB",
    "total_costs": 335,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "HR",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "BN",
    "total_costs": 198,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "SO",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "MK",
    "total_costs": 165,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-10-08T00:00:00",
    "currency": "PLN",
    "from_country": "PN",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "UM",
    "total_costs": 721,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "TZ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "LR",
    "total_costs": 79,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "SR",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "KY",
    "total_costs": 960,
    "tracking_number": ""
  },
  {
    "carrier": "dsv",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "MQ",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "LT",
    "total_costs": 55,
    "tracking_number": ""
  },
  {
    "carrier": "osm_worldwide",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "CK",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "MA",
    "total_costs": 294,
    "tracking_number": ""
  },
  {
    "carrier": "canada_post",
    "created": "2021-10-08T00:00:00",
    "currency": "USD",
    "from_country": "BR",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "KI",
    "total_costs": 896,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-10-08T00:00:00",
    "currency": "TWD",
    "from_country": "GH",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "PA",
    "total_costs": 594,
    "tracking_number": ""
  },
  {
    "carrier": "jas",
    "created": "2021-10-09T00:00:00",
    "currency": "USD",
    "from_country": "KG",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "GP",
    "total_costs": 473,
    "tracking_number": ""
  },
  {
    "carrier": "trakpak",
    "created": "2021-10-09T00:00:00",
    "currency": "USD",
    "from_country": "MY",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "SA",
    "total_costs": 278,
    "tracking_number": ""
  },
  {
    "carrier": "post_nord",
    "created": "2021-10-09T00:00:00",
    "currency": "USD",
    "from_country": "EE",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "BG",
    "total_costs": 779,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-10-10T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "LY",
    "total_costs": 270,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-10-10T00:00:00",
    "currency": "MOP",
    "from_country": "UM",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "BJ",
    "total_costs": 230,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-10-10T00:00:00",
    "currency": "USD",
    "from_country": "TC",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "NU",
    "total_costs": 479,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-10-11T00:00:00",
    "currency": "MYR",
    "from_country": "CG",
    "is_outbound": true,
    "service": "air",
    "to_country": "DJ",
    "total_costs": 216,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-10-11T00:00:00",
    "currency": "CZK",
    "from_country": "SI",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "PM",
    "total_costs": 702,
    "tracking_number": ""
  },
  {
    "carrier": "peak",
    "created": "2021-10-11T00:00:00",
    "currency": "USD",
    "from_country": "ZM",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "GS",
    "total_costs": 319,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-10-12T00:00:00",
    "currency": "USD",
    "from_country": "EH",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "DE",
    "total_costs": 996,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-10-12T00:00:00",
    "currency": "USD",
    "from_country": "AG",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "BN",
    "total_costs": 480,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-10-12T00:00:00",
    "currency": "ARS",
    "from_country": "MX",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "MN",
    "total_costs": 66,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-10-12T00:00:00",
    "currency": "USD",
    "from_country": "CO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "KM",
    "total_costs": 234,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-10-12T00:00:00",
    "currency": "USD",
    "from_country": "FO",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "KG",
    "total_costs": 172,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-10-12T00:00:00",
    "currency": "USD",
    "from_country": "KE",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "GT",
    "total_costs": 860,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "GA",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BI",
    "total_costs": 439,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-10-13T00:00:00",
    "currency": "EUR",
    "from_country": "MG",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "SL",
    "total_costs": 173,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "BG",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "NR",
    "total_costs": 321,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_france",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "GP",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "GH",
    "total_costs": 289,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "CO",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "IM",
    "total_costs": 863,
    "tracking_number": ""
  },
  {
    "carrier": "echo_logistics",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "OM",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "FM",
    "total_costs": 528,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "JE",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "NU",
    "total_costs": 440,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-10-13T00:00:00",
    "currency": "KRW",
    "from_country": "MQ",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "AU",
    "total_costs": 797,
    "tracking_number": ""
  },
  {
    "carrier": "new_penn",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "TW",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "CV",
    "total_costs": 217,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "LC",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "GP",
    "total_costs": 265,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-10-13T00:00:00",
    "currency": "USD",
    "from_country": "CD",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "AX",
    "total_costs": 746,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-10-14T00:00:00",
    "currency": "USD",
    "from_country": "AL",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AX",
    "total_costs": 780,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-10-14T00:00:00",
    "currency": "USD",
    "from_country": "PN",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "CH",
    "total_costs": 946,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-10-14T00:00:00",
    "currency": "PHP",
    "from_country": "US",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "AN",
    "total_costs": 917,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-10-15T00:00:00",
    "currency": "USD",
    "from_country": "IM",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "GE",
    "total_costs": 869,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-10-15T00:00:00",
    "currency": "USD",
    "from_country": "TF",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "LS",
    "total_costs": 598,
    "tracking_number": ""
  },
  {
    "carrier": "saia",
    "created": "2021-10-15T00:00:00",
    "currency": "USD",
    "from_country": "DE",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "MR",
    "total_costs": 405,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-10-15T00:00:00",
    "currency": "USD",
    "from_country": "AU",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "RE",
    "total_costs": 609,
    "tracking_number": ""
  },
  {
    "carrier": "priority_one",
    "created": "2021-10-16T00:00:00",
    "currency": "USD",
    "from_country": "MC",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "SN",
    "total_costs": 937,
    "tracking_number": ""
  },
  {
    "carrier": "nz_post",
    "created": "2021-10-16T00:00:00",
    "currency": "USD",
    "from_country": "IO",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MC",
    "total_costs": 535,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-10-16T00:00:00",
    "currency": "USD",
    "from_country": "KY",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "BV",
    "total_costs": 718,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global",
    "created": "2021-10-17T00:00:00",
    "currency": "USD",
    "from_country": "IN",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "ER",
    "total_costs": 115,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-10-17T00:00:00",
    "currency": "CZK",
    "from_country": "LR",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "CO",
    "total_costs": 123,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-10-17T00:00:00",
    "currency": "USD",
    "from_country": "TL",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "PY",
    "total_costs": 851,
    "tracking_number": ""
  },
  {
    "carrier": "gso",
    "created": "2021-10-17T00:00:00",
    "currency": "USD",
    "from_country": "CX",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "BR",
    "total_costs": 849,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "VE",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "LU",
    "total_costs": 979,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_sameday",
    "created": "2021-10-18T00:00:00",
    "currency": "RMB",
    "from_country": "HR",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "KG",
    "total_costs": 288,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "PT",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "GE",
    "total_costs": 438,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "BT",
    "is_outbound": true,
    "service": "air",
    "to_country": "JM",
    "total_costs": 839,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "FI",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "PL",
    "total_costs": 256,
    "tracking_number": ""
  },
  {
    "carrier": "agistix",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "PN",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "AG",
    "total_costs": 377,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "MG",
    "is_outbound": false,
    "service": "priority_ddp",
    "to_country": "LY",
    "total_costs": 283,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-10-18T00:00:00",
    "currency": "USD",
    "from_country": "AT",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "KG",
    "total_costs": 470,
    "tracking_number": ""
  },
  {
    "carrier": "fedex",
    "created": "2021-10-19T00:00:00",
    "currency": "USD",
    "from_country": "CC",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "KI",
    "total_costs": 361,
    "tracking_number": ""
  },
  {
    "carrier": "geodis",
    "created": "2021-10-20T00:00:00",
    "currency": "USD",
    "from_country": "ZA",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "VG",
    "total_costs": 407,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-10-20T00:00:00",
    "currency": "RUB",
    "from_country": "AS",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "NR",
    "total_costs": 798,
    "tracking_number": ""
  },
  {
    "carrier": "usps_tracking",
    "created": "2021-10-20T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "expedited_ddu",
    "to_country": "GG",
    "total_costs": 631,
    "tracking_number": ""
  },
  {
    "carrier": "apg",
    "created": "2021-10-20T00:00:00",
    "currency": "USD",
    "from_country": "MC",
    "is_outbound": false,
    "service": "air",
    "to_country": "MC",
    "total_costs": 31,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-10-20T00:00:00",
    "currency": "CLF",
    "from_country": "CU",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "GQ",
    "total_costs": 951,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "MP",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "KW",
    "total_costs": 663,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "BN",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "TO",
    "total_costs": 415,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "LI",
    "is_outbound": false,
    "service": "hot_shot",
    "to_country": "CC",
    "total_costs": 77,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-10-21T00:00:00",
    "currency": "EUR",
    "from_country": "KH",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "DJ",
    "total_costs": 175,
    "tracking_number": ""
  },
  {
    "carrier": "averitt",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "CK",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "KR",
    "total_costs": 710,
    "tracking_number": ""
  },
  {
    "carrier": "royal_mail",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "BE",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "NP",
    "total_costs": 648,
    "tracking_number": ""
  },
  {
    "carrier": "tnt_australia",
    "created": "2021-10-21T00:00:00",
    "currency": "EUR",
    "from_country": "AW",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "TF",
    "total_costs": 105,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_germany",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "PR",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "JE",
    "total_costs": 988,
    "tracking_number": ""
  },
  {
    "carrier": "sf_express",
    "created": "2021-10-21T00:00:00",
    "currency": "USD",
    "from_country": "LV",
    "is_outbound": true,
    "service": "expedited_ddu",
    "to_country": "CC",
    "total_costs": 764,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-10-22T00:00:00",
    "currency": "USD",
    "from_country": "PF",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "GR",
    "total_costs": 789,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-10-22T00:00:00",
    "currency": "CHF",
    "from_country": "PT",
    "is_outbound": true,
    "service": "hot_shot",
    "to_country": "IS",
    "total_costs": 253,
    "tracking_number": ""
  },
  {
    "carrier": "ninja_van",
    "created": "2021-10-22T00:00:00",
    "currency": "DKK",
    "from_country": "TK",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "GF",
    "total_costs": 896,
    "tracking_number": ""
  },
  {
    "carrier": "south_eastern",
    "created": "2021-10-22T00:00:00",
    "currency": "USD",
    "from_country": "HN",
    "is_outbound": false,
    "service": "air",
    "to_country": "BB",
    "total_costs": 995,
    "tracking_number": ""
  },
  {
    "carrier": "db_schenker",
    "created": "2021-10-22T00:00:00",
    "currency": "USD",
    "from_country": "ER",
    "is_outbound": false,
    "service": "priority_ddu",
    "to_country": "XZ",
    "total_costs": 207,
    "tracking_number": ""
  },
  {
    "carrier": "dmtrans",
    "created": "2021-10-23T00:00:00",
    "currency": "USD",
    "from_country": "TN",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "GR",
    "total_costs": 699,
    "tracking_number": ""
  },
  {
    "carrier": "ups",
    "created": "2021-10-23T00:00:00",
    "currency": "USD",
    "from_country": "NL",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "TR",
    "total_costs": 215,
    "tracking_number": ""
  },
  {
    "carrier": "singapore_post",
    "created": "2021-10-23T00:00:00",
    "currency": "USD",
    "from_country": "ME",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "BT",
    "total_costs": 525,
    "tracking_number": ""
  },
  {
    "carrier": "xpo_logistics",
    "created": "2021-10-23T00:00:00",
    "currency": "USD",
    "from_country": "BE",
    "is_outbound": false,
    "service": "air",
    "to_country": "NZ",
    "total_costs": 677,
    "tracking_number": ""
  },
  {
    "carrier": "japanpost",
    "created": "2021-10-23T00:00:00",
    "currency": "USD",
    "from_country": "AU",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "ST",
    "total_costs": 944,
    "tracking_number": ""
  },
  {
    "carrier": "fli",
    "created": "2021-10-23T00:00:00",
    "currency": "USD",
    "from_country": "GQ",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "FJ",
    "total_costs": 290,
    "tracking_number": ""
  },
  {
    "carrier": "dhl_global_forwarding",
    "created": "2021-10-24T00:00:00",
    "currency": "USD",
    "from_country": "NO",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "UG",
    "total_costs": 74,
    "tracking_number": ""
  },
  {
    "carrier": "ward",
    "created": "2021-10-24T00:00:00",
    "currency": "EUR",
    "from_country": "AC",
    "is_outbound": true,
    "service": "air",
    "to_country": "BI",
    "total_costs": 101,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-10-24T00:00:00",
    "currency": "USD",
    "from_country": "LY",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "LY",
    "total_costs": 593,
    "tracking_number": ""
  },
  {
    "carrier": "roadie",
    "created": "2021-10-24T00:00:00",
    "currency": "USD",
    "from_country": "NP",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "NE",
    "total_costs": 452,
    "tracking_number": ""
  },
  {
    "carrier": "seino",
    "created": "2021-10-24T00:00:00",
    "currency": "USD",
    "from_country": "DZ",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "SG",
    "total_costs": 945,
    "tracking_number": ""
  },
  {
    "carrier": "dpd_local",
    "created": "2021-10-24T00:00:00",
    "currency": "USD",
    "from_country": "CR",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "EH",
    "total_costs": 155,
    "tracking_number": ""
  },
  {
    "carrier": "aduiepyle",
    "created": "2021-10-24T00:00:00",
    "currency": "USD",
    "from_country": "WF",
    "is_outbound": true,
    "service": "air",
    "to_country": "UY",
    "total_costs": 705,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "CA",
    "is_outbound": true,
    "service": "expedited_ddp",
    "to_country": "GD",
    "total_costs": 247,
    "tracking_number": ""
  },
  {
    "carrier": "gls_denmark",
    "created": "2021-10-25T00:00:00",
    "currency": "TWD",
    "from_country": "KI",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "MH",
    "total_costs": 550,
    "tracking_number": ""
  },
  {
    "carrier": "bpost",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "GA",
    "is_outbound": true,
    "service": "priority_ddu_w_dc",
    "to_country": "DE",
    "total_costs": 524,
    "tracking_number": ""
  },
  {
    "carrier": "estes",
    "created": "2021-10-25T00:00:00",
    "currency": "EUR",
    "from_country": "PT",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "AF",
    "total_costs": 708,
    "tracking_number": ""
  },
  {
    "carrier": "crossflight",
    "created": "2021-10-25T00:00:00",
    "currency": "EUR",
    "from_country": "LY",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "LR",
    "total_costs": 816,
    "tracking_number": ""
  },
  {
    "carrier": "ups_freight",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "AM",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "AI",
    "total_costs": 630,
    "tracking_number": ""
  },
  {
    "carrier": "sendle",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "PK",
    "is_outbound": true,
    "service": "priority_pqw_ddu",
    "to_country": "MD",
    "total_costs": 490,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "AS",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "BW",
    "total_costs": 704,
    "tracking_number": ""
  },
  {
    "carrier": "dhl",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "SL",
    "is_outbound": false,
    "service": "air",
    "to_country": "JP",
    "total_costs": 387,
    "tracking_number": ""
  },
  {
    "carrier": "r_plus_l",
    "created": "2021-10-25T00:00:00",
    "currency": "USD",
    "from_country": "TW",
    "is_outbound": false,
    "service": "priority_ddu_w_dc",
    "to_country": "CG",
    "total_costs": 99,
    "tracking_number": ""
  },
  {
    "carrier": "chrono_post",
    "created": "2021-10-26T00:00:00",
    "currency": "USD",
    "from_country": "ML",
    "is_outbound": true,
    "service": "air",
    "to_country": "GG",
    "total_costs": 307,
    "tracking_number": ""
  },
  {
    "carrier": "fedex_freight",
    "created": "2021-10-26T00:00:00",
    "currency": "USD",
    "from_country": "CL",
    "is_outbound": false,
    "service": "expedited_ddp",
    "to_country": "JM",
    "total_costs": 408,
    "tracking_number": ""
  },
  {
    "carrier": "purolator_international",
    "created": "2021-10-26T00:00:00",
    "currency": "USD",
    "from_country": "MK",
    "is_outbound": false,
    "service": "priority_pqw_ddu",
    "to_country": "CN",
    "total_costs": 991,
    "tracking_number": ""
  },
  {
    "carrier": "ontrac",
    "created": "2021-10-26T00:00:00",
    "currency": "USD",
    "from_country": "DK",
    "is_outbound": true,
    "service": "white_glove",
    "to_country": "ET",
    "total_costs": 13,
    "tracking_number": ""
  },
  {
    "carrier": "startrack",
    "created": "2021-10-27T00:00:00",
    "currency": "MXN",
    "from_country": "GN",
    "is_outbound": false,
    "service": "domestic_standard",
    "to_country": "TD",
    "total_costs": 703,
    "tracking_number": ""
  },
  {
    "carrier": "ferrari",
    "created": "2021-10-27T00:00:00",
    "currency": "USD",
    "from_country": "HK",
    "is_outbound": true,
    "service": "priority_ddp",
    "to_country": "LT",
    "total_costs": 633,
    "tracking_number": ""
  },
  {
    "carrier": "deutsche_post",
    "created": "2021-10-27T00:00:00",
    "currency": "KRW",
    "from_country": "UA",
    "is_outbound": true,
    "service": "domestic_standard",
    "to_country": "AR",
    "total_costs": 442,
    "tracking_number": ""
  },
  {
    "carrier": "usps_endicia",
    "created": "2021-10-28T00:00:00",
    "currency": "USD",
    "from_country": "SC",
    "is_outbound": false,
    "service": "white_glove",
    "to_country": "FM",
    "total_costs": 593,
    "tracking_number": ""
  },
  {
    "carrier": "australia_post",
    "created": "2021-10-28T00:00:00",
    "currency": "USD",
    "from_country": "EE",
    "is_outbound": true,
    "service": "air",
    "to_country": "HR",
    "total_costs": 90,
    "tracking_number": ""
  },
  {
    "carrier": "dpd",
    "created": "2021-10-28T00:00:00",
    "currency": "SGD",
    "from_country": "PL",
    "is_outbound": true,
    "service": "priority_ddu",
    "to_country": "CZ",
    "total_costs": 287,
    "tracking_number": ""
  }
]