<template>
  <div>
    <span
      v-for="filterItem in value"
      :key="filterItem">
      {{ formatFilterItem(filterItem) }}<br>
    </span>
  </div>
</template>

<script>
export default {
  name: "ReturnMethodFilters",
}
</script>

<script setup>
import { defineProps } from 'vue';

import countryMapping from '../../../assets/js/countries.json';

const fieldMapping = {
  return_from_country: 'Country',
  total_value: 'Value',
  total_weight: 'Weight',
};

const operatorMapping = {
  equals: '=',
  not_equals: '!=',
  greater_than: '>',
  less_than: '<',
  contains: 'contains',
  not_contains: 'does not contain',
  'in': 'in',
  not_in: 'not in',
};

defineProps({
  value: {
    type: Array,
    required: true,
  },
});

const formatFilterItem = ({ field, name, operator, value }) => {
  if (field === 'return_from_country' && (operator === 'in' || operator === 'not_in')) {
    const startPart = operator === 'in' ? 'One of' : 'Not one of';
    const countries = value.map(item => countryMapping[item]).join(', ');
    return `${startPart} the countries: ${countries}`;
  }
  let fieldValue = field === 'custom' ? name : fieldMapping[field];
  return `${fieldValue} ${operatorMapping[operator]} ${value}`
};
</script>
