<template>
  <div class="p-8 shadow border-b border-gray-200 bg-white sm:rounded-lg mt-6">
    <h3>
      Shipments by Carrier
      <badge
        v-if="showTopBadge"
        type="info">
        Top 6
      </badge>
    </h3>
    <div class="w-full overflow-x-scroll">
      <div
        :style="{ width: chartWidth + 'px' }"
        class="relative">
        <bar-chart
          :chart-data="data"
          :options="options" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentsCarrierChart',
}
</script>

<script setup>
import { defineProps, computed } from 'vue';
import { BarChart } from 'vue-chart-3';

import Badge from '../../atoms/Badge';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showTopBadge: {
    type: Boolean,
    default: true,
  },
});

const options = ({
  skipNull: true,
  parsing: true,
  // scaleShowValues: true,
  layout: {
    padding: 0,
  },
  datasets: {
    bar: {
      borderWidth: 3,
      borderColor: 'rgba(0, 0, 0, 0)',
      borderRadius: 100,
      barThickness: 15,
      borderSkipped: false,
      barShowStroke: false,
    },
  },
  plugins: {
    legend: {
      align: 'start',
      labels: {
        boxWidth: 11,
        usePointStyle: true,
        pointStyle: 'rectRounded',
        padding: 15,
      }
    },
    labels: {
      render: function (args) {
        if (args.value !== 0) return args.value;
      },
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        beginAtZero: true,
        autoSkip: false,
      }
    },
    y: {
      ticks: {
        min: 0,
        reverse: false,
        stepSize: 1,
        callback: (value) => {
          return value === 0 ? '' : value;
        },
      },
      grid: {
        drawBorder: false,
        color: (context) => {
          if (context.index === 0) return '#fff';
          return 'rgba(0, 0, 0, 0.1)';
        },
      }
    }
  },
});

const chartWidth = computed(() => {
  const defaultWidth = 700;
  const result = props.data.labels.length * 120;
  return result > defaultWidth ? result : defaultWidth;
});
</script>
