import { reactive } from 'vue';

import { showFailMessage, showSuccessMessage, withLoading } from "../utils";

export default function(service, formatItemName) {
  const data = reactive({
    loading: false,
    items: [],
    message: '',
    messageStatus: '',
  });

  if (!formatItemName) {
    formatItemName = value => value.name;
  }

  const read = async () => {
    if (!service.get) return;

    await withLoading(data, async () => {
      try {
        data.items = await service.get();
      } catch (e) {
        await showFailMessage(data, `${service.name} list wasn't loaded!`);
      }
    });
  };

  const create = async value => {
    if (!service.post) return;

    await withLoading(data, async () => {
      try {
        const newItem = await service.post(value);
        data.items.push(newItem);
        await showSuccessMessage(
          data, `New ${service.name.toLowerCase()} was created!`,
        );
      } catch (e) {
        await showFailMessage(
          data, `Creating the ${service.name.toLowerCase()} has failed. Please contact our support team.`,
        );
      }
    });
  }

  const update = async (value, refreshList) => {
    if (!service.put) return;

    refreshList = refreshList || false;

    await withLoading(data, async () => {
      try {
        const updatedItem = await service.put(value);

        for (let i = 0; i < data.items.length; ++i) {
          if (data.items[i].id === updatedItem.id) {
            data.items[i] = updatedItem;
            const name = formatItemName(updatedItem) || updatedItem.method_name;
            await showSuccessMessage(
              data, `${service.name} '${name}' was updated!`,
            );
            break;
          }
        }
      } catch (e) {
        await showFailMessage(
          data, `Editing the ${service.name.toLowerCase()} '${formatItemName(value)}' has failed. Please contact our support team.`,
        );
      }
    });

    if (refreshList) await read();
  };

  const remove = async itemId => {
    if (!service.delete) return;
    if (!confirm(`Do you really want to delete this ${service.name.toLowerCase()}?`)) return;

    await withLoading(data, async () => {
      let index, itemName;
      for (let i = 0; i < data.items.length; ++i) {
        if (data.items[i].id === itemId) {
          index = i;
          itemName = formatItemName(data.items[i]) || data.items[i].method_name;
          break;
        }
      }

      try {
        await service.delete(itemId);

        data.items.splice(index, 1);
        await showSuccessMessage(
          data, `${service.name} '${itemName}' was deleted!`,
        );
      } catch (e) {
        await showFailMessage(
          data, `Deleting the ${service.name.toLowerCase()} '${itemName}' has failed. Please contact our support team.`,
        );
      }
    });
  };

  return {
    data,
    read,
    create,
    update,
    remove,
  };
}
