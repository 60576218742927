<template>
  <SwitchGroup>
    <div class="flex items-center">
      <slot name="before">
        <SwitchLabel
          v-if="label"
          class="mr-4">
          {{ label }}
        </SwitchLabel>
      </slot>
      <Switch
        :value="value ? 'checked' : ''"
        :class="[value ? &quot;bg-blue-600&quot; : &quot;bg-gray-200&quot;, disabled ? &quot;cursor-not-allowed&quot; : &quot;&quot;]"
        class="relative inline-flex items-center h-6 transition-colors rounded-full w-11
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @update:modelValue="update">
        <span
          :class="value ? &quot;translate-x-6&quot; : &quot;translate-x-1&quot;"
          class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
      </Switch>
      <slot name="after" />
    </div>
  </SwitchGroup>
</template>

<script>
export default {
  name: "Switch",
}
</script>

<script setup>
import { defineProps, defineEmits } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(['update:value']);

const update = () => {
  if (props.disabled) return;
  emit('update:value', !props.value);
};
</script>
