<template>
  <TransitionRoot
    :show="value"
    as="template">
    <div class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            @click="onClose" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden md:inline-block md:align-middle md:h-screen"
          aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          enter-to="opacity-100 translate-y-0 md:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 md:scale-100"
          leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
            overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle
            lg:w-8/12 md:w-10/12 md:p-6">
            <div class="hidden md:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                @click="onClose">
                <span class="sr-only">Close</span>
                <XIcon
                  class="h-6 w-6"
                  aria-hidden="true" />
              </button>
            </div>

            <v-form
              v-if="data.hasValidation && validation"
              v-slot="{ errors }"
              :validation-schema="validation"
              @submit="onSubmit">
              <div class="md:flex md:items-start">
                <div class="mt-3 w-full md:mt-0 md:text-left">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <slot name="title" />
                  </h3>
                  <div class="mt-2">
                    <slot
                      name="body"
                      :errors="errors" />
                  </div>
                </div>
              </div>
              <slot name="footer" />
            </v-form>
            <template v-else>
              <div class="md:flex md:items-start w-full">
                <div class="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <slot name="title" />
                  </h3>
                  <div class="mt-2">
                    <slot name="body" />
                  </div>
                </div>
              </div>
              <slot name="footer" />
            </template>
          </div>
        </TransitionChild>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
export default {
  name: 'Modal',
}
</script>

<script setup>
import { defineProps, defineEmits, nextTick, reactive } from 'vue';
import {
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { Form as VForm } from 'vee-validate';

defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  // size: {
  //   type: String,
  //   validator: function (value) {
  //     return ['xsmall', 'small', 'medium', 'large', 'xlarge', 'huge'].indexOf(value) !== -1;
  //   },
  // },
  validation: {
    type: Object,
    default: () => ({}),
  }
});

const data = reactive({
  hasValidation: true,
})

const emit = defineEmits(['submit', 'close']);

const onSubmit = async (value, { resetForm }) => {
  data.hasValidation = false;
  await nextTick(() => {
    emit('submit', value);
    resetForm();
    data.hasValidation = true;
  });
};

const onClose = async () => {
  data.hasValidation = false;
  await nextTick(() => {
    emit('close');
    data.hasValidation = true;
  });
}
</script>
