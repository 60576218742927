<template>
  <div class="h-screen flex">
    <div class="w-2/4 flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <div class="w-16 h-16 bg-indigo-700 flex justify-center items-center rounded-md">
            <img
              class="h-12 w-auto"
              src="../../../assets/img/logo-login.svg"
              alt="Dashboard's Logo">
          </div>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div class="mt-8">
          <div>
            <p class="text-sm font-medium text-gray-700">
              Sign in with
            </p>

            <div class="mt-1">
              <router-link
                :to="{ name: 'login-mfa' }"
                class="w-full flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with Email</span>
                <mail-icon class="w-6 h-6" />
              </router-link>
            </div>
          </div>

          <div class="mt-6 relative">
            <div
              class="absolute inset-0 flex items-center"
              aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-gray-100 text-gray-500"> Or continue with </span>
            </div>
          </div>

          <div class="mt-6">
            <v-form
              :validation-schema="schema"
              @submit="signIn">
              <slot name="message" />
              <z-input
                id="email"
                :value="email"
                :timeout="0"
                class="my-5"
                type="email"
                @update:value="updateEmail">
                <template #label>
                  Email address
                </template>
              </z-input>
              <z-input
                id="password"
                :value="password"
                :timeout="0"
                class="my-5"
                type="password"
                @update:value="updatePassword">
                <template #label>
                  Password
                </template>
              </z-input>

              <div class="my-4 flex items-center justify-between">
                <div class="w-1/3">
                  <toggle
                    :value="apiBaseUrl"
                    :left-value="usApiBaseUrl"
                    :right-value="euApiBaseUrl"
                    left-label="US"
                    right-label="EU"
                    @update:value="changeRegion" />
                </div>
                <div class="text-sm">
                  <router-link
                    :to="{ name: 'forgot-password' }"
                    class="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </router-link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
                  text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Sign in
                </button>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <div class="w-2/4 hidden lg:block relative w-0 flex-1 text-white">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicLoginTemplate',
};
</script>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { Form as VForm } from 'vee-validate';
import { string } from 'yup';
import { MailIcon } from '@heroicons/vue/solid';

import ZInput from '../../atoms/Input';
import Toggle from '../../atoms/Toggle';

const store = useStore();

defineProps({
  email: {
    type: String,
    required: true,
  },
  password: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['sign-in', 'update:email', 'update:password']);

const schema = {
  email: string().email().required().label('Email'),
  password: string().min(8).required().label('Password'),
};

const usApiBaseUrl = process.env.VUE_APP_ZENKRAFT_US_BASE_URL;
const euApiBaseUrl = process.env.VUE_APP_ZENKRAFT_EU_BASE_URL;

const apiBaseUrl = computed(() => store.state.apiBaseUrl);

const changeRegion = (value) => store.commit('CHANGE_REGION', value);

const signIn = () => emit('sign-in');
const updateEmail = value => emit('update:email', value);
const updatePassword = value => emit('update:password', value);
</script>
