<template>
  <log-details-template
    :loading="page.data.loading"
    :value="data.details">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </log-details-template>
</template>

<script>
export default {
  name: 'LogDetails',
};
</script>

<script setup>
import { defineProps, onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import LogDetailsTemplate from '../../templates/LogDetails';

import usePage from "../../../mixins/usePage";
import { LogDetailsService } from '../../../services';
import {showFailMessage, withLoading} from "../../../utils";

const page = usePage();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const data = reactive({
  details: {},
});

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      data.details = await LogDetailsService.get(props.id);
    } catch (e) {
      await showFailMessage(page.data, `${LogDetailsService.name} weren't loaded!`);
    }
  });
});
</script>
