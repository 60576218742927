<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="w-16 h-16 m-auto bg-indigo-700 flex justify-center items-center rounded-md">
        <img
          class="h-12 w-auto"
          src="../../../assets/img/logo-login.svg"
          alt="Dashboard's Logo">
      </div>
      <h2
        v-if="$slots.title"
        class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        <slot name="title" />
      </h2>
      <p
        v-if="$slots.subtitle"
        class="mt-2 text-center text-sm text-gray-600">
        <slot name="subtitle" />
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicCredentials',
};
</script>
