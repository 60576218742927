<template>
  <div class="relative h-full -mx-6 -mt-6">
    <div class="flex items-center gap-3 p-2 pl-5 shadow border-b border-gray-200 bg-white">
      <z-input
        :value="filters.tracking_number"
        :timeout="1500"
        class="w-1/2"
        placeholder="Filter by tracking number"
        @update:value="updateFilters({ tracking_number: $event })">
        <template #start-icon>
          <SearchIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true" />
        </template>
      </z-input>
      <z-select
        id="type"
        :value="filters.type"
        :options="typeOptions"
        class="w-1/4"
        placeholder="Filter by type"
        nullable
        @update:value="updateFilters({ type: $event })" />
    </div>

    <slot name="message" />

    <z-table
      :loading="loading"
      :fields="fields"
      :data="data"
      class="mb-2 mt-6 mx-6">
      <template #cell(carrier)="{ row }">
        {{ carriers ? carrierNames[row.carrier] || row.carrier : row.carrier }}
      </template>
      <template #cell(tracking_number)="{ row }">
        <router-link
          :to="{ name: 'shipment-details', params: { id: row.id } }"
          class="text-indigo-400 hover:text-indigo-600">
          {{ row.tracking_number }}
        </router-link>
      </template>
      <template #cell(created)="{ row }">
        {{ format(new Date(row.created), 'MM/dd/yyyy') }}
      </template>
      <template #cell(is_return)="{ row }">
        <CheckIcon
          v-if="row.is_return"
          class="h-5 w-5 m-auto"
          aria-hidden="true" />
        <span v-else />
      </template>
      <template #footer>
        <button-pagination
          :page="page"
          :last-page="lastPage"
          @prev="prev"
          @next="next" />
      </template>
    </z-table>
  </div>
</template>

<script>
export default {
  name: 'ShipmentsTemplate',
};
</script>

<script setup>
import format from "date-fns/format";
import { defineProps, defineEmits, computed } from "vue";
import {
  SearchIcon,
  CheckIcon,
} from "@heroicons/vue/outline";

import ZTable from '../../organisms/Table';
import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import ButtonPagination from '../../molecules/ButtonPagination';

const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
  filters: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  page: {
    type: Number,
    required: true,
  },
  lastPage: {
    type: Boolean,
    default: false,
  },
  carriers: {
    type: Array,
    default: () => ([])
  },
});

const typeOptions = [
  {value: 'outbound', label: 'outbound'},
  {value: 'return', label: 'return'},
];

const fields = [
  {key: "tracking_number", label: "Tracking Number"},
  {key: "reference_number", label: "Reference Number"},
  "carrier",
  "created",
  "status",
  {key: "is_return", label: "Is Return"},
];

const emit = defineEmits(['prev', 'next', 'update:filters']);

const updateFilters = (value) => {
  emit('update:filters', {
    ...props.filters,
    ...value,
  })
};

const carrierNames = computed(() => {
  const result = {};

  props.carriers.forEach(carrier => {
    result[carrier.slug] = (
      carrier.display_name.trim()
      || (carrier.slug[0].toUpperCase() + carrier.slug.substr(1))
    );
  });

  return result;
});

const prev = value => emit('prev', value);
const next = value => emit('next', value);
</script>
