<template>
  <home-template
    :loading="page.data.loading"
    :api-key="data.apiKey"
    :stats="data.stats"
    :logs="data.logs"
    :page="data.page"
    :last-page="data.isLastPage"
    @prev="loadLogs"
    @next="loadLogs">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </home-template>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<script setup>
import { onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import HomeTemplate from '../../templates/Home';

import usePage from "../../../mixins/usePage";
import {showFailMessage, withLoading} from "../../../utils";
import client from "../../../api";

const page = usePage();

const data = reactive({
  page: 1,
  pageSize: 20,
  isLastPage: false,
  apiKey: "",
  stats: [
    { title: "24h Shipments", subtitle: "total Shipments", value: 0 },
    { title: "7 Day Shipments", subtitle: "total Shipments", value: 0 },
    { title: "28 Day Shipments", subtitle: "total Shipments", value: 0 },
  ],
  logs: [],
});

const loadUser = async () => {
  try {
    const { data: { api_key: apiKey, total_1_day, total_7_days, total_28_days } } = await client.getUser();
    data.apiKey = apiKey;
    data.stats[0].value = total_1_day;
    data.stats[1].value = total_7_days;
    data.stats[2].value = total_28_days;
  } catch(e) {
    await showFailMessage(page.data, "User details weren't loaded!");
  }
};

const loadLogs = async pageNum => {
  if (pageNum) data.page = pageNum;

  pageNum = pageNum || data.page;
  const { pageSize } = data;

  await withLoading(page.data, async () => {
    try {
      const {data: logs} = await client.getLogs({ page: pageNum, page_size: pageSize });
      data.isLastPage = !logs.length;

      if (!data.isLastPage || data.page === 1) {
        data.logs = logs;
      } else {
        data.page -= 1;
      }
    } catch (e) {
      await showFailMessage(page.data, "Logs weren't loaded!");
    }
  });
};

onMounted(async () => {
  await withLoading(page.data, async () => {
    await loadUser();
    await loadLogs();
  });
});
</script>
