import client from "../api";


class LocationService {
  name = 'Location';

  async get() {
    const { data: { locations } } = await client.getLocations();
    return locations;
  }

  async post(value) {
    const { data: { location } } = await client.postLocation({ location: value });
    return location;
  }

  async put(value) {
    const { id: locationId } = value;
    delete value.id;

    const { data: { location } } = await client.putLocation(
      locationId, { location: value },
    );
    return location;
  }
}

export default new LocationService;
