<template>
  <modal
    :value="isShown"
    :validation="schema"
    size="huge"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <slot name="title" />
    </template>
    <template
      v-if="localValue"
      #body>
      <div :class="localValue.service ? '' : 'h-48'">
        <z-select
          id="service"
          v-model:value="localValue.service"
          :options="serviceOptions"
          :disabled="!isCreateForm"
          class="mb-4">
          <template #label>
            Integration
          </template>
        </z-select>
        <template v-if="localValue.service">
          <salesforce-core-form
            v-if="localValue.service === '1' || localValue.service === 1"
            v-model:domain="localValue[localValue.service].custom_domain"
            v-model:environment="localValue[localValue.service].environment" />
          <salesforce-commerce-cloud-form
            v-else-if="localValue.service === '2' || localValue.service === 2"
            v-model:cloud-url="localValue[localValue.service].url" />
          <newstore-form
            v-else-if="localValue.service === '8' || localValue.service === 8"
            :id="value.id || ''"
            v-model:name="localValue[localValue.service].name"
            v-model:shipping_account_key="localValue[localValue.service].shipping_account_key"
            :shipping_account_id="localValue[localValue.service].shipping_account_id"
            :shipping-accounts="shippingAccounts"
            :shipping-account-blacklist="shippingAccountBlacklist[8]" />
          <ftp-form
            v-else-if="localValue.service === '9' || localValue.service === 9"
            v-model:name="localValue[localValue.service].name"
            v-model:shipping_account_id="localValue[localValue.service].shipping_account_id"
            v-model:ftp_username="localValue[localValue.service].ftp_username"
            :ftp_password="localValue[localValue.service].ftp_password"
            :ftp_key="localValue[localValue.service].ftp_key"
            :shipping-accounts="shippingAccounts"
            :shipping-account-blacklist="shippingAccountBlacklist[9]"
            :allowed-carriers="allowedFtpCarriers"
            @update:ftp_password="updateFtpPassword"
            @update:ftp_key="updateFtpKey" />
          <quantum-view-form
            v-else-if="localValue.service === '10' || localValue.service === 10"
            :id="value.id || ''"
            v-model:name="localValue[localValue.service].name"
            v-model:shipping_account_id="localValue[localValue.service].shipping_account_id"
            :shipping-accounts="shippingAccounts"
            :shipping-account-blacklist="shippingAccountBlacklist[10]" />
        </template>
      </div>
    </template>
    <template #footer>
      <z-button
        type="success"
        full-width
        submit>
        <slot name="send-button-label" />
      </z-button>
    </template>
  </modal>
</template>

<script>
import Modal from '../../organisms/Modal';

export default {
  name: 'IntegrationModal',

  components: {
    Modal,
  },
};
</script>

<script setup>
import { computed, defineProps, defineEmits, nextTick, reactive, watch } from 'vue';
import { number, object, string } from 'yup';

import ZSelect from '../../atoms/Select';
import ZButton from '../../atoms/Button';
import SalesforceCoreForm from "../SalesforceCoreForm";
import SalesforceCommerceCloudForm from "../SalesforceCommerceCloudForm";
import NewstoreForm from '../NewstoreForm';
import FtpForm from '../FtpForm';
import QuantumViewForm from '../QuantumViewForm';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  serviceOptions: {
    type: Array,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    required: true,
  },
  shippingAccountBlacklist: {
    type: Object,
    required: true,
  },
  isShown: {
    type: Boolean,
    default: false,
  },
  isCreateForm: {
    type: Boolean,
    default: true,
  },
  allowedFtpCarriers: {
    type: Array,
    default: () => ([]),
  },
});

const initLocalValue = () => ({
  service: null,
  1: {
    environment: '',
    custom_domain: '',
  },
  2: {
    url: '',
  },
  8: {
    name: '',
    shipping_account_id: '',
    shipping_account_key: '',
  },
  9: {
    name: '',
    ftp_username: '',
    ftp_password: '',
    ftp_key: '',
    shipping_account_id: '',
  },
  10: {
    name: '',
    shipping_account_id: '',
  },
});
const localValue = reactive({ ...initLocalValue(), ...props.value });

watch(
  () => props.value,
  value => {
    Object.assign(localValue, {
      ...initLocalValue(),
      ...value,
    });

    for (let service in localValue) {
      for (let field in localValue[service]) {
        if (field in value) {
          localValue[service][field] = value[field];
        }
      }
    }
  }
);

const schemaMapping = {
  null: {
    service: number().nullable().required().label('Integration'),
  },
  1: {
    service: number().nullable().required().label('Integration'),
    environment: string().required().label('Environment'),
    custom_domain: string().url().label('Custom domain'),
  },
  2: {
    service: number().nullable().required().label('Integration'),
    url: string().url().required().label('Cloud URL'),
  },
  8: {
    service: number().nullable().required().label('Integration'),
    name: string().required().label('Name'),
    shipping_account_id: string().required().label('Shipping Account'),
  },
  10: {
    service: number().nullable().required().label('Integration'),
    name: string().required().label('Name'),
    shipping_account_id: string().required().label('Shipping Account'),
  },
  11: {
    service: number().nullable().required().label('Integration'),
    shop_url:
      string()
        .url()
        .matches(/[^.\s]+\.myshopify\.com/, 'URL must have the format "store-name.myshopify.com"')
        .label('Shop URL'),
  },
}

const ftpSchema = computed(() => ({
  service: number().nullable().required().label('Integration'),
  name: string().required().label('Name'),
  shipping_account_id: string().required().label('Shipping Account'),
  ftp_username: string().required().label('FTP username'),
  ftp_password: string()
    .test(
      'notBothAtTheSameTime',
      'You cannot pass FTP password at the same time FTP key',
      function(ftpPassword) {
        const { ftp_key: ftpKey } = this.parent;
        return !(ftpKey && ftpPassword);
      }
    )
    .when(['ftp_key'], {
      is: ftpKey => !ftpKey,
      then: props.value.ftp_key
        ? string()
        : string().required('FTP password is required'),
    }),
  ftp_key: string()
    .test(
      'notBothAtTheSameTime',
      'You cannot pass FTP password at the same time FTP key',
      function(ftpKey) {
        const { ftp_password: ftpPassword } = this.parent;
        return !(ftpKey && ftpPassword);
      }
    )
    .when(['ftp_password'], {
      is: ftpPassword => !ftpPassword,
      then: props.value.ftp_key
        ? string()
        : string().required('FTP key is required'),
    }),
}));

const schema = computed(() => {
  // It doesn't make sense to redefine all schemas on every change of this computed.
  // Therefore FTP schema was moved to the separate computed value and added to the
  // static schema mapping.
  // TODO: if you get a better variant without mutation inside computed value, refactor it please.
  // Only check the case that in the edit mode FTP password and key fields are optional
  // when something is in the ftp key field.
  schemaMapping[9] = ftpSchema.value; // eslint-disable-line vue/no-side-effects-in-computed-properties
  const { service } = localValue;
  return service === '9' || service === 9
    ? object().shape(schemaMapping[service], [['ftp_password', 'ftp_key']])
    : object(schemaMapping[service]);
});

const emit = defineEmits(['close', 'send']);

const resetLocalValue = () => Object.assign(localValue, initLocalValue());

const closeModal = async () => {
  resetLocalValue();
  await nextTick();
  emit('close');
};

const submit = async () => {
  const value = {
    service: localValue.service,
    ...localValue[localValue.service],
  };
  if (props.value && props.value.id) {
    value.id = props.value.id;
  }
  if (!value.ftp_password) delete value.ftp_password;
  if (!value.ftp_key) delete value.ftp_key;
  if (value.shipping_account_key) {
    value.shipping_account_id = value.shipping_account_key;
    delete value.shipping_account_key;
  }

  resetLocalValue();
  await nextTick();
  emit('send', value);
};

const updateFtpPassword = value => {
  localValue[localValue.service].ftp_password = value;
  localValue[localValue.service].ftp_key = '';
}

const updateFtpKey = value => {
  localValue[localValue.service].ftp_key = value;
  localValue[localValue.service].ftp_password = '';
}
</script>
