import client from "../api";


class ReturnDetailsService {
  name = 'Return details';

  async get(id) {
    const { data } = await client.getReturnItem(id);
    return data;
  }
}

export default new ReturnDetailsService;
