<template>
  <fieldset>
    <div class="mt-1 -space-y-px rounded-md">
      <v-form
        :validation-schema="schema"
        class="flex -space-x-px">
        <div class="min-w-0 flex-1">
          <z-input
            id="customKey"
            :value="data.key"
            input-class="rounded-r-none"
            error-class="text-xs absolute"
            placeholder="Custom key"
            @update:value="updateKey" />
        </div>
        <div class="min-w-0 flex-1">
          <z-input
            id="customValue"
            :value="data.value"
            :title="explanation"
            input-class="rounded-l-none"
            error-class="text-xs absolute"
            placeholder="Custom value"
            @update:value="updateValue">
            <template #end-icon>
              <InformationCircleIcon
                class="h-5 w-5 text-gray-400 cursor-pointer"
                aria-hidden="true" />
            </template>
          </z-input>
        </div>
        <button
          v-show="false"
          ref="submitRef"
          type="submit" />
      </v-form>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'CustomPairFilter',
}
</script>

<script setup>
import { computed, defineProps, defineEmits, reactive, ref, watchEffect } from 'vue';
import { InformationCircleIcon } from "@heroicons/vue/solid";
import { Form as VForm } from 'vee-validate';
import { string, object } from 'yup';

import ZInput from '../../atoms/Input';
import { createDebounce } from "../../../utils";


const DELIMITER = '::';

const debounce = createDebounce();

const schema = object({
  customKey: string().when('customValue',{
    is: val => !!val,
    then: string().required().label('Custom Key'),
    otherwise: () => string(),
  }),
  customValue: string().label('Custom Value'),
});

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  timeout: {
    type: Number,
    default: 500,
  },
});

const explanation = 'You can use values like 123, "123", true, false';

const data = reactive({
  key: '',
  value: '',
});

const submitRef = ref(null);

const emit = defineEmits(['update:value']);

watchEffect(() => {
  if (!props.value) return;
  if (props.value.indexOf(DELIMITER) === -1) {
    data.key = props.value;
    return;
  }
  const parts = props.value.split(DELIMITER, 2);
  data.key = parts[0];
  data.value = parts[1];
});

const isUpdateAllowed = computed(() => data.key || (!data.key && !data.value));

const updateKey = value => {
  data.key = value;
  if (isUpdateAllowed.value) update();
};

const updateValue = value => {
  data.value = value;
  // Submit button was used because currently the other variant to trigger validation
  // on the neighbor field was not found.
  if (value && !data.key) submitRef.value.click();
  if (isUpdateAllowed.value) update();
}

const update = () => debounce(
  () => emit('update:value', data.key ? [data.key, data.value].join(DELIMITER) : ''),
  props.timeout,
);
</script>
