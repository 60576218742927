<template>
  <logs-template
    v-model:filters="filters"
    :loading="loading"
    :data="logs"
    :page="page"
    :last-page="isLastPage"
    @prev="loadLogs"
    @next="loadLogs"
    @update:filters="loadLogs(1)">
    <template #message>
      <div class="u-ph-small">
        <alert
          v-if="message"
          :type="messageStatus"
          :close="true"
          @close="message = ''">
          {{ message }}
        </alert>
      </div>
    </template>
  </logs-template>
</template>

<script>
import Alert from '../../atoms/Alert';
import LogsTemplate from '../../templates/Logs';
import client from '../../../api';
import { showFailMessage, withLoading } from "../../../utils";

export default {
  name: 'LogsPage',

  components: {
    Alert,
    LogsTemplate,
  },

  data() {
    return {
      loading: false,
      message: "",
      messageStatus: "",
      page: 1,
      pageSize: 20,
      isLastPage: false,
      logs: [],
      filters: {
        start_date: null,
        end_date: null,
        status_code: "",
        method: "",
        url: "",
        ip: "",
      },
    };
  },

  async created() {
    await this.loadLogs();
  },

  methods: {
    async loadLogs(page) {
      if (page) this.page = page;

      page = page || this.page;
      const { pageSize } = this;

      const filters = Object.assign({}, this.filters);
      if (filters.start_date) {
        filters.start_date = filters.start_date.toISOString().replace('Z', '');
      }
      if (filters.end_date) {
        filters.end_date = filters.end_date.toISOString().replace('Z', '');
      }

      await withLoading(this, async () => {
        try {
          const { data: logs } = await client.getLogs({ page, page_size: pageSize, ...filters });
          this.isLastPage = !logs.length;

          if (!this.isLastPage || this.page === 1) {
            this.logs = logs;
          } else {
            this.page -= 1;
          }
        } catch (e) {
          await showFailMessage(this, "Logs weren't loaded!");
        }
      });
    },
  }
};
</script>
