<template>
  <div>
    <z-select
      id="environment"
      :value="environment"
      :options="environmentOptions"
      class="mb-4"
      @update:value="updateEnvironment">
      <template #label>
        Are you testing in Sandbox or going live with Production?
      </template>
    </z-select>
    <url-input
      id="custom_domain"
      :value="domain"
      class="mb-4"
      placeholder="your-domain.my.salesforce.com"
      @update:value="updateDomain">
      <template #label>
        Are you using a custom domain?
      </template>
    </url-input>
  </div>
</template>

<script>
export default {
  name: 'SalesforceCoreForm',
};
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

import ZSelect from '../../atoms/Select';
import UrlInput from '../../atoms/UrlInput';

const environmentOptions = [
  {value: 'sandbox', label: 'Sandbox'},
  {value: 'production', label: 'Production'},
]

defineProps({
  environment: {
    type: String,
    required: true,
  },
  domain: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:environment', 'update:domain']);

const updateEnvironment = value => emit('update:environment', value);
const updateDomain = value => emit('update:domain', value);
</script>
