<template>
  <locations-template
    :loading="page.data.loading"
    :locations="page.data.items"
    @create="page.create"
    @update="page.update">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </locations-template>
</template>

<script>
export default {
  name: 'Locations',
};
</script>

<script setup>
import { onMounted } from 'vue';

import Alert from '../../atoms/Alert';
import LocationsTemplate from '../../templates/Locations';

import usePage from "../../../mixins/usePage";
import { LocationService } from '../../../services';

const page = usePage(LocationService);

onMounted(async () => {
  await page.read();
});
</script>
