export const ADDRESS_COMPONENTS = {
  subpremise : 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'short_name',
  postal_code: 'short_name'
};

/*
  By default, we're only including basic place data because requesting these
  fields place data is not additionally charged by Google. Please refer to:

  https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
*/
export const BASIC_DATA_FIELDS = [
  'address_components', 'adr_address', 'alt_id',
  'formatted_address', 'geometry', 'icon', 'id', 'name',
  'business_status', 'photo', 'place_id', 'scope', 'type',
  'url', 'utc_offset_minutes', 'vicinity',
];
