<template>
  <public-login-mfa-confirm-template
    v-model:password="data.password"
    :email="email"
    :error="data.error"
    @sign-in="signIn"
    @alert-close="data.error = ''" />
</template>

<script>
export default {
  name: 'PublicLoginMfa',
};
</script>

<script setup>
import { defineProps, reactive } from 'vue';
import { useStore } from "vuex";

import PublicLoginMfaConfirmTemplate from '../../templates/PublicLoginMfaConfirm';

const store = useStore();

const props = defineProps({
  email: {
    type: String,
    required: true,
  }
});

const data = reactive({
  password: "",
  error: "",
});

const signIn = async () => {
  const email = props.email;
  const password = data.password;
  data.error = '';

  try {
    await store.dispatch('login', { email, password });
  } catch(e) {
    try {
      const { response: { data: { error: { message } } } } = e;
      data.error = message;
    } catch {
      data.error = 'Unknown error message from the server. Please contact our support team.'
    }
  }
};
</script>
