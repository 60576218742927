<template>
  <div class="p-3 pt-4 pr-5 mb-8 shadow border-b border-gray-200 bg-white sm:rounded-lg mt-6">
    <h3 class="pl-4">
      Shipments by Service
      <badge
        v-if="showTopBadge"
        type="info">
        Top 6
      </badge>
    </h3>
    <bar-chart
      :chart-data="data"
      :options="options" />
  </div>
</template>

<script>
export default {
  name: 'ShipmentsServiceChart',
}
</script>

<script setup>
import { defineProps } from 'vue';
import { BarChart } from 'vue-chart-3';

import Badge from '../../atoms/Badge';

defineProps({
  data: {
    type: Object,
    required: true,
  },
  showTopBadge: {
    type: Boolean,
    default: true,
  },
});

const options = {
  responsive: true,
  indexAxis: 'y',
  datasets: {
    bar: {
      borderWidth: 1,
      borderRadius: 100,
      barThickness: 15,
      categoryPercentage: 0.4,
      borderSkipped: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      }
    },
    y: {
      ticks: {
        mirror: true,
        labelOffset: -20
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  }
};
</script>
