<template>
  <users-template
    :loading="page.data.loading"
    :data="page.data.items"
    @update="updateUser">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </users-template>
</template>

<script>
export default {
  name: 'Users',
};
</script>

<script setup>
import { onMounted } from 'vue';

import Alert from '../../atoms/Alert';
import UsersTemplate from '../../templates/Users';

import usePage from "../../../mixins/usePage";
import { UserService } from "../../../services";
import { showFailMessage, withLoading } from "../../../utils";
import client from "../../../api";

const page = usePage(UserService);

onMounted(async () => {
  await page.read();
});

const updateUser = async value => {
  await withLoading(page.data, async () => {
    try {
      const { data: updatedUser } = await client.putUser(value);
      page.data.items[0].mfa = updatedUser.mfa;
    } catch (e) {
      await showFailMessage(page.data, `User details weren't updated!`);
    }
  });
}
</script>
