import MockAdapter from "axios-mock-adapter";
import axios from "axios";

import serviceTypes from "./stubs/serviceTypes.json";
import shipmentEvents from "./stubs/shipmentEvents";
import packagingTypes from "./stubs/packagingTypes.json";
import labelTypes from "./stubs/labelTypes.json";
import shipments from './stubs/shipments.json';
import shipmentDetails from "./stubs/shipmentDetails.json";
import returns from './stubs/returns.json';
import returnDetails from "./stubs/returnDetails.json";


export default () => {
    const BASE_URL = process.env.VUE_APP_ZENKRAFT_US_BASE_URL;
    const CDN_BASE_URL = process.env.VUE_APP_ZENKRAFT_US_BASE_URL;
    const mock = new MockAdapter(axios, { delayResponse: 1000 });

    mock.onGet(`${BASE_URL}/user`).reply(
        200,
        {
            "name": "John",
            "company": "Zenkraft",
            "email": "john@zenkraft.com",
            "total_1_day": 2.323,
            "total_7_days": 15.657,
            "total_28_days": 3.234,
            "api_key": "api_key",
            "created": "2021-08-23T16:10:44.637Z",
            "mfa": true,
        }
    );
    mock.onPut(`${BASE_URL}/user`).reply(({ data }) => {
        return [200, JSON.parse(data)];
    });
    mock.onPost(`${BASE_URL}/user/login`).reply(
        200,
        {
            "name": "John",
            "company": "Zenkraft",
            "email": "john@zenkraft.com",
            "total_1_day": 2.323,
            "total_7_days": 15.657,
            "total_28_days": 3.234,
            "api_key": "api_key",
            "created": "2021-08-23T16:10:44.637Z"
        },
    );
    mock.onPost(`${BASE_URL}/user/login/mfa`).reply(200);
    mock.onPost(`${BASE_URL}/user/forgot_password`).reply(200);
    mock.onGet(`${BASE_URL}/logs`).reply(
        200,
        [
            {
                id: "1",
                created: '2021-08-23T16:10:44.654Z',
                status_code: 400,
                url: '/track',
                ip_address: '10.148.121.8',
            },
            {
                id: "2",
                created: '2021-08-23T16:10:44.654Z',
                status_code: 400,
                url: '/track',
                ip_address: '10.148.121.8',
            },
            {
                id: "3",
                created: '2021-08-23T16:10:44.654Z',
                status_code: 400,
                url: '/track',
                ip_address: '10.148.121.8',
            },
            {
                id: "4",
                created: '2021-08-23T16:10:44.654Z',
                status_code: 400,
                url: '/track',
                ip_address: '10.148.121.8',
            },
        ],
    );
    mock.onGet(new RegExp(BASE_URL + "/logs/\\d+")).reply(
        200,
        {
            id: "9fe2c4e93f654fdbb24c02b15259716c",
            url: "/track",
            ip_address: "188.167.254.246",
            status_code: 400,
            created: "2021-08-29T16:10:44.654Z",
            http_method: "POST",
            content_type: "application/json",
            user_agent: "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36" +
                " (KHTML, like Gecko) Chrome/93.0.4577.63 Safari/537.36",
            request: {
                "track": {
                    "carrier": "ups",
                    "debug": true,
                    "shipping_account": 581498691,
                    "test": false,
                    "tracking_number": "949535554773"
                }
            },
            response: {
                "error": {
                    "code": "api_exception",
                    "detail": null,
                    "message": "The shipping account details are for fedex not ups"
                }
            },
        },
    );
    mock.onPost(`${BASE_URL}/track`).reply(
        200,
        {
            "carrier": "fedex",
            "delivered": true,
            "estimated_delivery": "2021-06-07T00:00:00.000Z",
            "delivery_date": "2021-06-07T00:00:00.000Z",
            "tracking_number": "567293923024",
            "status": "Delivered to a mailbox",
            "tracking_stage": "DELIVERED",
            "locale": "en_US",
            "checkpoints": [
                {
                    "description": "Delivered to a mailbox",
                    "status": "Delivered to a mailbox",
                    "tracking_stage": "DELIVERED",
                    "time": "2021-06-07T10:46:07.000+1000"
                }
            ]
        }
    );
    mock.onGet(`${BASE_URL}/shipments`).reply(({ params: { tracking_number } }) => {
        let result = shipments;
        if (tracking_number) {
            result = result.shipments.filter(item => tracking_number in item.tracking_number);
        }
        return [200, result];
    });
    mock.onGet(new RegExp(BASE_URL + "/shipments/\\w+")).reply(200, shipmentDetails);
    mock.onGet(`${BASE_URL}/shippingaccount`).reply(
        200,
        [
            {
                "shipping_account": {
                    "id": 5190163571408896,
                    "name": "ups 1",
                    "carrier": "ups",
                    "country": "CA",
                    "defaults": {
                        "currency": "Canadian dollar",
                        "dim_units": "IN",
                        "weight_units": "KG",
                    },
                    "auth": {
                        "username": "cdsc",
                        "password": "sdccs"
                    },
                    "test": false
                }
            },
            {
                "shipping_account": {
                    "id": 519016357140889226,
                    "name": "fedex_freight example",
                    "carrier": "fedex_freight",
                    "country": "GB",
                    "defaults": {
                        "currency": "Pound sterling",
                        "dim_units": "CM",
                        "weight_units": "LB",
                    },
                    "auth": {
                        "country": "US",
                        "account_entity": "22",
                        "account_number": "3333",
                        "account_pin": "123456",
                        "username": "username",
                        "password": "password",
                        "language_code": "EN"
                    },
                    "test": false
                }
            },
            {
                "shipping_account": {
                    "id": 519016357140889221,
                    "name": "example 3",
                    "carrier": "agistix",
                    "country": "GB",
                    "defaults": {
                        "currency": "Pound sterling",
                        "dim_units": "CM",
                        "weight_units": "LB",
                    },
                    "auth": {},
                    "test": true,
                    "location_id": "484fe753dcda47a1b817456f3a135432",
                }
            },
            {
                "shipping_account": {
                    "id": 5190163571408893331,
                    "name": "echo_logistics example",
                    "carrier": "echo_logistics",
                    "country": "GB",
                    "defaults": {
                        "currency": "Pound sterling",
                        "dim_units": "CM",
                        "weight_units": "LB",
                    },
                    "auth": {},
                    "test": true,
                }
            },
        ],
    );
    mock.onPost(`${BASE_URL}/shippingaccount`)
        .reply(({ data }) => {
            const shipping_account = {
                shipping_account: {
                    id: String(+new Date()),
                    ...JSON.parse(data).shipping_account,
                }
            }
            return [200, shipping_account];
        });
    mock.onPut(new RegExp(BASE_URL + "/shippingaccount/\\w+"))
        .reply(({ data }) => [200, data]);
    mock.onDelete(new RegExp(BASE_URL + "/shippingaccount/\\d+")).reply(204);
    mock.onGet(
      CDN_BASE_URL + "/meta/carriers",
      {
          params: {
              fields: "auth,name,display_name,description,short_description," +
                "private,has_master_account,master_account_countries"
          },
      }
    ).reply(200, [
        {
            "auth": [
                {
                    "name": "Username",
                    "required": true,
                    "slug": "username",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Password",
                    "required": true,
                    "slug": "password",
                    "type": "text",
                    "ui": true
                },
                {
                    "collection": [
                        {
                            "code": "us1",
                            "name": "US1",
                            "slug": "us1"
                        },
                        {
                            "code": "us2",
                            "name": "US2",
                            "slug": "us2"
                        },
                        {
                            "code": "eu1",
                            "name": "EU1",
                            "slug": "eu1"
                        },
                        {
                            "code": "eu2",
                            "name": "EU2",
                            "slug": "eu2"
                        }
                    ],
                    "name": "Region",
                    "required": true,
                    "slug": "region",
                    "type": "collection",
                    "ui": true
                }
            ],
            "description": "",
            "display_name": "B.DuiePYLE",
            "has_master_account": true,
            "master_account_countries": [
                "US"
            ],
            "private": false,
            "short_description": "",
            "slug": "aduiepyle"
        },
        {
            "auth": [
                {
                    "name": "Client ID",
                    "required": true,
                    "slug": "client_id",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "API Key",
                    "required": true,
                    "slug": "api_key",
                    "type": "text",
                    "ui": true
                }
            ],
            "description": "",
            "display_name": "Agistix",
            "has_master_account": false,
            "master_account_countries": [],
            "private": false,
            "short_description": "",
            "slug": "agistix"
        },
        {
            "auth": [
                {
                    "name": "User Name",
                    "required": true,
                    "slug": "username",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Password",
                    "required": true,
                    "slug": "password",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Account Number",
                    "required": true,
                    "slug": "account_number",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "API Key",
                    "required": true,
                    "slug": "api_key",
                    "type": "text",
                    "ui": false
                }
            ],
            "description": "Founded in 2001, APC Postal Logistics is a leading consolidator and distributor of international parcels and mail, providing a full range of shipping and mailing solutions to volume mailers and e-commerce clients.",
            "display_name": "APC Logistics",
            "has_master_account": false,
            "master_account_countries": [],
            "private": false,
            "short_description": "Founded in 2001, APC Postal Logistics is a leading consolidator and distributor of international parcels and mail, providing a full range of shipping and mailing solutions to volume mailers and e-commerce clients.",
            "slug": "apc_logistics"
        },
        {
            "auth": {},
            "description": null,
            "display_name": "APC Overnight",
            "has_master_account": false,
            "master_account_countries": [],
            "private": false,
            "short_description": "Supports almost all functionality via direct API integration including rate, track and shipping",
            "slug": "apc_overnight"
        },
        {
            "auth": {},
            "description": "",
            "display_name": "",
            "has_master_account": false,
            "master_account_countries": [],
            "private": false,
            "short_description": "",
            "slug": "apg"
        },
        {
            "auth": [
                {
                    "name": "Account Country Code",
                    "required": true,
                    "slug": "country",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Account Entity",
                    "required": true,
                    "slug": "account_entity",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Account Number",
                    "required": true,
                    "slug": "account_number",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Account PIN",
                    "required": true,
                    "slug": "account_pin",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "User Name",
                    "required": true,
                    "slug": "username",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Password",
                    "required": true,
                    "slug": "password",
                    "type": "text",
                    "ui": true
                },
                {
                    "name": "Language Code",
                    "required": false,
                    "slug": "language_code",
                    "type": "text",
                    "ui": true
                }
            ],
            "description": "",
            "display_name": "Aramex",
            "has_master_account": false,
            "master_account_countries": [],
            "private": false,
            "short_description": "Supports almost all functionality via direct API integration including rate, track and shipping",
            "slug": "aramex"
        },
    ]);
    mock.onGet(
      CDN_BASE_URL + "/meta/carriers",
      {
          params: {
              fields: "display_name,shipping_methods,service_types,pickup_service_types"
          },
      }
    ).reply(200, serviceTypes);
    mock.onGet(`${BASE_URL}/webhook/callback/validate`).reply(
        200,
        {
            "status": false
        },
    );
    mock.onGet(
      CDN_BASE_URL + "/meta/carriers",
      {
          params: {
              fields: "packaging_types"
          },
      }
    ).reply(200, packagingTypes);
    mock.onGet(
      CDN_BASE_URL + "/meta/carriers",
      {
          params: {
              fields: "label_types"
          },
      }
    ).reply(200, labelTypes);
    mock.onGet(`${BASE_URL}/webhook`).reply(
        200,
        {
            "webhooks": [
                {
                    "name": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit odio ac",
                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit odio ac " +
                        "felis facilisis volutpat. Curabitur non ex libero. Fusce lacinia magna ligula, ac" +
                        " sodales risus dignissim quis. Pellentesque habitant morbi tristique senectus et" +
                        " netus et malesuada fames ac turpis egestas.",
                    "debug": false,
                    "api_version": "v1",
                    "format": "json",
                    "events": [
                        "shipment.delivered"
                    ],
                    "callback": "https://webhook.site/3a788dd3-bd7c-44a8-bae0-297e3b68b508",
                    "id": "484fe753dcda47a1b817456f3a10e38e"
                },
                {
                    "name": "name 2",
                    "description": "description 2",
                    "debug": false,
                    "api_version": "v1",
                    "format": "json",
                    "events": [
                        "shipment.picked_up",
                        "shipment.in_transit",
                        "shipment.exception",
                        "shipment.ready",
                    ],
                    "callback": "https://webhook.site/temp",
                    "id": "484fe753dcda47a1b817456f3a120e333"
                },
                {
                    "name": "name 3",
                    "description": "description 3",
                    "debug": false,
                    "api_version": "v1",
                    "format": "json",
                    "events": [
                        "shipment.delivered",
                        "shipment.label_ready",
                        "shipment.ready",
                    ],
                    "callback": "https://webhook.site/temp",
                    "id": "484fe753dcda47a1b817456f3a135432"
                },
            ]
        },
    );
    mock.onPost(`${BASE_URL}/webhook`)
        .reply(({ data }) => {
            const webhook = {
                webhook: {
                    id: String(+new Date()),
                    ...JSON.parse(data).webhook,
                }
            }
            return [200, webhook];
        });
    mock.onPut(new RegExp(BASE_URL + "/webhook/\\w+"))
        .reply(({ data }) => [200, data]);
    mock.onDelete(new RegExp(BASE_URL + "/webhook/\\w+")).reply(204);
    mock.onGet(`${BASE_URL}/location`).reply(
        200,
        {
            "locations": [
                {
                    "name": "Nottingham Distribution Centre",
                    "type": "dc",
                    "street1": "unit 4, Bloomsgrove Industrial Estate, Norton St",
                    "street2": "",
                    "street3": "",
                    "city": "Nottingham",
                    "state": "Nottingham",
                    "postal_code": "NG7 3JG",
                    "country": "US",
                    "phone": "+44 203 444 3333",
                    "email": "support@retailer-comapny.xom",
                    "company": "Radford",
                    "timezone": "",
                    "cutoff_hours": 0,
                    "cutoff_minutes": 0,
                    "id": "484fe753dcda47a1b817456f3a135432",
                    "updated": "2021-06-02T07:22:43.000"
                },
                {
                    "name": "Another location",
                    "type": "dc",
                    "street1": "unit 4, Bloomsgrove Industrial Estate, Norton St",
                    "street2": "",
                    "street3": "",
                    "city": "Nottingham",
                    "state": "Nottingham",
                    "postal_code": "NG7 3JG",
                    "country": "US",
                    "phone": "+44 203 444 3333",
                    "email": "support@retailer-comapny.xom",
                    "company": "Radford",
                    "timezone": "",
                    "cutoff_hours": 0,
                    "cutoff_minutes": 0,
                    "id": "484fe753dcda47a1b817456f3a123456",
                    "updated": "2021-06-02T07:22:43.000"
                },
            ]
        },
    );
    mock.onPost(`${BASE_URL}/location`)
        .reply(({ data }) => {
            const location = {
                location: {
                    id: String(+new Date()),
                    updated: (new Date()).toISOString(),
                    ...JSON.parse(data).location,
                }
            }
            return [200, location];
        });
    mock.onPut(new RegExp(BASE_URL + "/location/\\w+"))
        .reply(({ data }) => [200, data]);
    mock.onGet(`${BASE_URL}/return_methods`).reply(
        200,
        {
            "return_methods": [
                {
                    "position": 0,
                    "method_name": "DHL Ground Services",
                    "display_name": "Free Returns",
                    "type": "drop_off",
                    "shipping_account_id": "5190163571408896",
                    "location_id": "484fe753dcda47a1b817456f3a135432",
                    "service_type": "ups_next_day_air",
                    "return_charge": "10.05",
                    "currency": "USD",
                    "filters": [
                        {
                            "field": "return_from_country",
                            "operator": "in",
                            "value": ["GB", "US"],
                        },
                    ],
                    "id": "484fe753dcda47a1b817456f3a135432",
                    "sf_shipping_preference_id": null,
                },
                {
                    "position": 1,
                    "method_name": "Another Return Method",
                    "display_name": "Another Returns",
                    "type": "drop_off",
                    "shipping_account_id": "519016357140889226",
                    "location_id": "484fe753dcda47a1b817456f3a135432",
                    "service_type": "ups_three_day_select",
                    "return_charge": "10.05",
                    "currency": "CAD",
                    "filters": [
                        {
                            "field": "return_from_country",
                            "operator": "equals",
                            "value": "0"
                        },
                        {
                            "field": "total_value",
                            "operator": "not_equals",
                            "value": "0"
                        },
                        {
                            "field": "total_weight",
                            "operator": "equals",
                            "value": "0"
                        }
                    ],
                    "id": "484fe753dcda47a1b817456f3a1351111"
                },
            ]
        },
    );
    mock.onPost(`${BASE_URL}/return_methods`)
        .reply(({ data }) => {
            const returnMethod = {
                return_method: {
                    id: String(+new Date()),
                    ...JSON.parse(data).return_method,
                }
            }
            return [200, returnMethod];
        });
    mock.onPut(new RegExp(BASE_URL + "/return_methods/\\w+"))
        .reply(({ data }) => [200, data]);
    mock.onDelete(new RegExp(BASE_URL + "/return_methods/\\w+")).reply(204);
    mock.onGet(`${BASE_URL}/analytics/shipment`).reply(
      200,
      {
          "events": shipmentEvents,
      },
    );
    mock.onGet(`${BASE_URL}/connected_accounts`).reply(
        200,
        {
            "connected_accounts": [
                // {
                //     "id": "1",
                //     "name": "Salesforce Core",
                //     "service": 1,
                //     "status": "active",
                // },
                // {
                //     "id": "2",
                //     "name": "Salesforce Commerce Cloud",
                //     "service": 2,
                //     "status": "active",
                // },
                {
                    "id": "3",
                    "name": "Newstore 1",
                    "service": 8,
                    "status": "active",
                    "shipping_account_id": "484fe753dcda47a1b817456f3a1351111",
                    "shipping_account_key": 5190163571408896,
                },
                {
                    "id": "4",
                    "name": "Newstore 2",
                    "service": 8,
                    "status": "expired",
                    "shipping_account_id": "484fe753dcda47a1b817456f3a1351111",
                    "shipping_account_key": 519016357140889226,
                },
            ],
        },
    );
    mock.onDelete(new RegExp(BASE_URL + "/connected_accounts/\\w+")).reply(204);
    mock.onGet(`${BASE_URL}/returns`).reply(200, returns);
    mock.onGet(new RegExp(BASE_URL + "/returns/\\w+")).reply(200, returnDetails);
};
