<template>
  <return-methods-template
    v-model:filters="data.returnFilters"
    :loading="page.data.loading"
    :return-methods="page.data.items"
    :returns="data.returns"
    :service-types="data.serviceTypes"
    :locations="data.locations"
    :shipping-accounts="data.shippingAccounts"
    :return-page="data.page"
    :return-last-page="data.isLastPage"
    @create="page.create"
    @update="page.update"
    @delete="page.remove"
    @prev="loadReturns"
    @next="loadReturns"
    @update:filters="loadReturns(1)">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </return-methods-template>
</template>

<script>
export default {
  name: 'ReturnMethods',
};
</script>

<script setup>
import { onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import ReturnMethodsTemplate from '../../templates/ReturnMethods';

import usePage from "../../../mixins/usePage";
import { ReturnMethodService } from "../../../services";
import { showFailMessage, withLoading } from "../../../utils";
import client from "../../../api";

const data = reactive({
  serviceTypes: [],
  locations: [],
  shippingAccounts: [],
  returns: [],
  page: 1,
  pageSize: 20,
  isLastPage: false,
  returnFilters: {
    start_date: null,
    end_date: null,
    return_method: "",
    search: "",
    customer_id: "",
    order_id: "",
    status: "",
    custom: "",
  },
});

const page = usePage(ReturnMethodService);

onMounted(async () => {
  await withLoading(page.data, async () => {
    const serviceTypes = {};

    try {
      const { data: rawServiceTypes } = await client.getServiceTypes();

      rawServiceTypes.forEach((
        { shipping_methods, service_types, pickup_service_types, slug },
        index,
      ) => {
        serviceTypes[index] = {
          ship: shipping_methods.includes('ship'),
          pickup: shipping_methods.includes('pickup'),
          data: service_types,
          pickupServiceTypes: pickup_service_types,
          carrier: slug,
        };

        if (!serviceTypes[index].ship && !serviceTypes[index].pickup) {
          delete serviceTypes[index];
        }
      });

      data.serviceTypes = Object.values(serviceTypes);
    } catch (e) {
      await showFailMessage(page.data, "Service type list wasn't loaded!");
      return;
    }

    try {
      const { data: { locations } } = await client.getLocations();
      data.locations = locations;
    } catch (e) {
      await showFailMessage(page.data, "Location list wasn't loaded!");
      return;
    }

    try {
      const { data: accounts } = await client.getShippingAccounts();
      data.shippingAccounts = accounts.map(account => account.shipping_account);
    } catch (e) {
      await showFailMessage(page.data, "Shipping Account list wasn't loaded!");
    }

    await loadReturns();
  });

  await page.read();
});

const loadReturns = async pageNum => {
  if (pageNum) data.page = pageNum;

  await withLoading(page.data, async () => {
    try {
      const { data: returns } = await client.getReturns({
        page: pageNum || data.page, page_size: data.pageSize, ...data.returnFilters,
      });
      data.isLastPage = !returns.length;

      if (!data.isLastPage || data.page === 1) {
        data.returns = returns;
      } else {
        data.page -= 1;
      }
    } catch (e) {
      await showFailMessage(page.data, "Return list wasn't loaded!");
    }
  });
};
</script>
