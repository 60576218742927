<template>
  <div
    :class="{ 'opacity-50 cursor-not-allowed': disabled }"
    class="w-full relative z-0 flex">
    <div class="w-1/2">
      <label
        class="inline-flex items-center">
        <input
          :value="leftValue"
          :checked="value === leftValue"
          :disabled="disabled"
          type="radio"
          class="form-radio"
          name="radio"
          @click="updateValue(leftValue)">
        <span class="ml-2">{{ finalLeftLabel }}</span>
      </label>
    </div>
    <div class="w-1/2">
      <label class="inline-flex items-center">
        <input
          :value="rightValue"
          :checked="value === rightValue"
          :disabled="disabled"
          type="radio"
          class="form-radio"
          name="radio"
          @click="updateValue(rightValue)">
        <span class="ml-2">{{ finalRightLabel }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toggle",
}
</script>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  leftValue: {
    type: String,
    required: true,
  },
  rightValue: {
    type: String,
    required: true,
  },
  leftLabel: {
    type: String,
    default: "",
  },
  rightLabel: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:value']);

function _title(txt) {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

const finalLeftLabel = computed(() => (
  props.leftLabel || _title(props.leftValue)
));

const finalRightLabel = computed(() => (
  props.rightLabel || _title(props.rightValue)
));

const updateValue = value => emit('update:value', value);
</script>
