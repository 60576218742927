<template>
  <shipping-accounts-template
    :loading="page.data.loading"
    :accounts="page.data.items"
    :carriers="data.carriers"
    :locations="data.locations"
    :packaging-types="data.packagingTypes"
    :service-types="data.serviceTypes"
    :label-types="data.labelTypes"
    @create="page.create"
    @update="page.update"
    @delete="page.remove">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </shipping-accounts-template>
</template>

<script>
export default {
  name: 'ShippingAccounts',
};
</script>

<script setup>
import { onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import ShippingAccountsTemplate from '../../templates/ShippingAccounts';

import usePage from '../../../mixins/usePage';
import { ShippingAccountService } from '../../../services';
import { withLoading, showFailMessage } from '../../../utils';
import client from '../../../api';

const page = usePage(ShippingAccountService);

const data = reactive({
  carriers: [],
  locations: [],
  packagingTypes: {},
  serviceTypes: {},
  labelTypes: {},
});

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      const { data: carriers } = await client.getCarriers();
      data.carriers = carriers.filter(carrier => (
        !carrier.private && Object.keys(carrier.auth).length
      ));
    } catch (e) {
      await showFailMessage(page.data, 'Carrier list loading was failed!');
      return;
    }

    try {
      const { data: { locations } } = await client.getLocations();
      data.locations = locations;
    } catch (e) {
      await showFailMessage(page.data, "Location list wasn't loaded!");
      return;
    }

    try {
      const { data: rawData } = await client.getPackagingTypes();
      data.packagingTypes = {};

      for (let i = 0; i < rawData.length; ++i) {
        const { slug, packaging_types: packagingTypes } = rawData[i];
        data.packagingTypes[slug] = packagingTypes.map(({ name, slug }) => ({
          label: name,
          value: slug,
        }));
      }
    } catch (e) {
      await showFailMessage(page.data, "Packaging type list wasn't loaded!");
      return;
    }

    try {
      const { data: rawData } = await client.getServiceTypes();
      data.serviceTypes = {};

      for (let i = 0; i < rawData.length; ++i) {
        const { slug, service_types: serviceTypes } = rawData[i];
        data.serviceTypes[slug] = serviceTypes.map(({ name, slug }) => ({
          label: name,
          value: slug,
        }));
      }
    } catch (e) {
      await showFailMessage(page.data, "Service type list wasn't loaded!");
      return;
    }

    try {
      const { data: rawData } = await client.getLabelTypes();
      data.labelTypes = {};

      for (let i = 0; i < rawData.length; ++i) {
        const { slug, label_types: labelTypes } = rawData[i];
        data.labelTypes[slug] = labelTypes.map(({ type }) => type);
      }
    } catch (e) {
      await showFailMessage(page.data, "Label type list wasn't loaded!");
    }
  });

  await page.read();
});
</script>
