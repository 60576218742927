import client from "../api";


class ShipmentDetailsService {
  name = 'Shipment details';

  async get(id) {
    const { data } = await client.getShipmentItem(id);
    return data;
  }

  async refresh(trackRequest) {
    await client.refreshTrack(trackRequest);
  }
}

export default new ShipmentDetailsService;
