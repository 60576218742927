<template>
  <modal
    :value="isShown"
    :validation="data.formValidation"
    size="large"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <slot name="title" />
    </template>
    <template
      v-if="data.localValue"
      #body>
      <div class="flex flex-col w-full">
        <z-input
          id="name"
          v-model:value="data.localValue.name"
          :timeout="0"
          :disabled="isEditedTestAccount"
          class="mb-4 w-full"
          placeholder="Enter your shipping account name">
          <template #label>
            Name
          </template>
        </z-input>
        <z-select
          id="carrier"
          v-model:value="data.localValue.carrier"
          :options="carrierOptions"
          :disabled="!isCreateModal"
          class="mb-4 w-full">
          <template #label>
            Carrier
          </template>
        </z-select>
        <z-select
          id="location_id"
          v-model:value="data.localValue.location_id"
          :options="formattedLocationOptions"
          :disabled="isEditedTestAccount"
          class="mb-4 w-full">
          <template #label>
            Shipping Address
            <router-link
              :to="{ name: 'locations' }"
              class="text-blue-500 hover:text-blue-700">
              New Location
            </router-link>
          </template>
        </z-select>

        <template
          v-if="!data.localValue.test && !value.test && data.formFields.length">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900 sm:border-b sm:border-gray-200 sm:pb-3 sm:mb-3">
              Credentials
            </h3>
          </div>
          <template
            v-for="{ type, name, label, options: fieldOptions } in data.formFields"
            :key="name">
            <z-select
              v-if="type === 'collection'"
              :id="`auth-${name}`"
              v-model:value="data.localValue.auth[name]"
              :options="fieldOptions"
              class="mb-4 w-full">
              <template #label>
                {{ label }}
              </template>
            </z-select>
            <z-input
              v-else
              :id="`auth-${name}`"
              v-model:value="data.localValue.auth[name]"
              :timeout="0"
              class="mb-4 w-full">
              <template #label>
                {{ label }}
              </template>
            </z-input>
          </template>
          <template
            v-if="isTestCheckboxShown">
            <checkbox
              id="testShippingAccount"
              :value="data.localValue.test"
              :disabled="isEditedTestAccount"
              class="mb-4"
              @update:value="updateTestField">
              <template #label>
                Create test shipping account
              </template>
            </checkbox>
          </template>
        </template>

        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 sm:border-b sm:border-gray-200 sm:pb-3 sm:mb-3">
            Defaults
          </h3>
        </div>
        <z-select
          id="country"
          :value="data.localValue.country"
          :options="countryOptions"
          :disabled="isEditedTestAccount"
          class="mb-4 w-full"
          @update:value="updateCountry">
          <template #label>
            Country
          </template>
        </z-select>
        <z-select
          id="currency"
          v-model:value="data.localValue.defaults.currency"
          :options="currencyOptions"
          :disabled="isEditedTestAccount"
          class="mb-4 w-full">
          <template #label>
            Currency
          </template>
        </z-select>
        <!--      <z-select-->
        <!--        v-model:value="localValue.billing_address"-->
        <!--        id="billingAddress"-->
        <!--        class="mb-4 w-full">-->
        <!--        <template #label>Billing Address <a href="">New Location</a></template>-->
        <!--      </z-select>-->
        <!--      <z-select-->
        <!--        v-model:value="localValue.shipping_address"-->
        <!--        id="shippingAddress"-->
        <!--        class="mb-4 w-full">-->
        <!--        <template #label>Shipping Address <a href="">New Location</a></template>-->
        <!--      </z-select>-->
        <z-select
          id="defaults.dim_units"
          v-model:value="data.localValue.defaults.dim_units"
          :options="dimensionUnits"
          :disabled="isEditedTestAccount"
          class="mb-4 w-full">
          <template #label>
            Dimension
          </template>
        </z-select>
        <z-select
          id="defaults.weight_units"
          v-model:value="data.localValue.defaults.weight_units"
          :class="data.localValue.carrier ? 'mb-4' : 'mb-6'"
          :options="weightUnits"
          :disabled="isEditedTestAccount">
          <template #label>
            Weight Unit
          </template>
        </z-select>
        <z-select
          id="defaults.packaging_type"
          v-model:value="data.localValue.defaults.packaging_type"
          :options="packagingTypeOptions"
          :disabled="isEditedTestAccount || !data.localValue.carrier"
          class="mb-4"
          options-top-position>
          <template #label>
            Packaging Type
          </template>
        </z-select>
        <z-select
          id="defaults.service"
          v-model:value="data.localValue.defaults.service"
          :options="serviceTypeOptions"
          :disabled="isEditedTestAccount || !data.localValue.carrier"
          class="mb-4"
          options-top-position>
          <template #label>
            Service Type
          </template>
        </z-select>
        <z-select
          id="defaults.label_type"
          v-model:value="data.localValue.defaults.label_type"
          :options="labelTypeOptions"
          :disabled="isEditedTestAccount || !data.localValue.carrier"
          class="mb-4"
          options-top-position>
          <template #label>
            Label Type
          </template>
        </z-select>
      </div>
      <!--      <z-select-->
      <!--        v-model:value="data.localValue.currency"-->
      <!--        id="currency"-->
      <!--        class="mb-4 w-full">-->
      <!--        <template #label>Default Currency</template>-->
      <!--      </z-select>-->
    </template>
    <template #footer>
      <z-button
        :disabled="value.test"
        type="success"
        full-width
        submit>
        <slot name="send-button-label" />
      </z-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ShippingAccountModal',
};
</script>

<script setup>
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { string, object } from 'yup';

import ZInput from '../../atoms/Input';
import ZButton from '../../atoms/Button';
import ZSelect from '../../atoms/Select';
import Checkbox from '../../atoms/Checkbox';
import Modal from '../../organisms/Modal';

const requiredBaseFieldsSchema = {
  name: string().max(50).required().label('Name'),
  carrier: string().required().label('Carrier'),
  country: string().required().label('Country'),
  defaults: object({
    dim_units: string().required().label('Dimension'),
    weight_units: string().required().label('Weight Unit'),
    // packaging_type: string().required().label('Packaging Type'),
    // service: string().required().label('Service'),
    // label_type: string().required().label('Label Type'),
  }),
  location_id: string().label('Shipping Address'),
};

const dimensionUnits = ['CM', 'IN'];
const weightUnits = ['KG', 'LB'];

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
  carriers: {
    type: Array,
    default: () => ([]),
  },
  locations: {
    type: Array,
    default: () => ([]),
  },
  countryOptions: {
    type: Array,
    default: () => ([]),
  },
  currencyOptions: {
    type: Array,
    default: () => ([]),
  },
  packagingTypes: {
    type: Object,
    default: () => ({}),
  },
  serviceTypes: {
    type: Object,
    default: () => ({}),
  },
  labelTypes: {
    type: Object,
    default: () => ({}),
  },
  isShown: {
    type: Boolean,
    default: false,
  },
  isCreateModal: {
    type: Boolean,
    default: true,
  },
});

const localValueFactory = () => ({
  name: "",
  carrier: "",
  country: "",
  defaults: {
    dim_units: "",
    weight_units: "",
    packaging_type: "",
    service: "",
    label_type: "",
    currency: "",
  },
  location_id: "",
  auth: {},
  test: false,
});

const data = reactive({
  localValue: {
    ...localValueFactory(),
    ...props.value,
  },
  selectedCarrier: {},
  formValidation: object({ ...requiredBaseFieldsSchema }),
  formFields: [],
});

const carrierOptions = computed(() => (
  props.carriers
    .map(carrier => ({
      value: carrier.slug,
      label: carrier.display_name.trim() || (carrier.slug[0].toUpperCase() + carrier.slug.substr(1)),
    }))
    .sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
));

const packagingTypeOptions = computed(() => props.packagingTypes[data.localValue.carrier]);
const labelTypeOptions = computed(() => props.labelTypes[data.localValue.carrier]);
const serviceTypeOptions = computed(() => props.serviceTypes[data.localValue.carrier]);
const isEditedTestAccount = computed(() => !props.isCreateModal && props.value.test);
const formattedLocationOptions = computed(() => (
  props.locations.map(({ id: value, name: label }) => ({ value, label }))
));
const isTestCheckboxShown = computed(() => (
  (
    data.selectedCarrier.has_master_account
    && data.selectedCarrier.master_account_countries.includes(data.localValue.country)
  ) && props.isCreateModal
));

watch(
  () => props.value,
  (value) => {
    const newLocalValue = localValueFactory();
    data.localValue = {
      ...newLocalValue,
      ...value,
      auth: {
        ...newLocalValue.auth,
        ...value.auth,
      },
      defaults: {
        ...newLocalValue.defaults,
        ...value.defaults,
      },
    };
  }
);

watch(
  () => data.localValue.carrier,
  async (value) => {
    data.localValue.test = false;
    data.localValue.packaging_type = "";
    data.localValue.service = "";
    data.localValue.label_type = "";

    if (!value) {
      data.selectedCarrier = {};
      await rebuildForm();
      return;
    }

    for (let i = 0; i < props.carriers.length; ++i) {
      if (props.carriers[i].slug === value) {
        data.selectedCarrier = props.carriers[i];
        await rebuildForm(!!Object.keys(props.value).length);
        break;
      }
    }
  },
);

const emit = defineEmits(['send', 'close']);

const rebuildForm = async (isEdited) => {
  data.formFields = [];
  data.form = object({});

  await nextTick(() => {
    const form = { ...requiredBaseFieldsSchema };
    const fields = [];

    const { auth } = data.selectedCarrier;
    auth && Array.isArray(auth) && !data.localValue.test && auth.forEach(element => {
      if (!element.ui) return;

      const key = `auth-${element.slug}`
      form[key] = string();

      if (element.required) {
        form[key] = form[key].required();
      }
      form[key] = form[key].label(element.name);

      const fieldItem = { name: element.slug, label: element.name, type: element.type };
      if ('collection' in element) {
        fieldItem['options'] = element['collection'].map(
          item => ({'value': item.slug, 'label': item.name})
        );
      }

      fields.push(fieldItem);
    });

    if (!Object.keys(props.value.auth || {}) || !isEdited) {
      data.localValue.auth = {};
    }

    data.formValidation = object(form);
    data.formFields = fields;
  });
};

const updateTestField = async (current) => {
  const previous = data.localValue.test;
  data.localValue.test = current;

  if (current || (previous && !current)) {
    await rebuildForm();
  }
};

const updateCountry = async (value) => {
  data.localValue.country = value;
  await updateTestField(false);
};

const submit = () => emit('send', data.localValue);

const closeModal = async () => {
  data.localValue = localValueFactory();
  await nextTick(async () => {
    await nextTick(() => emit('close'))
  });
};
</script>
