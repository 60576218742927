<template>
  <public-credentials>
    <template #title>
      Confirm your login
    </template>
    <template #subtitle>
      Enter the authorization code from <strong>{{ email }}</strong>
    </template>
    <template #content>
      <v-form
        :validation-schema="schema"
        @submit="signIn">
        <alert
          v-if="error"
          type="danger"
          class="mb-4"
          close
          @close="closeAlert">
          {{ error }}
        </alert>
        <pin-input
          id="password"
          :key="error"
          :timeout="0"
          :size="data.passwordLength"
          class="mb-4"
          @update:value="updatePassword">
          <template #error-message>
            <span />
          </template>
        </pin-input>

        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
          text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Submit
        </button>
      </v-form>
    </template>
  </public-credentials>
</template>

<script>
export default {
  name: 'PublicLoginMfaConfirmTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits, reactive } from 'vue';
import { Form as VForm } from 'vee-validate';
import { string } from "yup";

import Alert from '../../atoms/Alert';
import PinInput from '../../atoms/PinInput';
import PublicCredentials from '../../templates/PublicCredentials';

const PASSWORD_LENGTH = 6;

defineProps({
  email: {
    type: String,
    required: true,
  },
  password: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['sign-in', 'alert-close', 'update:password']);

const data = reactive({
  passwordLength: PASSWORD_LENGTH,
})

const schema = {
  password: string().length(PASSWORD_LENGTH).required().label('Confirmation Code'),
};

const signIn = () => emit('sign-in');
const closeAlert = () => emit('alert-close');
const updatePassword = value => emit('update:password', value);
</script>
