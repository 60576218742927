<template>
  <button
    :class="classes"
    :disabled="disabled"
    :type="submit ? 'submit' : 'button'"
    class="inline-flex items-center py-2 border border-transparent font-medium
    justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2
    border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-offset-gray-100 focus:ring-gray-500
    disabled:opacity-50 disabled:cursor-not-allowed">
    <span
      v-if="$slots['start-icon']"
      class="-ml-0.5 mr-2 h-4 w-4"
      aria-hidden="true">
      <slot name="start-icon" />
    </span>
    <slot />
    <span
      v-if="$slots['end-icon']"
      class="-ml-0.5 mr-2 h-4 w-4"
      aria-hidden="true">
      <slot name="end-icon" />
    </span>
  </button>
</template>

<script>
export default {
  name: "Button",
}
</script>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  primary: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
    validator: function (value) {
      return ['primary', 'success', 'info', 'warning', 'danger'].indexOf(value) !== -1;
    },
  },
  size: {
    type: String,
    default: 'small',
    validator: function (value) {
      return ['small', 'large'].indexOf(value) !== -1;
    },
  },
  submit: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => ({
  ["btn--primary"]: props.primary,
  ["btn--secondary"]: props.secondary,
  ["btn--fullwidth"]: props.fullWidth,
  [`btn--${props.type}`]: true,
  [`btn--${props.size}`]: true,
}));
</script>

<style lang="scss">
.btn--fullwidth {
  width: 100%;
}

/**
 * 1. Reduce spacing between the icon and text in small buttons.
 */

.btn--small {
  @apply px-3 text-sm;
}

.btn--large {
  @apply px-4 text-base;
}

.btn--primary {
  //@apply text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500;
}

.btn--secondary {
  //@apply text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500;
}

.btn--info {
  //@apply text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500;
}

.btn--success {
  //@apply text-white bg-green-500 hover:bg-green-700 focus:ring-green-500;
}

.btn--warning {
  //@apply text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500;
}

.btn--danger {
  //@apply text-white bg-red-600 hover:bg-red-700 focus:ring-red-500;
}
</style>
