<template>
  <div>
    <div class="flex justify-between">
      <template
        v-for="i in size"
        :key="`code-field-${i}`">
        <input
          :id="`codeField${i}`"
          :autofocus="i === 0"
          :class="classes"
          class="h-16 w-12 border rounded-lg flex items-center text-center font-thin text-3xl outline-none"
          value=""
          maxlength="1"
          min="0"
          max="9"
          inputmode="decimal"
          @input="update(i, $event.target.value)">
      </template>
    </div>
    <p
      v-if="errorMessage"
      class="mt-2 text-sm text-red-600">
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "PinInput",
}
</script>

<script setup>
import { computed, defineEmits, defineProps, onMounted, reactive } from 'vue';
import { useField } from 'vee-validate';

import { createDebounce } from '../../../utils';

const debounce = createDebounce();

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  size: {
    type: Number,
    default: 6,
  },
  status: {
    type: String,
    default: '',
    validator(value) {
      return ['success', 'warning', 'danger', ''].indexOf(value) !== -1;
    },
  },
  timeout: {
    type: Number,
    default: 500,
  },
  validation: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:value']);

const data = reactive({
  value: [],
});

onMounted(() => {
  data.value = Array(props.size).fill('');
});

let errorMessage = false;
let meta = { validated: false, valid: true };
let handleInput = (value) => {
  if (props.timeout) {
    debounce(() => emit('update:value', value), props.timeout);
    return;
  }

  emit('update:value', value);
};

if (props.validation) {
  const connectedField = useField(props.id, undefined, {
    initialValue: '',
  });
  errorMessage = connectedField.errorMessage;
  meta = connectedField.meta;

  handleInput = (value) => {
    connectedField.handleChange(value);

    if (props.timeout) {
      debounce(() => emit('update:value', value), props.timeout);
      return;
    }

    emit('update:value', value);
  };
}

const update = (index, value) => {
  data.value[index - 1] = value;
  handleInput(data.value.join(''));

  if (value) {
    if (index < props.size) {
      document.getElementById(`codeField${index + 1}`).focus();
    }
  } else {
    if (index > 1) {
      document.getElementById(`codeField${index - 1}`).focus();
    }
  }
};

const classes = computed(() => ({
  ...(
      (meta.validated && !meta.valid) || props.status
          ? {
            'focus:ring-red-500 focus:border-red-500 border-red-300': (meta.validated && !meta.valid) || props.status === 'danger',
            'focus:ring-green-500 focus:border-green-500 border-green-300': props.status === 'success',
            'focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300': props.status === 'warning',
          }
          : {'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300': true}
  ),
}));
</script>
