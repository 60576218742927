<template>
  <Menu
    :id="id"
    as="div"
    class="relative inline-block text-left">
    <div>
      <MenuButton
        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
        px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        <slot />
        <ChevronDownIcon
          class="-mr-1 ml-2 h-5 w-5"
          aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        :class="leftAlign ? 'left-0' : 'right-0'"
        class="origin-top-right absolute mt-2 w-56 rounded-md shadow-lg
        bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
        <div class="py-1">
          <MenuItem
            v-for="item in options"
            v-slot="{ active }"
            :key="item.value"
            class="cursor-pointer"
            @click="selectItem(item.value)">
            <a
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm'
              ]">
              <component
                :is="item.icon"
                v-if="item.icon"
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true" />
              {{ item.label }}
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
export default {
  name: "Dropdown",
}
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';

defineProps({
  options: {
    type: Array,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  leftAlign: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['select']);

const selectItem = (value) => {
  emit('select', value);
};
</script>

