<template>
  <div>
    <slot name="message" />

    <div class="mb-7">
      <h3 class="text-2xl font-medium leading-tight mr-4 py-4">
        Settings
      </h3>
      <z-switch
        :value="mfaValue"
        label="Multi-factor authentication:"
        @update:value="update" />
    </div>

    <z-table
      :loading="loading"
      :fields="data.fields"
      :data="props.data">
      <template #title>
        Users
      </template>
    </z-table>
  </div>
</template>

<script>
export default {
  name: 'UsersTemplate',
};
</script>

<script setup>
import { defineProps, defineEmits, computed, reactive } from 'vue';

import ZSwitch from '../../atoms/Switch';
import ZTable from '../../organisms/Table';


const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  mfa: false,
  fields: [
    "name",
    "email",
  ],
});

const mfaValue = computed(() => {
  if (!props.data.length) return false;
  return props.data[0].mfa;
})

const emit = defineEmits(['update']);

const update = value => emit('update', { mfa: value });
</script>
