<template>
  <div>
    <location-modal
      :value="modal.data.form"
      :is-shown="modal.data.isOpen"
      :type-options="typeOptions"
      @send="modal.handle"
      @close="modal.close">
      <template #title>
        <template v-if="modal.data.isCreateForm">
          New
        </template> Location
      </template>
      <template #send-button-label>
        {{ modal.data.isCreateForm ? "Create" : "Update" }}
      </template>
    </location-modal>

    <slot name="message" />

    <z-table
      :loading="loading"
      :fields="fields"
      :data="locations">
      <template #title>
        Locations
      </template>
      <template #filter-bar>
        <z-button
          type="success"
          @click="modal.showCreateForm">
          Create
        </z-button>
      </template>
      <template #cell(country)="{ row }">
        {{ countries[row.country] }}
      </template>
      <template #cell(type)="{ row }">
        {{ typeOptionsMapping[row.type] }}
      </template>
      <template #cell(updated)="{ row }">
        {{ format(Date.parse(row.updated), 'PPPppp') }}
      </template>
      <template #cell(actions)="{ row }">
        <z-button
          secondary
          @click="modal.showEditForm(row)">
          Edit
        </z-button>
      </template>
    </z-table>
  </div>
</template>

<script>
export default {
  name: 'LocationsTemplate',
};
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import format from "date-fns/format";

import ZButton from '../../atoms/Button';
import ZTable from '../../organisms/Table';
import LocationModal from '../../organisms/LocationModal';
import useModal from '../../../mixins/useModal';

import countries from '../../../assets/js/countries.json';

defineProps({
  locations: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['create', 'update']);
const modal = useModal(emit);

const fields = [
  "name",
  "type",
  "city",
  "country",
  {key: "updated", label: "Last Updated"},
  "actions",
];

const typeOptions = [
  {value: 'dc', label: 'DC'},
  {value: 'store', label: 'Store'},
  {value: 'drop_off', label: 'Drop Off'},
];

const typeOptionsMapping = computed(() => {
  const result = {};

  for (let i = 0; i < typeOptions.length; ++i) {
    const { value, label } = typeOptions[i];
    result[value] = label;
  }

  return result;
});
</script>
