<template>
  <div>
    <shipping-account-modal
      :value="modal.data.form"
      :carriers="carriers"
      :locations="locations"
      :country-options="countryOptions"
      :currency-options="currencyOptions"
      :packaging-types="packagingTypes"
      :service-types="serviceTypes"
      :label-types="labelTypes"
      :is-shown="modal.data.isOpen"
      :is-create-modal="modal.data.isCreateForm"
      @send="modal.handle"
      @close="modal.close">
      <template #title>
        <template v-if="modal.data.isCreateForm">
          New
        </template> Shipping Account
      </template>
      <template #send-button-label>
        {{ modal.data.isCreateForm ? "Create" : "Update" }}
      </template>
    </shipping-account-modal>

    <slot name="message" />

    <z-table
      :loading="loading"
      :fields="fields"
      :data="accounts">
      <template #no-data>
        <a
          class="text-indigo-400 hover:text-indigo-600"
          href="https://zenkraft.com/docs/api#ship"
          target="_blank">Read our documentation</a>
        &nbsp;to create your first shipment!
      </template>
      <template #title>
        Shipping Accounts
      </template>
      <template #filter-bar>
        <z-button
          :disabled="!carriers.length"
          type="success"
          @click="modal.showCreateForm">
          Create
        </z-button>
      </template>
      <template #header(carrier)="{ column }">
        <div class="text-center">
          {{ column.label }}
        </div>
      </template>
      <template #header(actions)="{ column }">
        <div class="text-center">
          {{ column.label }}
        </div>
      </template>


      <template #cell(carrier)="{ row }">
        <div class="text-center">
          <img
            :src="`${apiBaseUrl}/img/carriers/${row.carrier}.png`"
            :alt="row.carrier"
            class="w-24 block m-auto">
          {{ carriers ? carrierNames[row.carrier] : row.carrier }}
        </div>
      </template>
      <template #cell(country)="{ row }">
        {{ row.country && countryMapping[row.country] }}
      </template>
      <template #cell(actions)="{ row }">
        <div class="min-w-max text-center">
          <z-button
            :disabled="!carriers.length"
            class="mr-4"
            secondary
            @click="modal.showEditForm(row)">
            Edit
          </z-button>
          <z-button
            type="danger"
            @click="remove(row.id)">
            Delete
          </z-button>
        </div>
      </template>
    </z-table>
  </div>
</template>

<script>
export default {
  name: 'ShippingAccountsTemplate',
};
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useStore } from 'vuex'

import ZButton from '../../atoms/Button';
import ZTable from '../../organisms/Table';
import ShippingAccountModal from "../../organisms/ShippingAccountModal";
import useModal from '../../../mixins/useModal';

import countryMapping from '../../../assets/js/countries.json';
import CurrencyList from '../../../assets/js/currencies.json';

const countryOptions = Object.keys(countryMapping).map(value => ({
  value, label: countryMapping[value],
}));

const currencyOptions = (
  Object.keys(CurrencyList)
    .map(value => ({ value, label: CurrencyList[value] }))
    .sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();

      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    })
);

const fields = [
  "carrier",
  "id",
  "name",
  "country",
  // "auth",
  "actions",
];

const store = useStore();
const apiBaseUrl = computed(() => store.state.apiBaseUrl);

const props = defineProps({
  accounts: {
    type: Array,
    default: () => ([])
  },
  carriers: {
    type: Array,
    default: () => ([])
  },
  locations: {
    type: Array,
    default: () => ([])
  },
  packagingTypes: {
    type: Object,
    default: () => ({})
  },
  serviceTypes: {
    type: Object,
    default: () => ({})
  },
  labelTypes: {
    type: Object,
    default: () => ({})
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['create', 'update', 'delete']);
const modal = useModal(emit);

const carrierNames = computed(() => {
  const result = {};

  props.carriers.forEach(carrier => {
    result[carrier.slug] = (
      carrier.display_name.trim()
      || (carrier.slug[0].toUpperCase() + carrier.slug.substr(1))
    );
  });

  return result;
});

const remove = row => emit('delete', row.id);
</script>
