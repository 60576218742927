<template>
  <div>
    <z-button
      type="success"
      class="mb-4"
      full-width
      @click="addHeader">
      Add new header
    </z-button>
    <template
      v-for="(header, index) in value"
      :key="header">
      <div class="flex flex-row gap-4 justify-between">
        <div class="flex flex-col w-1/2 mb-4">
          <z-input
            :id="`name-${index}`"
            :value="header.name"
            placeholder="Header name"
            @update:value="updateHeader(index, 'name', $event)" />
          <small
            v-if="headersErrors[index] && headersErrors[index].name"
            class="text-red-600">
            {{ headersErrors[index].name }}
          </small>
        </div>
        <div class="flex flex-col w-1/2 mb-4">
          <z-input
            :id="`value-${index}`"
            :value="header.value"
            placeholder="Header value"
            @update:value="updateHeader(index, 'value', $event)" />
          <small
            v-if="headersErrors[index] && headersErrors[index].value"
            class="text-red-600">
            {{ headersErrors[index].value }}
          </small>
        </div>
        <div class="flex flex-col">
          <z-button
            @click="removeHeader(index)">
            <TrashIcon
              class="h-5 w-5"
              aria-hidden="true" />
          </z-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HeadersField',
};
</script>

<script setup>
import { useField } from 'vee-validate';
import { computed, defineEmits, defineProps, nextTick } from 'vue';
import { TrashIcon } from '@heroicons/vue/outline';

import ZInput from '../../atoms/Input';
import ZButton from '../../atoms/Button';

const props = defineProps({
  value: {
    type: Array,
    required: true,
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:value', 'update:header']);

const connectedField = useField('headers', undefined, {
  initialValue: props.value,
});

const addHeader = async () => {
  const value = [...props.value, {
    name: '',
    value: '',
  }];
  emit('update:value', value);
  connectedField.handleChange(value);
};

const updateHeader = async (index, field, value) => {
  emit('update:header', { index, field, value });

  await nextTick(async () => {
    await nextTick(() => {
      connectedField.handleChange(props.value);
    });
  });
};

const removeHeader = index => {
  const value = props.value.filter((_, i) => i !== index);
  emit('update:value', value);
  connectedField.handleChange(value);
};

const headersErrors = computed(() => {
  const result = {};

  Object.keys(props.errors).forEach(key => {
    if (key.startsWith('headers')) {
      const [other, fieldName] = key.split('].');
      const [_, index] = other.split('[');

      if (!(index in result)) result[index] = {};

      result[index][fieldName] = props.errors[key].replace(`headers[${index}].`, '');
    }
  });

  return result;
});
</script>
