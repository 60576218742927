<template>
  <return-details-template
    :loading="page.data.loading"
    :value="data.details"
    @update:status="changeStatus">
    <template #message>
      <alert
        v-if="page.data.message"
        :type="page.data.messageStatus"
        :close="true"
        @close="page.data.message = ''">
        {{ page.data.message }}
      </alert>
    </template>
  </return-details-template>
</template>

<script>
export default {
  name: 'ReturnDetails',
};
</script>

<script setup>
import { defineProps, onMounted, reactive } from 'vue';

import Alert from '../../atoms/Alert';
import ReturnDetailsTemplate from '../../templates/ReturnDetails';

import usePage from "../../../mixins/usePage";
import { ReturnDetailsService } from '../../../services';
import { showFailMessage, withLoading } from "../../../utils";
import client from "../../../api";

const page = usePage();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const data = reactive({
  details: {},
});

onMounted(async () => {
  await withLoading(page.data, async () => {
    try {
      data.details = await ReturnDetailsService.get(props.id);
    } catch (e) {
      await showFailMessage(page.data, `${ReturnDetailsService.name} weren't loaded!`);
    }
  });
});

const changeStatus = async value => {
  await withLoading(page.data, async() => {
    try {
      await client.patchReturnItem(props.id, {
        shipment: {
          status: value,
        }
      });
      data.details.shipment.status = value;
    } catch (e) {
      await showFailMessage(page.data, "Return status wasn't updated!");
    }
  });
};
</script>
