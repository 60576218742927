<template>
  <div class="pt-5">
    <slot name="message" />

    <div class="flex align-top gap-4 mb-10">
      <usage-summary-card
        :loading="loading"
        :value="stats"
        class="flex-grow" />
      <api-key-card
        :loading="loading"
        :value="apiKey"
        class="flex-shrink" />
    </div>
    <div class="row">
      <z-table
        :loading="loading"
        :fields="fields"
        :data="logs">
        <template #no-data>
          Enable&nbsp;<i>debug</i>&nbsp;mode in your requests to see them here.
        </template>
        <template #title>
          Logs
        </template>
        <template #cell(status_code)="{ row }">
          <badge :type="convertStatusCode(row.status_code)">
            {{ row.status_code }}
          </badge>
        </template>
        <template #cell(created)="{ row }">
          <router-link
            :to="{ name: 'log-details', params: { id: row.id } }"
            :title="row.created"
            class="text-indigo-400 hover:text-indigo-600">
            {{ formatDistanceToNow(new Date(row.created), { addSuffix: true }) }}
          </router-link>
        </template>
      </z-table>
      <pagination
        :page="page"
        :last-page="lastPage"
        class="u-pv-small"
        @prev="prev"
        @next="next" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeTemplate',
};
</script>

<script setup>
import {defineEmits, defineProps} from 'vue';
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import Badge from "../../atoms/Badge";
import Pagination from "../../molecules/Pagination";
import UsageSummaryCard from "../../molecules/UsageSummaryCard";
import ApiKeyCard from "../../molecules/ApiKeyCard";
import ZTable from '../../organisms/Table';
import { convertStatusCode } from '../../../utils';

defineProps({
  apiKey: {
    type: String,
    required: true,
  },
  stats: {
    type: Array,
    required: true,
  },
  logs: {
    type: Array,
    required: true,
  },
  page: {
    type: Number,
    required: true,
  },
  lastPage: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['prev', 'next']);

const fields = [
  {key: "created", label: "Date Time"},
  {key: "status_code", label: "Status Code"},
  {key: "url", label: "URL"},
  {key: "ip_address", label: "IP Address"},
];

const prev = value => emit('prev', value);
const next = value => emit('next', value);
</script>
