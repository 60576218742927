<template>
  <public-login-template
    v-model:email="data.email"
    v-model:password="data.password"
    @sign-in="signIn">
    <template #message>
      <alert
        v-if="data.error"
        type="danger"
        close
        @close="data.error = ''">
        {{ data.error }}
      </alert>
      <alert
        v-if="resetPasswordMessage"
        :close="true"
        type="success"
        @close="resetPasswordMessage = ''">
        {{ resetPasswordMessage }}
      </alert>
    </template>
  </public-login-template>
</template>

<script>
export default {
  name: 'PublicLoginPage',

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$route && 'success' in vm.$route.query && from.name === 'forgot-password') {
        vm.resetPasswordMessage = 'Check your inbox for a password reset email!';
      }
    })
  },

  data() {
    return {
      resetPasswordMessage: '',
    };
  }
};
</script>

<script setup>
import { reactive } from 'vue';
import { useStore } from "vuex";

import Alert from '../../atoms/Alert';
import PublicLoginTemplate from '../../templates/PublicLogin';

const data = reactive({
  email: "",
  password: "",
  error: "",
});

const store = useStore();

const signIn = async () => {
  const email = data.email;
  const password = data.password;

  try {
    await store.dispatch('login', { email, password });
  } catch(e) {
    try {
      const { response: { data: { error: { message } } } } = e;
      data.error = message;
    } catch {
      data.error = 'Unknown error message from the server. Please contact our support team.'
    }
  }
};
</script>
