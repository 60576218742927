<template>
  <modal
    :value="isShown"
    :validation="schema"
    size="huge"
    @close="closeModal"
    @submit="submit">
    <template #title>
      <slot name="title" />
    </template>
    <template
      v-if="data.localValue"
      #body="{ errors }">
      <div class="flex flex-col w-full">
        <div class="flex gap-3">
          <z-input
            id="method_name"
            v-model:value="data.localValue.method_name"
            class="mb-4 w-full">
            <template #label>
              Return Option Name
            </template>
          </z-input>
          <z-input
            id="display_name"
            v-model:value="data.localValue.display_name"
            class="mb-4 w-full">
            <template #label>
              Display Name
            </template>
          </z-input>
        </div>

        <z-select
          id="shipping_account_id"
          v-model:value="data.localValue.shipping_account_id"
          :options="formattedShippingAccountOptions"
          class="mb-4 w-full">
          <template #label>
            Shipping Account
          </template>
          <template #nooptions>
            Please create a new <router-link
              :to="{ name: 'shipping-accounts' }"
              target="_blank"
              class="text-indigo-400 hover:text-indigo-600">
              shipping account
            </router-link>
          </template>
        </z-select>

        <div class="flex gap-3">
          <z-select
            id="type"
            v-model:value="data.localValue.type"
            :options="typeOptions"
            class="mb-4 w-full">
            <template #label>
              Return Type
            </template>
          </z-select>
          <z-select
            id="service_type"
            v-model:value="data.localValue.service_type"
            :options="formattedServiceTypeOptions"
            :disabled="!formattedServiceTypeOptions.length"
            :title="serviceTypeTitle"
            class="mb-4 w-full">
            <template #label>
              Service Type
            </template>
          </z-select>
        </div>
        <z-input
          id="return_charge"
          v-model:value="data.localValue.return_charge"
          :min="0"
          type="number"
          class="mb-4 w-full">
          <template #label>
            Return Charge
            <span v-if="data.localValue.shipping_account_id">
              [{{ shippingAccountDetailsMapping[data.localValue.shipping_account_id].currency }}]
            </span>
          </template>
        </z-input>
        <display-criteria-field
          id="filters"
          v-model:value="data.filtersValue"
          v-model:filters="data.localValue.filters"
          :errors="errors"
          class="mb-4 w-full"
          @update:field="updateField">
          <template #label>
            Display Criteria
          </template>
        </display-criteria-field>
        <z-select
          id="location_id"
          v-model:value="data.localValue.location_id"
          :options="formattedLocationOptions"
          :class="$style.locationId"
          class="mb-4 w-full"
          options-top-position>
          <template #label>
            Return Location
          </template>
          <template #nooptions>
            Please create a new <router-link
              :to="{ name: 'locations' }"
              class="text-indigo-400 hover:text-indigo-600">
              location
            </router-link>
          </template>
        </z-select>
        <z-input
          id="sf_shipping_preference_id"
          v-model:value="data.localValue.sf_shipping_preference_id"
          class="mb-4 w-full">
          <template #label>
            Salesforce Shipping Preference ID
          </template>
        </z-input>
      </div>
    </template>
    <template #footer>
      <z-button
        type="success"
        full-width
        submit>
        <slot name="send-button-label" />
      </z-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ReturnMethodModal',
};
</script>

<script setup>
import { computed, defineEmits, defineProps, nextTick, reactive, watch } from 'vue';
import { array, number, string, object } from 'yup';

import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import ZButton from '../../atoms/Button';
import DisplayCriteriaField from '../../organisms/DisplayCriteriaField';
import Modal from '../../organisms/Modal';

const typeOptions = [
  {value: 'drop_off', label: 'Carrier Drop-off'},
  {value: 'pickup', label: 'Carrier Pick-up'},
];
const schema = object().shape({
  method_name: string().required().label('Name'),
  display_name: string().required().label('Display Name'),
  type: string().required().oneOf(typeOptions.map(option => option.value)).label('Return Type'),
  service_type: string()
    .when('field', {
      is: () => formattedServiceTypeOptions.value.length,
      then: () => string().label('Service Type').required(),
      otherwise: () => string().label('Service Type').test(
        'service-type',
        `Select another Return Type`,
        () => formattedServiceTypeOptions.value.length,
      ),
    }),
  shipping_account_id: string().required().label('Shipping Account'),
  location_id: string().required().label('Return Location'),
  return_charge: number().required().moreThan(-1).label('Return Charge'),
  sf_shipping_preference_id: string().nullable().label('Salesforce Shipping Preference ID'),
  filters: array()
    .of(
      object().shape({
        field: string().required(),
        name: string()
          .when('field', {
            is: val => val === 'custom',
            then: () => string().required(),
            otherwise: () => string(),
          }),
        operator: string().required(),
        value: string()
          .when('field', {
            is: val => val === 'return_from_country',
            then: () => string()
              .when('operator', {
                is: val => val === 'in' || val === 'not_in',
                then: () => array().of(string()).required(),
                otherwise: () => string().required(),
              }),
            otherwise: () => string()
              .when('field', {
                is: val => val === 'custom',
                them: () => string().max(100).required(),
                otherwise: () => number().typeError('value must be a number').moreThan(-1).integer().required()
              }),
          }),
      })
    )
    .label('Display Criteria')
});

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  isShown: {
    type: Boolean,
    default: false,
  },
  serviceTypes: {
    type: Array,
    required: true,
  },
  locations: {
    type: Array,
    required: true,
  },
  shippingAccounts: {
    type: Array,
    required: true,
  },
});

const localValueFactory = () => ({
  method_name: "",
  display_name: "",
  type: "",
  shipping_account_id: "",
  location_id: "",
  service_type: "",
  sf_shipping_preference_id: "",
  return_charge: 0,
  filters: [],
});

const data = reactive({
  localValue: {
    ...localValueFactory(),
    ...props.value,
  },
  filtersValue: false,
  defaultPickupServiceTypeOptions: [],
  pickupServiceTypeOptions: [],
  dropOffServiceTypeOptions: [],
});

const emit = defineEmits(['send', 'close']);

const carrierBlacklist = computed(() => (
  props.serviceTypes
    .filter(({ data, pickupServiceTypes }) => !data.length && !pickupServiceTypes.length)
    .map(({ carrier }) => carrier)
));

const formattedShippingAccountOptions = computed(() => (
  props.shippingAccounts
    .filter(({ carrier }) => !carrierBlacklist.value.includes(carrier))
    .map(({ id: value, name: label }) => ({ value, label }))
));

const shippingAccountDetailsMapping = computed(() => {
  const result = {};

  for (let i = 0; i < props.shippingAccounts.length; ++i) {
    const { id, carrier, location_id, defaults: { currency } } = props.shippingAccounts[i];
    result[id] = { carrier, currency, location_id };
  }

  return result;
});

const formattedLocationOptions = computed(() => (
  props.locations.map(({ id: value, name: label }) => ({ value, label }))
));

const formattedServiceTypeOptions = computed(() => {
  const { shipping_account_id: shippingAccountId } = data.localValue;

  if (!shippingAccountId) return [];

  const result = data.localValue.type === 'pickup'
    ? data.defaultPickupServiceTypeOptions.length
      ? data.defaultPickupServiceTypeOptions
      : data.pickupServiceTypeOptions
    : data.localValue.type === 'drop_off'
      ? data.dropOffServiceTypeOptions
      : [];

  const { carrier } = shippingAccountDetailsMapping.value[shippingAccountId];
  return result.filter(item => item.carrier === carrier);
});

const serviceTypeTitle = computed(() => (
  formattedServiceTypeOptions.value.length ? '': 'Please select another Return Type.'
));

watch(
  () => props.value,
  async (value) => {
    data.localValue = {
      ...data.localValue,
      ...value,
    };
    await nextTick(async () => {
      data.localValue.type = props.value.type || '';
      data.localValue.filters = props.value.filters || [];
      data.filtersValue = !data.localValue.filters.length;

      await nextTick(() => {
        data.localValue.service_type = props.value.service_type || '';
      });
    });
  }
);

watch(
  () => data.localValue.type,
  async () => {
    data.localValue.service_type = '';
  }
);

watch(
  () => data.localValue.shipping_account_id,
  () => {
    data.localValue.type = '';
    data.localValue.service_type = '';

    if (!data.localValue.location_id) {
      const account = shippingAccountDetailsMapping.value[data.localValue.shipping_account_id];
      if (account && account.location_id) {
        data.localValue.location_id = account.location_id;
      }
    }
  }
);

watch(
  () => props.serviceTypes,
  () => {
    data.defaultPickupServiceTypeOptions = [];
    data.pickupServiceTypeOptions = [];
    data.dropOffServiceTypeOptions = [];

    props.serviceTypes.forEach(({ data: rawData, pickupServiceTypes, pickup, ship, carrier }) => {
      const item = rawData.map(({ slug: value, name: label }) => ({ value, label, carrier }));
      if (pickup) data.pickupServiceTypeOptions.push(...item);
      if (ship) data.dropOffServiceTypeOptions.push(...item);
      data.defaultPickupServiceTypeOptions.push(
        ...(pickupServiceTypes || []).map(({ slug: value, name: label }) => ({ value, label, carrier }))
      )
    });
  },
);

const closeModal = async () => {
  data.localValue = localValueFactory();
  await nextTick(() => emit('close'));
};

const submit = async () => {
  const value = data.localValue;
  data.localValue = localValueFactory();
  await nextTick(() => emit('send', value));
};

const updateField = ({ index, field, value }) => {
  data.localValue.filters[index][field] = value;
};
</script>

<style module lang="scss">
.locationId {
  :global {
    .multiselect-dropdown {
      max-height: 40px;
    }
  }
}
</style>
