<template>
  <nav class="border-t border-gray-200 px-4 mb-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <a
        :class="{ 'cursor-not-allowed': page === 1 }"
        class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium
        text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
        @click="prev">
        <ArrowNarrowLeftIcon
          class="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true" />
        Previous
      </a>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a
        :class="{ 'cursor-not-allowed': lastPage }"
        class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium
        text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
        @click="next">
        Next
        <ArrowNarrowRightIcon
          class="ml-3 h-5 w-5 text-gray-400"
          aria-hidden="true" />
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
}
</script>

<script setup>
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  page: {
    type: Number,
    default: 1,
  },
  lastPage: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['prev', 'next']);

const prev = () => {
  if (props.page !== 1) emit('prev', props.page - 1);
};

const next = () => {
  if (!props.lastPage) emit('next', props.page + 1);
};
</script>
