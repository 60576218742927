<template>
  <div class="">
    <div class="border-b border-gray-200">
      <nav
        class="-mb-px flex space-x-8"
        aria-label="Tabs">
        <a
          v-for="item in fullData"
          :key="`head-${item.key}`"
          :class="[
            isOpen[item.key]
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
          ]"
          data-toggle="tab"
          role="tab"
          @click="move(item.key)">
          {{ item.label }}
        </a>
      </nav>
    </div>
    <div class="py-4">
      <template v-for="item in fullData">
        <div
          v-if="isOpen[item.key]"
          :key="`body-${item.key}`"
          class=""
          role="tabpanel">
          <slot
            :name="item.key"
            :label="item.label"
            :content="item.content" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
};
</script>

<script setup>
import { computed, defineProps, reactive } from "vue";

const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
});

const fullData = computed(() => props.data.map(item => {
  return {
    ...item,
    key: item.key || item.label.toLowerCase(),
  }
}));

const _initIsOpen = () => {
  const isOpenRaw = {};
  fullData.value.forEach((item) => isOpenRaw[item.key] = false);
  if (Object.keys(isOpenRaw).length) {
    isOpenRaw[fullData.value[0].key] = true;
  }

  return isOpenRaw;
};
const isOpen = reactive(_initIsOpen());

const move = (key) => {
  fullData.value.forEach((item) => isOpen[item.key] = false);
  isOpen[key] = true;
}
</script>
