<template>
  <div>
    <label
      v-if="$slots.label"
      :for="id"
      class="block text-sm font-medium text-gray-700">
      <slot name="label" />
    </label>
    <div
      :class="{'mt-1': $slots.label }"
      class="flex relative">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <div
          v-if="$slots['start-icon']"
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <slot name="start-icon" />
        </div>
        <input
          v-if="!multiline"
          v-bind="numberProps"
          :id="id"
          ref="inputRef"
          :class="[classes, $slots['end-button'] ? 'rounded-none rounded-l-md' : rounded ? 'rounded-md' : '', inputClass]"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          :disabled="disabled"
          class="shadow-sm block w-full sm:text-sm disabled:cursor-not-allowed"
          @input="handleInput($event.target.value)">
        <textarea
          v-else
          :value="inputValue"
          :class="[classes, inputClass]"
          :placeholder="placeholder"
          class="shadow-sm focus block w-full sm:text-sm rounded-md disabled:cursor-not-allowed"
          @input="handleInput($event.target.value)" />
        <div
          v-if="$slots['end-icon']"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <slot name="end-icon" />
        </div>
      </div>
      <template v-if="$slots['end-button']">
        <slot name="end-button" />
      </template>
    </div>
    <p
      v-if="errorMessage"
      :class="errorClass"
      class="mt-2 text-red-600">
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "Input",
}
</script>

<script setup>
import { computed, defineEmits, defineProps, ref, useSlots } from 'vue';
import { useField } from 'vee-validate';

import { createDebounce } from '../../../utils';

const slots = useSlots();

const debounce = createDebounce();

const props = defineProps({
  value: {
    type: null,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  inputClass: {
    type: String,
    default: "",
  },
  errorClass: {
    type: String,
    default: "text-sm",
  },
  placeholder: {
    type: String,
    default: "",
  },
  multiline: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: '',
    validator(value) {
      return ['success', 'warning', 'danger', ''].indexOf(value) !== -1;
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  timeout: {
    type: Number,
    default: 500,
  },
  validation: {
    type: Boolean,
    default: true,
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
  rounded: {
    type: Boolean,
    default: true,
  }
});

const inputRef = ref(null);

const emit = defineEmits(['update:value', 'end-button-click']);

const numberProps = computed(() => {
  const result = {};

  if (props.min !== null) result.min = props.min;
  if (props.max !== null) result.max = props.max;

  return result;
});

let inputValue = computed(() => props.value);
let errorMessage = false;
let meta = { validated: false, valid: true };
let handleInput = (value) => {
  if (props.type === 'number') value = Number(value);

  if (props.timeout) {
    debounce(() => emit('update:value', value), props.timeout);
    return;
  }

  emit('update:value', value);
};

if (props.validation) {
  const connectedField = useField(props.id, undefined, {
    initialValue: props.value,
  });
  inputValue = connectedField.value;
  errorMessage = connectedField.errorMessage;
  meta = connectedField.meta;

  handleInput = (value) => {
    function triggerChange() {
      connectedField.handleChange(value);
      emit('update:value', value);
    }

    if (props.type === 'number') value = Number(value);

    if (props.timeout) {
      debounce(triggerChange, props.timeout);
      return;
    }

    triggerChange();
  };
}

const classes = computed(() => ({
  'pl-10': slots['start-icon'],
  'pr-10': slots['end-icon'],
  ...(
      (meta.validated && !meta.valid) || props.status
      ? {
        'focus:ring-red-500 focus:border-red-500 border-red-300': (meta.validated && !meta.valid) || props.status === 'danger',
        'focus:ring-green-500 focus:border-green-500 border-green-300': props.status === 'success',
        'focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300': props.status === 'warning',
      }
      : {'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300': true}
  ),
}));
</script>
