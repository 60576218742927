<template>
  <dl
    class="grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y
    divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
    <div
      v-for="item in value"
      :key="item.title"
      class="px-4 py-5 sm:p-6 my-auto">
      <dt class="text-base font-normal text-gray-900">
        {{ item.title }}
      </dt>
      <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div
          v-if="loading"
          class="animate-pulse w-full flex space-x-4">
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 w-1/2 bg-blue-400 rounded" />
            <div class="h-4 bg-blue-400 rounded" />
          </div>
        </div>
        <div
          v-else
          class="flex items-baseline text-2xl font-semibold text-indigo-600">
          {{ formatNumber(item.value) }}
          <span class="ml-2 text-sm font-medium text-gray-500">
            {{ item.subtitle }}
          </span>
        </div>

        <!--        <div :class="[item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0']">-->
        <!--          <ArrowSmUpIcon v-if="item.changeType === 'increase'" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" aria-hidden="true" />-->
        <!--          <ArrowSmDownIcon v-else class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />-->
        <!--          <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>-->
        <!--          {{ item.change }}-->
        <!--        </div>-->
      </dd>
    </div>
  </dl>
</template>

<script>
export default {
  name: 'UsageSummaryCard',
}
</script>

<script setup>
import { defineProps } from 'vue';

import { formatNumber } from "../../../utils";

defineProps({
  value: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>
