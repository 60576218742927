<template>
  <z-input
    :id="id"
    :value="value"
    :status="status"
    :placeholder="placeholder"
    :disabled="disabled">
    <template #label>
      <slot name="label" />
    </template>
    <template
      v-if="$slots['start-icon']"
      #start-icon>
      <slot name="start-icon" />
    </template>
    <template
      v-if="$slots['end-icon']"
      #end-icon>
      <slot name="end-icon" />
    </template>
  </z-input>
</template>

<script>
export default {
  name: "GoogleAutocompleteInput",
}
</script>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue';

import ZInput from '../Input';

import { ADDRESS_COMPONENTS, BASIC_DATA_FIELDS } from './constants';
import { getAutocompleteService } from './googleAutocompleteService.js'

let autocompleteService = ref(null);

const props = defineProps({
  value: {
    type: null,
    default: ''
  },
  id: {
    type: String,
    default: "",
  },
  types: {
    type: String,
    default: 'address'
  },
  placeholder: {
    type: String,
    default: "",
  },
  status: {
    type: String,
    default: '',
    validator(value) {
      return ['success', 'warning', 'danger', ''].indexOf(value) !== -1;
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fields: {
    type: Array,
    default: function() {
      return BASIC_DATA_FIELDS;
    },
  },
  country: {
    type: [String, Array],
    default: null
  },
});

onMounted(() => {
  const options = {};

  if (props.types) {
    options.types = [props.types];
  }

  if (props.country) {
    options.componentRestrictions = {
      country: props.country
    };
  }

  autocompleteService = getAutocompleteService(props.id, options)

  autocompleteService.setFields(props.fields); // eslint-disable-line vue/no-ref-as-operand
  autocompleteService.addListener('place_changed', onPlaceChanged); // eslint-disable-line vue/no-ref-as-operand
});

const emit = defineEmits(['update:value', 'update:address', 'no-results-found']);

const formatResult = (place) => {
  let returnData = {};
  for (let i = 0; i < place.address_components.length; i++) {
    let addressType = place.address_components[i].types[0];

    if (ADDRESS_COMPONENTS[addressType]) {
      returnData[addressType] = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
    }
  }

  returnData['latitude'] = place.geometry.location.lat();
  returnData['longitude'] = place.geometry.location.lng();
  return returnData
};

const onPlaceChanged = () => {
  const place = autocompleteService.getPlace(); // eslint-disable-line vue/no-ref-as-operand

  if (!place.geometry) {
    // User entered the name of a Place that was not suggested and
    // pressed the Enter key, or the Place Details request failed.
    emit('no-results-found', place);
    return;
  }

  if (place.address_components !== undefined) {
    emit('update:address', formatResult(place));
    emit('update:value', document.getElementById(props.id).value);
  }
}
</script>
