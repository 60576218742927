<template>
  <div class="row">
    <slot name="message" />

    <z-table
      :loading="loading"
      :loading-filter-bar="false"
      :fields="fields"
      :data="data">
      <template #no-data>
        Enable&nbsp;<i>debug</i>&nbsp;mode in your requests to see them here.
      </template>
      <template #title>
        Logs
      </template>
      <template #filter-bar>
        <div class="flex items-end gap-2">
          <date-picker
            id="dateFrom"
            :value="filters.start_date"
            class="flex-1"
            placeholder="Date from"
            @update:value="updateFilters({ start_date: $event })">
            <template #start-icon>
              <CalendarIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </date-picker>
          <date-picker
            id="dateTo"
            :value="filters.end_date"
            class="flex-1"
            placeholder="Date to"
            @update:value="updateFilters({ end_date: $event })">
            <template #start-icon>
              <CalendarIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </date-picker>
          <z-input
            id="statusCode"
            :value="filters.status_code"
            class="flex-1"
            placeholder="200"
            @update:value="updateFilters({ status_code: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-input
            id="ipAddress"
            :value="filters.ip"
            class="flex-1"
            placeholder="127.0.0.1"
            @update:value="updateFilters({ ip: $event })">
            <template #start-icon>
              <FilterIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true" />
            </template>
          </z-input>
          <z-select
            id="url"
            :value="filters.url"
            :options="urlOptions"
            class="flex-1"
            placeholder="Filter by url"
            nullable
            @update:value="updateFilters({ url: $event || '' })">
            <!--              <template #start-icon>-->
            <!--                <FilterIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />-->
            <!--              </template>-->
          </z-select>
          <z-select
            id="method"
            :value="filters.method"
            :options="methodOptions"
            class="flex-1"
            placeholder="Filter by HTTP method"
            nullable
            @update:value="updateFilters({ method: $event || '' })" />
        </div>
      </template>
      <template #cell(status_code)="{ row }">
        <badge :type="convertStatusCode(row.status_code)">
          {{ row.status_code }}
        </badge>
      </template>
      <template #cell(created)="{ row }">
        <router-link
          :to="{ name: 'log-details', params: { id: row.id } }"
          :title="row.created"
          class="text-indigo-400 hover:text-indigo-600">
          {{ formatDistanceToNow(new Date(row.created), { addSuffix: true }) }}
        </router-link>
      </template>
    </z-table>
    <pagination
      :page="page"
      :last-page="lastPage"
      class="u-pv-small"
      @prev="prev"
      @next="next" />
  </div>
</template>

<script>
export default {
  name: 'LogsTemplate',
}
</script>

<script setup>
import { defineProps, defineEmits } from "vue";
import { CalendarIcon, FilterIcon } from "@heroicons/vue/solid";
import formatDistanceToNow from "date-fns/formatDistanceToNow";


import Badge from "../../atoms/Badge";
import DatePicker from '../../atoms/DatePicker';
import ZInput from '../../atoms/Input';
import ZSelect from '../../atoms/Select';
import Pagination from '../../molecules/Pagination';
import ZTable from '../../organisms/Table';
import { convertStatusCode } from "../../../utils";

const fields = [
  {key: "created", label: "Date Time"},
  {key: "status_code", label: "Status Code"},
  {key: "url", label: "URL"},
  {key: "ip_address", label: "IP Address"},
];
const urlOptions = [
  '/ship',
  '/rate',
  '/track',
  '/cancel',
  '/dopu',
  '/pickup',
  '/manifest',
  '/return',
];
const methodOptions = [
  'GET',
  'POST',
];

const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
  filters: {
    type: Object,
    required: true,
  },
  page: {
    type: Number,
    required: true,
  },
  lastPage: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:filters', 'prev', 'next']);

const updateFilters = (value) => {
  emit('update:filters', {
    ...props.filters,
    ...value,
  })
};

const prev = value => emit('prev', value);
const next = value => emit('next', value);
</script>
