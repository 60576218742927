<template>
  <div>
    <div
      v-if="$slots.title || $slots['filter-bar']"
      class="flex flex-row items-center">
      <div
        v-if="$slots.title"
        class="text-2xl font-medium leading-tight mr-4">
        <slot name="title" />
      </div>
      <div
        v-if="$slots['filter-bar']"
        class="my-2 flex sm:flex-row flex-col">
        <div
          v-if="loading && loadingFilterBar"
          class="animate-pulse w-40 flex space-x-4">
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-blue-400 rounded" />
          </div>
        </div>
        <slot
          v-else
          name="filter-bar" />
      </div>
    </div>
    <template v-if="$slots['before-table']">
      <slot name="before-table" />
    </template>
    <div class="relative shadow overflow-x-auto h-full border-b border-gray-200 sm:rounded-lg mt-6">
      <table
        :class="[tableClass, {'mb-28': !data.length}]"
        class="min-w-full h-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              v-for="item in header"
              :key="`header-${item.key}`"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <slot
                :name="`header(${item.key})`"
                :column="item">
                {{ item.label }}
              </slot>
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-if="loading">
            <tr
              v-for="rowIndex in loadingLinesNumber"
              :key="`loading-row-${rowIndex}`"
              :class="rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
              <td
                v-for="item in header"
                :key="`cell-${item.key}-${rowIndex}`"
                class="px-6 py-4 text-sm font-medium text-gray-900">
                <div class="animate-pulse flex space-x-4">
                  <div class="flex-1 space-y-4 py-1">
                    <div class="h-4 bg-blue-400 rounded" />
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else-if="data.length">
            <tr
              v-for="(row, rowIndex) in data"
              :key="row"
              :class="rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
              <td
                v-for="(item, columnIndex) in header"
                :key="`cell-${item.key}-${rowIndex}`"
                :class="cellClass"
                class="px-6 py-4 text-sm font-medium text-gray-900">
                <slot
                  :name="`cell(${item.key})`"
                  :row="row"
                  :rowIndex="rowIndex"
                  :columnIndex="columnIndex">
                  {{ handleCellValue(row, item.key) }}
                </slot>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div
        v-if="!data.length && !loading"
        :class="{'pb-20': $slots.footer}"
        class="absolute flex justify-center items-center p-4 inset-0 top-12">
        <slot name="no-data">
          {{ noData }}
        </slot>
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZTable',
}
</script>

<script setup>
import { computed, defineProps } from "vue";

const KEY_DELIMITER = ".";

const props = defineProps({
  fields: {
    type: Array,
    default: () => ([]),
  },
  data: {
    type: Array,
    default: () => ([]),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loadingFilterBar: {
    type: Boolean,
    default: true,
  },
  noData: {
    type: String,
    default: 'No data',
  },
  cellClass: {
    type: [String, Array],
    default: '',
  },
  tableClass: {
    type: [String, Array],
    default: '',
  },
  loadingLinesNumber: {
    type: Number,
    default: 3,
  },
});

const header = computed(() => (
  props.fields.map(item => {
    if (typeof(item) === "string") {
      const label = item.charAt(0).toUpperCase() + item.substr(1).toLowerCase();
      return { key: item, label };
    }
    return item;
  })
));


const handleCellValue = (row, key) => {
  if (key.indexOf(KEY_DELIMITER) === -1) return row[key];

  const parts = key.split(KEY_DELIMITER);
  for (let k of parts) {
    try {
      row = row[k];
    } catch {
      return '';
    }
  }

  return row;
};
</script>
