<template>
  <div class="flex justify-end items-center gap-3 p-2 shadow border-b border-gray-200 bg-white">
    <z-select
      id="carrier"
      :value="filters.carrier"
      :options="carrierOptions"
      class="w-40"
      placeholder="Carrier"
      content-width
      nullable
      @update:value="updateFilters('carrier', $event)" />
    <z-select
      id="shippingAccount"
      :value="filters.shipping_account_id"
      :options="formattedShippingAccountOptions"
      placeholder="Shipping account"
      content-width
      nullable
      @update:value="updateFilters('shipping_account_id', $event)" />
    <z-select
      id="type"
      :value="isOutbound"
      :options="typeOptions"
      class="w-40"
      placeholder="Type"
      content-width
      nullable
      @update:value="updateFilters('is_outbound', $event)" />
    <date-picker
      id="startDate"
      :value="filters.start_date"
      :lower-limit="data.periodStartLimit"
      :upper-limit="startDateEndLimit"
      placeholder="Start date"
      @update:value="updateFilters('start_date', $event)" />
    <date-picker
      id="endDate"
      :value="filters.end_date"
      :lower-limit="endDateStartLimit"
      :upper-limit="data.periodEndLimit"
      placeholder="End date"
      @update:value="updateFilters('end_date', $event)" />
    <z-button
      class="mt-1.5 px-5"
      @click="exportData">
      Export
    </z-button>
  </div>
</template>

<script>
export default {
  name: 'ShipmentsFilterBar',
};
</script>

<script setup>
import { computed, defineEmits, defineProps, reactive } from 'vue';
import subMonths from 'date-fns/subMonths';

import ZSelect from '../../atoms/Select';
import ZButton from '../../atoms/Button';
import DatePicker from '../../atoms/DatePicker';

const typeOptions = [
  {value: 'true', label: 'Outbound'},
  {value: 'false', label: 'Return'},
];

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
  carrierOptions: {
    type: Array,
    default: () => ([]),
  },
  shippingAccountOptions: {
    type: Array,
    default: () => ([]),
  },
});

const data = reactive({
  startDate: null,
  endDate: null,
  periodStartLimit: subMonths(new Date(), 3),
  periodEndLimit: new Date(),
});

const endDateStartLimit = computed(() => {
  return data.startDate
    ? data.startDate
    : props.filters.startDate
      ? props.filters.startDate
      : data.periodStartLimit;
});

const startDateEndLimit = computed(() => {
  return data.endDate
    ? data.endDate
    : props.filters.endDate
      ? props.filters.endDate
      : data.periodEndLimit;
});

const formattedShippingAccountOptions = computed(() => (
  props.shippingAccountOptions.map(
    ({ id: value, name: label }) => ({ value, label })
  )
));

const isOutbound = computed(() => (
  props.filters.is_outbound !== undefined
    ? String(props.filters.is_outbound)
    : props.filters.is_outbound
));

const emit = defineEmits(['export-data', 'update:filters']);

const updateFilters = (field, value) => {
  const filters = { ...props.filters };

  if (field === 'start_date') data.startDate = value;
  if (field === 'end_date') data.endDate = value;

  if (!value) {
    delete filters[field];
  } else {
    if (field === 'is_outbound') value = (value === 'true');

    filters[field] = value;
  }

  emit('update:filters', filters);
};

const exportData = () => emit('export-data');
</script>
