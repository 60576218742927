<template>
  <public-credentials>
    <template #title>
      Send one-time password
    </template>
    <template #subtitle>
      Enter your email address to receive one-time password
    </template>
    <template #content>
      <v-form
        :validation-schema="schema"
        @submit="sendPassword">
        <alert
          v-if="error"
          type="danger"
          close
          @close="closeAlert">
          {{ error }}
        </alert>
        <z-input
          id="email"
          :value="email"
          :timeout="0"
          class="mb-4"
          @update:value="updateEmail">
          <template #label>
            Email address
          </template>
        </z-input>

        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
          text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Send
        </button>
      </v-form>
    </template>
  </public-credentials>
</template>

<script>
export default {
  name: 'PublicLoginMfaTemplate',
}
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { Form as VForm } from 'vee-validate';
import { string } from "yup";

import Alert from '../../atoms/Alert';
import ZInput from '../../atoms/Input';
import PublicCredentials from '../../templates/PublicCredentials';

defineProps({
  email: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['send-password', 'alert-close', 'update:email']);

const schema = {
  email: string().email().required().label('Email'),
};

const sendPassword = () => emit('send-password');
const closeAlert = () => emit('alert-close');
const updateEmail = value => emit('update:email', value);
</script>
