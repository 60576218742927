import { nextTick } from "vue";

const LOGOUT_CHECK_TIMEOUT = 60 * 1000;
const LOGIN_PERIOD = 60 * 30;


export const convertStatusCode = (value) => {
  return {
    200: "success",
    400: "danger",
    500: "danger",
  }[value];
};

export function createDebounce() {
  let timeout = null;
  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

export function formatNumber(value) {
  return new Intl.NumberFormat().format(value);
}

export function copyToClipboard(text) {
  /* Select the text field */
  // input.select();
  // input.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(text);
}

export async function withLoading(context, handler) {
  context.loading = true;
  await handler();
  context.loading = false;
}

export async function showSuccessMessage(ctx, message) {
  ctx.message = '';
  await nextTick(() => {
    ctx.message = message;
    ctx.messageStatus = 'success';
  });
  setTimeout(() => {
    ctx.message = '';
  }, 3000);
}

export async function showFailMessage(ctx, message) {
  ctx.message = '';
  await nextTick(() => {
    ctx.message = message;
    ctx.messageStatus = 'danger';
  });
}

export function runAutoLogout(logoutFunc, init) {
  const key = 'bringg-dashboard-login-time';

  if (init) {
    localStorage.setItem(key, new Date().getTime().toString());
  }

  function checkLogout() {
    const rawValue = localStorage.getItem(key);

    if (!rawValue) {
      logoutFunc();
      return;
    }

    const loginDate = new Date(parseInt(rawValue));
    const currentDate = new Date();

    if ((currentDate - loginDate) / 1000 >= LOGIN_PERIOD) {
      logoutFunc();
      return;
    }

    setTimeout(checkLogout, LOGOUT_CHECK_TIMEOUT);
  }

  setTimeout(checkLogout, LOGOUT_CHECK_TIMEOUT);
}
